import * as React from "react";
import { CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@utils/cn";
import { Avatar, AvatarImage } from "@components/avatar";
import { Button } from "@components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@components/command";
import { Dialog } from "@components/dialog";

import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { useContact } from "@utils/hooks";
import { TeamGroup } from "@utils/types";
import { Skeleton } from "@components/skeleton";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

type TeamSwitcherProps = PopoverTriggerProps;

const getMatchingTeam = (teams: TeamGroup[] | undefined, value: string) => {
  const team = (teams ?? [])
    .flatMap((group) => group.teams)
    .find((team) => {
      return team.value === value;
    });

  return team;
};

export default function TeamSwitcher({ className }: TeamSwitcherProps) {
  const { contact, setStoredTeam, team: storedTeam } = useContact();

  const [open, setOpen] = React.useState(false);
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false);

  const selectedTeam = React.useMemo(
    () =>
      getMatchingTeam(
        contact?.teams,
        storedTeam?.value ?? (contact?.id as string)
      ),
    [contact?.teams, storedTeam?.value, contact?.id]
  );

  const onClick = React.useCallback(
    (value: string) => {
      setStoredTeam(value);
      setOpen(false);
    },
    [setStoredTeam]
  );

  const TeamGroups = React.useMemo(
    () =>
      contact?.teams.map((group) => (
        <CommandGroup key={group.label} heading={group.label}>
          {group.teams.map((team) => (
            <CommandItem
              key={team.value}
              onSelect={() => onClick(team.value)}
              className="text-sm"
            >
              <Avatar className="mr-2 h-5 w-5">
                <AvatarImage
                  src={`https://avatar.vercel.sh/${team.label}.png`}
                  className="grayscale"
                />
              </Avatar>
              {team.label}
              <CheckIcon
                className={cn(
                  "ml-auto h-4 w-4",
                  selectedTeam?.value === team.value
                    ? "opacity-100"
                    : "opacity-0"
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      )),
    [contact?.teams, selectedTeam?.value, onClick]
  );

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="teamSwitcher"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("justify-between h-fit p-3", className)}
          >
            <Avatar className="h-3 w-3">
              {selectedTeam == null ? (
                <Skeleton className="w-5 h-5" />
              ) : (
                <AvatarImage
                  src={`https://avatar.vercel.sh/${selectedTeam.value}.png`}
                  alt={selectedTeam.label}
                />
              )}
            </Avatar>
            {selectedTeam?.label == null ? (
              <Skeleton className="w-20 h-5" />
            ) : (
              <span className="truncate max-w-[70px] ml-1">{selectedTeam.label}</span>
            )}
            {/* <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" /> */}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search team..." />
              <CommandEmpty>No team found.</CommandEmpty>
              {TeamGroups}
            </CommandList>
            <CommandSeparator />
          </Command>
        </PopoverContent>
      </Popover>
    </Dialog>
  );
}
