import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { useCallback, useState } from "react";
import {
  useAllCompanyOfficesQuery,
  useCompanyByIdQuery,
  useUpdateCompanyMutation,
} from "@codegen/index";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import CountrySelect from "@components/formCountrySelect";
import { CompanyOffice, UpdateCompanyByIdInput } from "@codegen/schema";
import { EmployerOnboardingPageType } from "@pages/onboarding/types";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import * as React from "react";
import Spinner from "@components/spinner";
import { WaitingSplash } from "@pages/onboarding/shared/waiting";
import { useEmployerOnboarding } from "../../employer-onboarding";

const companyAddressSchema = z.object({
  street: z.string().min(1, { message: "Required" }),
  city: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
  country: z.string().min(1, { message: "Required" }),
  zip: z.string().min(1, { message: "Required" }),
  isBeneficiaryWorksite: z.union([z.literal("yes"), z.literal("no")]),
  unitType: z
    .enum(["apt", "flr", "ste"])
    .transform((s) => s || null)
    .nullish(),
  unitNumber: z
    .string()
    .transform((s) => s || null)
    .nullish(),
});

type CompanyAddressValues = z.infer<typeof companyAddressSchema>;

const unitTypeOptions = [
  { label: "APT", value: "APT" },
  { label: "FLR", value: "FLR" },
  { label: "STE", value: "STE" },
  { label: "N/A", value: null },
];

export const ConfirmEmployerHQForm = (props: {
  officeWorksite?: Pick<CompanyOffice, "id"> | null;
}) => {
  const { onSubmit, company, onBack, refetchCompany } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [companyUpdatesMutation] = useUpdateCompanyMutation();

  const form = useForm<CompanyAddressValues>({
    resolver: zodResolver(companyAddressSchema),
    defaultValues: {
      street: company?.street ?? undefined,
      unitType: company?.unitType ?? undefined,
      unitNumber: company?.unitNumber ?? undefined,
      city: company?.city ?? undefined,
      state: company?.state ?? undefined,
      country: company?.country ?? undefined,
      zip: company?.postalCode ?? undefined,
      isBeneficiaryWorksite:
        typeof company?.isWorksite === "boolean"
          ? company.isWorksite
            ? "yes"
            : "no"
          : undefined,
    },
  });

  const isBeneficiaryWorksite = form.watch("isBeneficiaryWorksite");
  const logError = useLogError();
  const doSubmitCompanyAddress = useCallback(
    async (data: CompanyAddressValues) => {
      try {
        if (company == null) return;
        setStatus("loading");
        const { errors } = await companyUpdatesMutation({
          variables: {
            input: {
              id: company.value,
              companyPatch: {
                cityBusinessAddress: data.city,
                countryBusinessAddress: data.country,
                stateBusinessAddress: data.state,
                streetNumberStreetNameBusinessAddress: data.street,
                zipCodeBusinessAddress: data.zip,
                unitTypeBusinessAddress: data.unitType,
                unitNumberBusinessAddress: data.unitNumber,
                isBusinessAddressWorksite:
                  data.isBeneficiaryWorksite === "yes" ? true : false,
              },
            } as UpdateCompanyByIdInput,
          },
        });

        if (errors != null) {
          throw errors;
        }
        await refetchCompany();
        onSubmit({ data, key: "companyHQAddress" });
      } catch (exception) {
        logError(exception);
        setStatus("error");
      }
    },

    [
      companyUpdatesMutation,
      onSubmit,
      company,
      setStatus,
      logError,
      refetchCompany,
    ]
  );

  return (
    <EmployerOnboardingContainer
      title="Company and Worksite Address"
      subtitle={
        <div>
          {/* <p className="mb-2">Please confirm your personal details</p> */}
          {/* <p>If you are not an authorized signatory for the company, please add the best signatory information.</p> */}
        </div>
      }
      cardTitle={"Please provide the U.S. company mailing address"}
      progress={0}
      form={form}
      onSubmit={doSubmitCompanyAddress}
      name="confirm_employer_hq"
    >
      <FieldsContainer status={status}>
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} className="h-11" placeholder="123 Main St." />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full flex flex-row gap-x-2">
          <FormField
            control={form.control}
            name="unitType"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormControl>
                  <select
                    value={field.value === null ? "N/A" : String(field.value)}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value === "N/A" ? null : e.target.value
                      )
                    }
                    className="h-11 w-full border rounded-md"
                  >
                    {unitTypeOptions.map((option) => (
                      <option
                        key={option.label}
                        value={
                          option.value === null ? "N/A" : String(option.value)
                        }
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="unitNumber"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormControl>
                  <Input
                    {...field}
                    value={field.value ?? ""}
                    className="h-11"
                    placeholder="Unit Number"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} className="h-11" placeholder="City" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full flex flex-row gap-x-2">
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormControl>
                  <Input
                    {...field}
                    className="h-11 w-full"
                    placeholder="State"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <CountrySelect
                  field={field}
                  onSelect={(x: string) => form.setValue("country", x)}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="zip"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} className="h-11" placeholder="Zip code" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"isBeneficiaryWorksite"}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-md">
                Will your employee work from the same address?
              </FormLabel>
              <FormControl className="flex items-center gap-2">
                <FormLabel
                  className="text-md text-primary"
                  htmlFor="isBeneficiaryWorksiteYes"
                >
                  <input
                    {...form.register("isBeneficiaryWorksite")}
                    type="radio"
                    id="isBeneficiaryWorksiteYes"
                    name="isBeneficiaryWorksite"
                    value={"yes"}
                  />
                  Yes
                </FormLabel>
              </FormControl>
              <FormControl className="flex items-center gap-2">
                <FormLabel
                  className="text-md text-primary"
                  htmlFor="isBeneficiaryWorksiteNo"
                >
                  <input
                    {...form.register("isBeneficiaryWorksite")}
                    type="radio"
                    id="isBeneficiaryWorksiteNo"
                    name="isBeneficiaryWorksite"
                    value={"no"}
                  />
                  No
                </FormLabel>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FieldsContainer>

      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!form.formState.isValid}
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};

export const ConfirmEmployerHQController = () => {
  const { company } = useEmployerOnboarding();
  const { data: officeData, loading: officeLoading } =
    useAllCompanyOfficesQuery({
      variables: {
        id: company.value,
      },
    });

  const officeWorksite =
    officeData?.companyById?.companyOfficesByCompanyId?.nodes?.find(
      (of) => !!of?.isEmployeeWorksite
    );

  if (officeLoading) {
    return <WaitingSplash />;
  }

  return <ConfirmEmployerHQForm officeWorksite={officeWorksite} />;
};
