import { Button } from '@components/button';
import { FormControl, FormLabel } from '@components/form';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdatePetitionMutation } from '@codegen/index';
import { useLogError } from '@utils/error';
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import { UpdatePetitionByIdInput } from '@codegen/schema';
import Spinner from '@components/spinner';
import * as changeCase from 'change-case';
import { visaClassMap } from '@utils/index';
// Schema definition
const formSchema = z.object({
  hasDependents: z.enum(['Yes', 'No'], {
    required_error: 'Please select Yes or No',
  }),
  hasVisaDenial: z.enum(['Yes', 'No'], {
    required_error: 'Please select Yes or No',
  }),
  hasJ1J2History: z.enum(['Yes', 'No'], {
    required_error: 'Please select Yes or No',
  }),
});

export type ImmigrationQuestionsFormType = z.infer<typeof formSchema>;

export const ImmigrationQuestionsForm: React.FC<OnboardingPageType & { 
  immigrationData?: ImmigrationQuestionsFormType, 
  refetch: () => void,
}> = ({ petition, onSubmit, onBack, immigrationData, refetch, onSkipForNow }) => {
  const methods = useForm({
    defaultValues: immigrationData || {
      hasDependents: undefined,
      hasVisaDenial: undefined,
      hasJ1J2History: undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const { register, formState } = methods;
  const logError = useLogError();
  const [updatePetitionMutation] = useUpdatePetitionMutation();

  const doSubmit = async (data: ImmigrationQuestionsFormType) => {
    try {
      // Get existing metadata and merge with new data
      const existingMetadata = petition.metadata ? JSON.parse(petition.metadata) : {};
      const newMetadata = {
        ...existingMetadata,
        hasDependents: data.hasDependents,
        hasVisaDenial: data.hasVisaDenial,
        hasJ1J2History: data.hasJ1J2History,
      };

      const { errors: updatePetitionError } = await updatePetitionMutation({
        variables: {
          input: {
            id: petition.id,
            petitionPatch: {
              metadata: JSON.stringify(newMetadata),
            },
          } as UpdatePetitionByIdInput,
        },
      });

      if (updatePetitionError != null) {
        throw updatePetitionError;
      }
      refetch();
      onSubmit(data);
    } catch (exception) {
      logError(exception, {
        message: 'Error saving immigration data'
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title="Immigration Questions"
        subtitle=""
        cardTitle=""
        progress={0}
        onSubmit={doSubmit}
      >
        <FieldsContainer status="">
          <div className="flex flex-col gap-6 space-y-6">
            <div className="space-y-3">
              <FormLabel className="font-semibold mb-3">Do you have any dependents with this petition?</FormLabel>
              <div className="space-y-3">
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasDependentsYes">
                    <input id="hasDependentsYes" type="radio" value="Yes" {...register('hasDependents')} /> Yes
                  </FormLabel>
                </FormControl>
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasDependentsNo">
                    <input id="hasDependentsNo" type="radio" value="No" {...register('hasDependents')} /> No
                  </FormLabel>
                </FormControl>
              </div>
              <ErrorMessage
                errors={formState.errors}
                name="hasDependents"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </div>

            <div className="space-y-3">
              <FormLabel className="font-semibold mb-3">Have you ever been denied for a {visaClassMap[petition.visaClass]} in the last 7 years?</FormLabel>
              <div className="space-y-3">
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasVisaDenialYes">
                    <input id="hasVisaDenialYes" type="radio" value="Yes" {...register('hasVisaDenial')} /> Yes
                  </FormLabel>
                </FormControl>
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasVisaDenialNo">
                    <input id="hasVisaDenialNo" type="radio" value="No" {...register('hasVisaDenial')} /> No
                  </FormLabel>
                </FormControl>
              </div>
              <ErrorMessage
                errors={formState.errors}
                name="hasVisaDenial"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </div>

            <div className="space-y-3">
              <FormLabel className="font-semibold mb-3">Have you ever had a J-1 exchange visitor or J-2 dependent before?</FormLabel>
              <div className="space-y-3">
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasJ1J2HistoryYes">
                    <input id="hasJ1J2HistoryYes" type="radio" value="Yes" {...register('hasJ1J2History')} /> Yes
                  </FormLabel>
                </FormControl>
                <FormControl className="flex items-center gap-2">
                  <FormLabel htmlFor="hasJ1J2HistoryNo">
                    <input id="hasJ1J2HistoryNo" type="radio" value="No" {...register('hasJ1J2History')} /> No
                  </FormLabel>
                </FormControl>
              </div>
              <ErrorMessage
                errors={formState.errors}
                name="hasJ1J2History"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </div>
          </div>
        </FieldsContainer>

        <CTAContainer onBack={onBack} onSkip={onSkipForNow}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={formState.isValidating}
            type="submit"
          >
            {formState.isValidating ? <Spinner /> : 'Next'}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
};

export const ImmigrationQuestionsFormController: React.FC<OnboardingPageType> = (props) => {
  // Get existing data from petition metadata
  const immigrationData = React.useMemo(() => {
    if (props.petition?.metadata) {
      const metadata = JSON.parse(props.petition.metadata);
      return {
        hasDependents: metadata.hasDependents,
        hasVisaDenial: metadata.hasVisaDenial,
        hasJ1J2History: metadata.hasJ1J2History,
      };
    }
    return undefined;
  }, [props.petition?.metadata]);

  // No need for loading state since data comes from petition prop
  return (
    <ImmigrationQuestionsForm 
      {...props} 
      immigrationData={immigrationData} 
      refetch={() => {}} // No need to refetch since we're using petition metadata
    />
  );
}; 