export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigFloat: { input: any; output: any };
  BigInt: { input: string; output: string };
  Cursor: { input: any; output: any };
  Date: { input: string; output: string };
  Datetime: { input: any; output: any };
  JSON: { input: string; output: string };
};

/** A connection to a list of `AdditionalEntity` values. */
export type AdditionalEntitiesConnection = {
  /** A list of edges which contains the `AdditionalEntity` and cursor to aid in pagination. */
  edges: Array<AdditionalEntitiesEdge>;
  /** A list of `AdditionalEntity` objects. */
  nodes: Array<Maybe<AdditionalEntity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdditionalEntity` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AdditionalEntity` edge in the connection. */
export type AdditionalEntitiesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AdditionalEntity` at the end of the edge. */
  node: Maybe<AdditionalEntity>;
};

/** Methods to use when ordering `AdditionalEntity`. */
export enum AdditionalEntitiesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type AdditionalEntity = Node & {
  /** Reads a single `Company` that is related to this `AdditionalEntity`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Maybe<Scalars["BigInt"]["output"]>;
  createdAt: Scalars["Datetime"]["output"];
  data: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `EntityFile`. */
  entityFilesByEntityId: EntityFilesConnection;
  /** Reads and enables pagination through a set of `EntityRelation`. */
  entityRelationsByEntityId: EntityRelationsConnection;
  id: Scalars["BigInt"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `ReusableExhibitEntity`. */
  reusableExhibitEntitiesByEntityId: ReusableExhibitEntitiesConnection;
  type: EntityTypes;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `AdditionalEntity`. */
  userByUserId: Maybe<User>;
  userId: Maybe<Scalars["BigInt"]["output"]>;
};

export type AdditionalEntityEntityFilesByEntityIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntityFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntityFilesOrderBy>>;
};

export type AdditionalEntityEntityRelationsByEntityIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntityRelationCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntityRelationsOrderBy>>;
};

export type AdditionalEntityReusableExhibitEntitiesByEntityIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

/**
 * A condition to be used against `AdditionalEntity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AdditionalEntityCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `data` field. */
  data: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<EntityTypes>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `AdditionalEntity` */
export type AdditionalEntityInput = {
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  data: InputMaybe<Scalars["JSON"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  type: EntityTypes;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Represents an update to a `AdditionalEntity`. Fields that are set will be updated. */
export type AdditionalEntityPatch = {
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  data: InputMaybe<Scalars["JSON"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  type: InputMaybe<EntityTypes>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type Admin = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `Admin`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
};

/** A condition to be used against `Admin` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AdminCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `Admin` */
export type AdminInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `adminInsertCompanyCheck` mutation. */
export type AdminInsertCompanyCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `adminInsertCompanyCheck` mutation. */
export type AdminInsertCompanyCheckPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Represents an update to a `Admin`. Fields that are set will be updated. */
export type AdminPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `Admin` values. */
export type AdminsConnection = {
  /** A list of edges which contains the `Admin` and cursor to aid in pagination. */
  edges: Array<AdminsEdge>;
  /** A list of `Admin` objects. */
  nodes: Array<Maybe<Admin>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Admin` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Admin` edge in the connection. */
export type AdminsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Admin` at the end of the edge. */
  node: Maybe<Admin>;
};

/** Methods to use when ordering `Admin`. */
export enum AdminsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type AuditLog = Node & {
  changedAt: Maybe<Scalars["Datetime"]["output"]>;
  changedBy: Maybe<Scalars["BigInt"]["output"]>;
  changedData: Scalars["JSON"]["output"];
  id: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  operation: Scalars["String"]["output"];
  recordId: Maybe<Scalars["BigInt"]["output"]>;
  tableName: Scalars["String"]["output"];
};

/**
 * A condition to be used against `AuditLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuditLogCondition = {
  /** Checks for equality with the object’s `changedAt` field. */
  changedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `changedBy` field. */
  changedBy: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `changedData` field. */
  changedData: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `operation` field. */
  operation: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recordId` field. */
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `tableName` field. */
  tableName: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `AuditLog` */
export type AuditLogInput = {
  changedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  changedBy: InputMaybe<Scalars["BigInt"]["input"]>;
  changedData: Scalars["JSON"]["input"];
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  operation: Scalars["String"]["input"];
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  tableName: Scalars["String"]["input"];
};

/** Represents an update to a `AuditLog`. Fields that are set will be updated. */
export type AuditLogPatch = {
  changedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  changedBy: InputMaybe<Scalars["BigInt"]["input"]>;
  changedData: InputMaybe<Scalars["JSON"]["input"]>;
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  operation: InputMaybe<Scalars["String"]["input"]>;
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  tableName: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `AuditLog` values. */
export type AuditLogsConnection = {
  /** A list of edges which contains the `AuditLog` and cursor to aid in pagination. */
  edges: Array<AuditLogsEdge>;
  /** A list of `AuditLog` objects. */
  nodes: Array<Maybe<AuditLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditLog` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AuditLog` edge in the connection. */
export type AuditLogsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditLog` at the end of the edge. */
  node: Maybe<AuditLog>;
};

/** Methods to use when ordering `AuditLog`. */
export enum AuditLogsOrderBy {
  ChangedAtAsc = "CHANGED_AT_ASC",
  ChangedAtDesc = "CHANGED_AT_DESC",
  ChangedByAsc = "CHANGED_BY_ASC",
  ChangedByDesc = "CHANGED_BY_DESC",
  ChangedDataAsc = "CHANGED_DATA_ASC",
  ChangedDataDesc = "CHANGED_DATA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OperationAsc = "OPERATION_ASC",
  OperationDesc = "OPERATION_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecordIdAsc = "RECORD_ID_ASC",
  RecordIdDesc = "RECORD_ID_DESC",
  TableNameAsc = "TABLE_NAME_ASC",
  TableNameDesc = "TABLE_NAME_DESC",
}

/** A connection to a list of `CaseStrategy` values. */
export type CaseStrategiesConnection = {
  /** A list of edges which contains the `CaseStrategy` and cursor to aid in pagination. */
  edges: Array<CaseStrategiesEdge>;
  /** A list of `CaseStrategy` objects. */
  nodes: Array<Maybe<CaseStrategy>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CaseStrategy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CaseStrategy` edge in the connection. */
export type CaseStrategiesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CaseStrategy` at the end of the edge. */
  node: Maybe<CaseStrategy>;
};

/** Methods to use when ordering `CaseStrategy`. */
export enum CaseStrategiesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LabelAsc = "LABEL_ASC",
  LabelDesc = "LABEL_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StrategyAsc = "STRATEGY_ASC",
  StrategyDesc = "STRATEGY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserEmailAsc = "USER_EMAIL_ASC",
  UserEmailDesc = "USER_EMAIL_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type CaseStrategy = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `CaseStrategy`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Maybe<Scalars["BigInt"]["output"]>;
  strategy: Scalars["JSON"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `CaseStrategy`. */
  userByUserId: Maybe<User>;
  userEmail: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `CaseStrategy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CaseStrategyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `label` field. */
  label: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `strategy` field. */
  strategy: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userEmail` field. */
  userEmail: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `CaseStrategy` */
export type CaseStrategyInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  label: Scalars["String"]["input"];
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  strategy: Scalars["JSON"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userEmail: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Represents an update to a `CaseStrategy`. Fields that are set will be updated. */
export type CaseStrategyPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  label: InputMaybe<Scalars["String"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  strategy: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userEmail: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type CaseType = Node & {
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByCaseType: PetitionsConnection;
};

export type CaseTypePetitionsByCaseTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

/**
 * A condition to be used against `CaseType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CaseTypeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `CaseType` */
export type CaseTypeInput = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

/** Represents an update to a `CaseType`. Fields that are set will be updated. */
export type CaseTypePatch = {
  id: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `CaseType` values. */
export type CaseTypesConnection = {
  /** A list of edges which contains the `CaseType` and cursor to aid in pagination. */
  edges: Array<CaseTypesEdge>;
  /** A list of `CaseType` objects. */
  nodes: Array<Maybe<CaseType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CaseType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CaseType` edge in the connection. */
export type CaseTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CaseType` at the end of the edge. */
  node: Maybe<CaseType>;
};

/** Methods to use when ordering `CaseType`. */
export enum CaseTypesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export enum CommStatusEnum {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
  VirtualMeeting = "VIRTUAL_MEETING",
}

export enum CommTypeEnum {
  Chat = "CHAT",
  Email = "EMAIL",
  InPersonMeeting = "IN_PERSON_MEETING",
  Other = "OTHER",
  PhoneCall = "PHONE_CALL",
  SocialMedia = "SOCIAL_MEDIA",
  VirtualMeeting = "VIRTUAL_MEETING",
}

/** A connection to a list of `Company` values. */
export type CompaniesConnection = {
  /** A list of edges which contains the `Company` and cursor to aid in pagination. */
  edges: Array<CompaniesEdge>;
  /** A list of `Company` objects. */
  nodes: Array<Maybe<Company>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Company` edge in the connection. */
export type CompaniesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Company` at the end of the edge. */
  node: Maybe<Company>;
};

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  BioAsc = "BIO_ASC",
  BioDesc = "BIO_DESC",
  CityBusinessAddressAsc = "CITY_BUSINESS_ADDRESS_ASC",
  CityBusinessAddressDesc = "CITY_BUSINESS_ADDRESS_DESC",
  CompanyGrossAnnualIncomeAsc = "COMPANY_GROSS_ANNUAL_INCOME_ASC",
  CompanyGrossAnnualIncomeDesc = "COMPANY_GROSS_ANNUAL_INCOME_DESC",
  CompanyNetAnnualIncomeAsc = "COMPANY_NET_ANNUAL_INCOME_ASC",
  CompanyNetAnnualIncomeDesc = "COMPANY_NET_ANNUAL_INCOME_DESC",
  CompanyTypeOfBusinessAsc = "COMPANY_TYPE_OF_BUSINESS_ASC",
  CompanyTypeOfBusinessDesc = "COMPANY_TYPE_OF_BUSINESS_DESC",
  CountryBusinessAddressAsc = "COUNTRY_BUSINESS_ADDRESS_ASC",
  CountryBusinessAddressDesc = "COUNTRY_BUSINESS_ADDRESS_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CurrentNumberOfEmployeesAsc = "CURRENT_NUMBER_OF_EMPLOYEES_ASC",
  CurrentNumberOfEmployeesDesc = "CURRENT_NUMBER_OF_EMPLOYEES_DESC",
  DbaNameAsc = "DBA_NAME_ASC",
  DbaNameDesc = "DBA_NAME_DESC",
  EinAsc = "EIN_ASC",
  EinDesc = "EIN_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IncorporationYearAsc = "INCORPORATION_YEAR_ASC",
  IncorporationYearDesc = "INCORPORATION_YEAR_DESC",
  IsBusinessAddressWorksiteAsc = "IS_BUSINESS_ADDRESS_WORKSITE_ASC",
  IsBusinessAddressWorksiteDesc = "IS_BUSINESS_ADDRESS_WORKSITE_DESC",
  LegalNameAsc = "LEGAL_NAME_ASC",
  LegalNameDesc = "LEGAL_NAME_DESC",
  LogoFileKeyAsc = "LOGO_FILE_KEY_ASC",
  LogoFileKeyDesc = "LOGO_FILE_KEY_DESC",
  Natural = "NATURAL",
  PhoneNumberAsc = "PHONE_NUMBER_ASC",
  PhoneNumberDesc = "PHONE_NUMBER_DESC",
  PortalOnboardedAsc = "PORTAL_ONBOARDED_ASC",
  PortalOnboardedDesc = "PORTAL_ONBOARDED_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StateBusinessAddressAsc = "STATE_BUSINESS_ADDRESS_ASC",
  StateBusinessAddressDesc = "STATE_BUSINESS_ADDRESS_DESC",
  StreetNumberStreetNameBusinessAddressAsc = "STREET_NUMBER_STREET_NAME_BUSINESS_ADDRESS_ASC",
  StreetNumberStreetNameBusinessAddressDesc = "STREET_NUMBER_STREET_NAME_BUSINESS_ADDRESS_DESC",
  UnitNumberBusinessAddressAsc = "UNIT_NUMBER_BUSINESS_ADDRESS_ASC",
  UnitNumberBusinessAddressDesc = "UNIT_NUMBER_BUSINESS_ADDRESS_DESC",
  UnitTypeBusinessAddressAsc = "UNIT_TYPE_BUSINESS_ADDRESS_ASC",
  UnitTypeBusinessAddressDesc = "UNIT_TYPE_BUSINESS_ADDRESS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
  ZipCodeBusinessAddressAsc = "ZIP_CODE_BUSINESS_ADDRESS_ASC",
  ZipCodeBusinessAddressDesc = "ZIP_CODE_BUSINESS_ADDRESS_DESC",
}

export type Company = Node & {
  /** Reads and enables pagination through a set of `AdditionalEntity`. */
  additionalEntitiesByCompanyId: AdditionalEntitiesConnection;
  atRecordId: Maybe<Scalars["String"]["output"]>;
  bio: Maybe<Scalars["String"]["output"]>;
  cityBusinessAddress: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `CompanyDocument`. */
  companyDocumentsByCompanyId: CompanyDocumentsConnection;
  companyGrossAnnualIncome: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `CompanyMember`. */
  companyMembersByCompanyId: CompanyMembersConnection;
  companyNetAnnualIncome: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `CompanyOffice`. */
  companyOfficesByCompanyId: CompanyOfficesConnection;
  companyTypeOfBusiness: Maybe<Scalars["String"]["output"]>;
  countryBusinessAddress: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  currentNumberOfEmployees: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `CustomerCommsLog`. */
  customerCommsLogsByCompanyId: CustomerCommsLogsConnection;
  dbaName: Scalars["String"]["output"];
  ein: Maybe<Scalars["String"]["output"]>;
  id: Scalars["BigInt"]["output"];
  incorporationYear: Maybe<Scalars["Int"]["output"]>;
  isBusinessAddressWorksite: Maybe<Scalars["Boolean"]["output"]>;
  legalName: Maybe<Scalars["String"]["output"]>;
  logoFileKey: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByPetitionerId: PetitionsConnection;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  portalOnboarded: Maybe<Scalars["Boolean"]["output"]>;
  stateBusinessAddress: Maybe<Scalars["String"]["output"]>;
  streetNumberStreetNameBusinessAddress: Maybe<Scalars["String"]["output"]>;
  unitNumberBusinessAddress: Maybe<Scalars["String"]["output"]>;
  unitTypeBusinessAddress: Maybe<UnitTypeAddressEnum>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `UserEmployment`. */
  userEmploymentsByCompanyId: UserEmploymentsConnection;
  websiteUrl: Maybe<Scalars["String"]["output"]>;
  zipCodeBusinessAddress: Maybe<Scalars["String"]["output"]>;
};

export type CompanyAdditionalEntitiesByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AdditionalEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

export type CompanyCompanyDocumentsByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

export type CompanyCompanyMembersByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyMemberCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

export type CompanyCompanyOfficesByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyOfficeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyOfficesOrderBy>>;
};

export type CompanyCustomerCommsLogsByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CustomerCommsLogCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

export type CompanyPetitionsByPetitionerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type CompanyUserEmploymentsByCompanyIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserEmploymentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `bio` field. */
  bio: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cityBusinessAddress` field. */
  cityBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyGrossAnnualIncome` field. */
  companyGrossAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `companyNetAnnualIncome` field. */
  companyNetAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `companyTypeOfBusiness` field. */
  companyTypeOfBusiness: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `countryBusinessAddress` field. */
  countryBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `currentNumberOfEmployees` field. */
  currentNumberOfEmployees: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `dbaName` field. */
  dbaName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ein` field. */
  ein: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `incorporationYear` field. */
  incorporationYear: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isBusinessAddressWorksite` field. */
  isBusinessAddressWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `legalName` field. */
  legalName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `logoFileKey` field. */
  logoFileKey: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `portalOnboarded` field. */
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `stateBusinessAddress` field. */
  stateBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `streetNumberStreetNameBusinessAddress` field. */
  streetNumberStreetNameBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unitNumberBusinessAddress` field. */
  unitNumberBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unitTypeBusinessAddress` field. */
  unitTypeBusinessAddress: InputMaybe<UnitTypeAddressEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `zipCodeBusinessAddress` field. */
  zipCodeBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
};

export type CompanyDocument = Node & {
  /** Reads a single `Company` that is related to this `CompanyDocument`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Scalars["BigInt"]["output"];
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `File` that is related to this `CompanyDocument`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  type: CompanyDocumentType;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  visible: Scalars["Boolean"]["output"];
};

/**
 * A condition to be used against `CompanyDocument` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyDocumentCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<CompanyDocumentType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `visible` field. */
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `CompanyDocument` */
export type CompanyDocumentInput = {
  companyId: Scalars["BigInt"]["input"];
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  type: CompanyDocumentType;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Represents an update to a `CompanyDocument`. Fields that are set will be updated. */
export type CompanyDocumentPatch = {
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  type: InputMaybe<CompanyDocumentType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum CompanyDocumentType {
  ArticlesOfIncorporation = "ARTICLES_OF_INCORPORATION",
  BankStatement = "BANK_STATEMENT",
  BusinessLicense = "BUSINESS_LICENSE",
  CertificateOfIncorporation = "CERTIFICATE_OF_INCORPORATION",
  CompanyBylaws = "COMPANY_BYLAWS",
  CompanyOverview = "COMPANY_OVERVIEW",
  FundingDocument = "FUNDING_DOCUMENT",
  NameChange = "NAME_CHANGE",
  SafeDocumentation = "SAFE_DOCUMENTATION",
  TaxIdFeinDocument = "TAX_ID_FEIN_DOCUMENT",
  Tbd = "TBD",
  TermSheet = "TERM_SHEET",
}

/** A connection to a list of `CompanyDocument` values. */
export type CompanyDocumentsConnection = {
  /** A list of edges which contains the `CompanyDocument` and cursor to aid in pagination. */
  edges: Array<CompanyDocumentsEdge>;
  /** A list of `CompanyDocument` objects. */
  nodes: Array<Maybe<CompanyDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDocument` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CompanyDocument` edge in the connection. */
export type CompanyDocumentsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CompanyDocument` at the end of the edge. */
  node: Maybe<CompanyDocument>;
};

/** Methods to use when ordering `CompanyDocument`. */
export enum CompanyDocumentsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VisibleAsc = "VISIBLE_ASC",
  VisibleDesc = "VISIBLE_DESC",
}

/** An input for mutations affecting `Company` */
export type CompanyInput = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  bio: InputMaybe<Scalars["String"]["input"]>;
  cityBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  companyGrossAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  companyNetAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  companyTypeOfBusiness: InputMaybe<Scalars["String"]["input"]>;
  countryBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  currentNumberOfEmployees: InputMaybe<Scalars["Int"]["input"]>;
  dbaName: Scalars["String"]["input"];
  ein: InputMaybe<Scalars["String"]["input"]>;
  incorporationYear: InputMaybe<Scalars["Int"]["input"]>;
  isBusinessAddressWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  legalName: InputMaybe<Scalars["String"]["input"]>;
  logoFileKey: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  stateBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  unitNumberBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  unitTypeBusinessAddress: InputMaybe<UnitTypeAddressEnum>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  websiteUrl: InputMaybe<Scalars["String"]["input"]>;
  zipCodeBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
};

export type CompanyMember = Node & {
  /** Reads a single `Company` that is related to this `CompanyMember`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Scalars["BigInt"]["output"];
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  isAuthorizedSignatory: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  role: CompanyUserRole;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `CompanyMember`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `CompanyMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyMemberCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `isAuthorizedSignatory` field. */
  isAuthorizedSignatory: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `role` field. */
  role: InputMaybe<CompanyUserRole>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `CompanyMember` */
export type CompanyMemberInput = {
  companyId: Scalars["BigInt"]["input"];
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  isAuthorizedSignatory: InputMaybe<Scalars["Boolean"]["input"]>;
  role: InputMaybe<CompanyUserRole>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: Scalars["BigInt"]["input"];
};

/** Represents an update to a `CompanyMember`. Fields that are set will be updated. */
export type CompanyMemberPatch = {
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  isAuthorizedSignatory: InputMaybe<Scalars["Boolean"]["input"]>;
  role: InputMaybe<CompanyUserRole>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `CompanyMember` values. */
export type CompanyMembersConnection = {
  /** A list of edges which contains the `CompanyMember` and cursor to aid in pagination. */
  edges: Array<CompanyMembersEdge>;
  /** A list of `CompanyMember` objects. */
  nodes: Array<Maybe<CompanyMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CompanyMember` edge in the connection. */
export type CompanyMembersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CompanyMember` at the end of the edge. */
  node: Maybe<CompanyMember>;
};

/** Methods to use when ordering `CompanyMember`. */
export enum CompanyMembersOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IsAuthorizedSignatoryAsc = "IS_AUTHORIZED_SIGNATORY_ASC",
  IsAuthorizedSignatoryDesc = "IS_AUTHORIZED_SIGNATORY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RoleAsc = "ROLE_ASC",
  RoleDesc = "ROLE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type CompanyOffice = Node & {
  city: Scalars["String"]["output"];
  /** Reads a single `Company` that is related to this `CompanyOffice`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  country: Scalars["String"]["output"];
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["Int"]["output"];
  isEmployeeWorksite: Maybe<Scalars["Boolean"]["output"]>;
  label: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  postalCode: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  street: Scalars["String"]["output"];
  street2: Maybe<Scalars["String"]["output"]>;
  unitNumber: Maybe<Scalars["String"]["output"]>;
  unitType: Maybe<UnitTypeAddressEnum>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `UserDatum`. */
  userDataByOfficeId: UserDataConnection;
};

export type CompanyOfficeUserDataByOfficeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDatumCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

/**
 * A condition to be used against `CompanyOffice` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyOfficeCondition = {
  /** Checks for equality with the object’s `city` field. */
  city: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `country` field. */
  country: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isEmployeeWorksite` field. */
  isEmployeeWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `label` field. */
  label: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `state` field. */
  state: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `street` field. */
  street: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `street2` field. */
  street2: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unitNumber` field. */
  unitNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unitType` field. */
  unitType: InputMaybe<UnitTypeAddressEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `CompanyOffice` */
export type CompanyOfficeInput = {
  city: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  country: Scalars["String"]["input"];
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  isEmployeeWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  label: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  postalCode: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
  street2: InputMaybe<Scalars["String"]["input"]>;
  unitNumber: InputMaybe<Scalars["String"]["input"]>;
  unitType: InputMaybe<UnitTypeAddressEnum>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `CompanyOffice`. Fields that are set will be updated. */
export type CompanyOfficePatch = {
  city: InputMaybe<Scalars["String"]["input"]>;
  companyId: InputMaybe<Scalars["Int"]["input"]>;
  country: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  isEmployeeWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  label: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  postalCode: InputMaybe<Scalars["String"]["input"]>;
  state: InputMaybe<Scalars["String"]["input"]>;
  street: InputMaybe<Scalars["String"]["input"]>;
  street2: InputMaybe<Scalars["String"]["input"]>;
  unitNumber: InputMaybe<Scalars["String"]["input"]>;
  unitType: InputMaybe<UnitTypeAddressEnum>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `CompanyOffice` values. */
export type CompanyOfficesConnection = {
  /** A list of edges which contains the `CompanyOffice` and cursor to aid in pagination. */
  edges: Array<CompanyOfficesEdge>;
  /** A list of `CompanyOffice` objects. */
  nodes: Array<Maybe<CompanyOffice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyOffice` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CompanyOffice` edge in the connection. */
export type CompanyOfficesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CompanyOffice` at the end of the edge. */
  node: Maybe<CompanyOffice>;
};

/** Methods to use when ordering `CompanyOffice`. */
export enum CompanyOfficesOrderBy {
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsEmployeeWorksiteAsc = "IS_EMPLOYEE_WORKSITE_ASC",
  IsEmployeeWorksiteDesc = "IS_EMPLOYEE_WORKSITE_DESC",
  LabelAsc = "LABEL_ASC",
  LabelDesc = "LABEL_DESC",
  Natural = "NATURAL",
  PhoneNumberAsc = "PHONE_NUMBER_ASC",
  PhoneNumberDesc = "PHONE_NUMBER_DESC",
  PostalCodeAsc = "POSTAL_CODE_ASC",
  PostalCodeDesc = "POSTAL_CODE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StateAsc = "STATE_ASC",
  StateDesc = "STATE_DESC",
  Street2Asc = "STREET2_ASC",
  Street2Desc = "STREET2_DESC",
  StreetAsc = "STREET_ASC",
  StreetDesc = "STREET_DESC",
  UnitNumberAsc = "UNIT_NUMBER_ASC",
  UnitNumberDesc = "UNIT_NUMBER_DESC",
  UnitTypeAsc = "UNIT_TYPE_ASC",
  UnitTypeDesc = "UNIT_TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** Represents an update to a `Company`. Fields that are set will be updated. */
export type CompanyPatch = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  bio: InputMaybe<Scalars["String"]["input"]>;
  cityBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  companyGrossAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  companyNetAnnualIncome: InputMaybe<Scalars["BigInt"]["input"]>;
  companyTypeOfBusiness: InputMaybe<Scalars["String"]["input"]>;
  countryBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  currentNumberOfEmployees: InputMaybe<Scalars["Int"]["input"]>;
  dbaName: InputMaybe<Scalars["String"]["input"]>;
  ein: InputMaybe<Scalars["String"]["input"]>;
  incorporationYear: InputMaybe<Scalars["Int"]["input"]>;
  isBusinessAddressWorksite: InputMaybe<Scalars["Boolean"]["input"]>;
  legalName: InputMaybe<Scalars["String"]["input"]>;
  logoFileKey: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  stateBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  unitNumberBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
  unitTypeBusinessAddress: InputMaybe<UnitTypeAddressEnum>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  websiteUrl: InputMaybe<Scalars["String"]["input"]>;
  zipCodeBusinessAddress: InputMaybe<Scalars["String"]["input"]>;
};

export enum CompanyUserRole {
  Admin = "ADMIN",
  Member = "MEMBER",
  Viewer = "VIEWER",
}

export type CompiledPetition = Node & {
  attorneyUserId: Maybe<Scalars["BigInt"]["output"]>;
  createdAt: Scalars["Datetime"]["output"];
  creatorId: Scalars["BigInt"]["output"];
  displayName: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `CompiledPetition`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  jobId: Scalars["BigInt"]["output"];
  modalCallId: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `CompiledPetition`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Scalars["BigInt"]["output"];
  status: CompilerStatus;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByCreatorId: Maybe<User>;
};

/**
 * A condition to be used against `CompiledPetition` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompiledPetitionCondition = {
  /** Checks for equality with the object’s `attorneyUserId` field. */
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `creatorId` field. */
  creatorId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `modalCallId` field. */
  modalCallId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<CompilerStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `CompiledPetition` */
export type CompiledPetitionInput = {
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  creatorId: Scalars["BigInt"]["input"];
  displayName: InputMaybe<Scalars["String"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  modalCallId: InputMaybe<Scalars["String"]["input"]>;
  petitionId: Scalars["BigInt"]["input"];
  status: InputMaybe<CompilerStatus>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `CompiledPetition`. Fields that are set will be updated. */
export type CompiledPetitionPatch = {
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  creatorId: InputMaybe<Scalars["BigInt"]["input"]>;
  displayName: InputMaybe<Scalars["String"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  modalCallId: InputMaybe<Scalars["String"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  status: InputMaybe<CompilerStatus>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `CompiledPetition` values. */
export type CompiledPetitionsConnection = {
  /** A list of edges which contains the `CompiledPetition` and cursor to aid in pagination. */
  edges: Array<CompiledPetitionsEdge>;
  /** A list of `CompiledPetition` objects. */
  nodes: Array<Maybe<CompiledPetition>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompiledPetition` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CompiledPetition` edge in the connection. */
export type CompiledPetitionsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CompiledPetition` at the end of the edge. */
  node: Maybe<CompiledPetition>;
};

/** Methods to use when ordering `CompiledPetition`. */
export enum CompiledPetitionsOrderBy {
  AttorneyUserIdAsc = "ATTORNEY_USER_ID_ASC",
  AttorneyUserIdDesc = "ATTORNEY_USER_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatorIdAsc = "CREATOR_ID_ASC",
  CreatorIdDesc = "CREATOR_ID_DESC",
  DisplayNameAsc = "DISPLAY_NAME_ASC",
  DisplayNameDesc = "DISPLAY_NAME_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  JobIdAsc = "JOB_ID_ASC",
  JobIdDesc = "JOB_ID_DESC",
  ModalCallIdAsc = "MODAL_CALL_ID_ASC",
  ModalCallIdDesc = "MODAL_CALL_ID_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export enum CompilerStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Running = "RUNNING",
}

export enum ComponentTypes {
  Exhibit = "EXHIBIT",
  Module = "MODULE",
  Petition = "PETITION",
}

/** All input for the create `AdditionalEntity` mutation. */
export type CreateAdditionalEntityInput = {
  /** The `AdditionalEntity` to be created by this mutation. */
  additionalEntity: AdditionalEntityInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AdditionalEntity` mutation. */
export type CreateAdditionalEntityPayload = {
  /** The `AdditionalEntity` that was created by this mutation. */
  additionalEntity: Maybe<AdditionalEntity>;
  /** An edge for our `AdditionalEntity`. May be used by Relay 1. */
  additionalEntityEdge: Maybe<AdditionalEntitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `AdditionalEntity`. */
  companyByCompanyId: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AdditionalEntity`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `AdditionalEntity` mutation. */
export type CreateAdditionalEntityPayloadAdditionalEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

/** All input for the create `Admin` mutation. */
export type CreateAdminInput = {
  /** The `Admin` to be created by this mutation. */
  admin: AdminInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `Admin` mutation. */
export type CreateAdminPayload = {
  /** The `Admin` that was created by this mutation. */
  admin: Maybe<Admin>;
  /** An edge for our `Admin`. May be used by Relay 1. */
  adminEdge: Maybe<AdminsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Admin`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `Admin` mutation. */
export type CreateAdminPayloadAdminEdgeArgs = {
  orderBy?: InputMaybe<Array<AdminsOrderBy>>;
};

/** All input for the create `AuditLog` mutation. */
export type CreateAuditLogInput = {
  /** The `AuditLog` to be created by this mutation. */
  auditLog: AuditLogInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditLog` mutation. */
export type CreateAuditLogPayload = {
  /** The `AuditLog` that was created by this mutation. */
  auditLog: Maybe<AuditLog>;
  /** An edge for our `AuditLog`. May be used by Relay 1. */
  auditLogEdge: Maybe<AuditLogsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AuditLog` mutation. */
export type CreateAuditLogPayloadAuditLogEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditLogsOrderBy>>;
};

/** All input for the create `CaseStrategy` mutation. */
export type CreateCaseStrategyInput = {
  /** The `CaseStrategy` to be created by this mutation. */
  caseStrategy: CaseStrategyInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `CaseStrategy` mutation. */
export type CreateCaseStrategyPayload = {
  /** The `CaseStrategy` that was created by this mutation. */
  caseStrategy: Maybe<CaseStrategy>;
  /** An edge for our `CaseStrategy`. May be used by Relay 1. */
  caseStrategyEdge: Maybe<CaseStrategiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Petition` that is related to this `CaseStrategy`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CaseStrategy`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `CaseStrategy` mutation. */
export type CreateCaseStrategyPayloadCaseStrategyEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

/** All input for the create `CaseType` mutation. */
export type CreateCaseTypeInput = {
  /** The `CaseType` to be created by this mutation. */
  caseType: CaseTypeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `CaseType` mutation. */
export type CreateCaseTypePayload = {
  /** The `CaseType` that was created by this mutation. */
  caseType: Maybe<CaseType>;
  /** An edge for our `CaseType`. May be used by Relay 1. */
  caseTypeEdge: Maybe<CaseTypesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `CaseType` mutation. */
export type CreateCaseTypePayloadCaseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseTypesOrderBy>>;
};

/** All input for the create `CompanyDocument` mutation. */
export type CreateCompanyDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `CompanyDocument` to be created by this mutation. */
  companyDocument: CompanyDocumentInput;
};

/** The output of our create `CompanyDocument` mutation. */
export type CreateCompanyDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyDocument`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyDocument` that was created by this mutation. */
  companyDocument: Maybe<CompanyDocument>;
  /** An edge for our `CompanyDocument`. May be used by Relay 1. */
  companyDocumentEdge: Maybe<CompanyDocumentsEdge>;
  /** Reads a single `File` that is related to this `CompanyDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `CompanyDocument` mutation. */
export type CreateCompanyDocumentPayloadCompanyDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

/** All input for the create `Company` mutation. */
export type CreateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Company` to be created by this mutation. */
  company: CompanyInput;
};

/** All input for the create `CompanyMember` mutation. */
export type CreateCompanyMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `CompanyMember` to be created by this mutation. */
  companyMember: CompanyMemberInput;
};

/** The output of our create `CompanyMember` mutation. */
export type CreateCompanyMemberPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyMember`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyMember` that was created by this mutation. */
  companyMember: Maybe<CompanyMember>;
  /** An edge for our `CompanyMember`. May be used by Relay 1. */
  companyMemberEdge: Maybe<CompanyMembersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompanyMember`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `CompanyMember` mutation. */
export type CreateCompanyMemberPayloadCompanyMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

/** All input for the create `CompanyOffice` mutation. */
export type CreateCompanyOfficeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `CompanyOffice` to be created by this mutation. */
  companyOffice: CompanyOfficeInput;
};

/** The output of our create `CompanyOffice` mutation. */
export type CreateCompanyOfficePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyOffice`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyOffice` that was created by this mutation. */
  companyOffice: Maybe<CompanyOffice>;
  /** An edge for our `CompanyOffice`. May be used by Relay 1. */
  companyOfficeEdge: Maybe<CompanyOfficesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `CompanyOffice` mutation. */
export type CreateCompanyOfficePayloadCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyOfficesOrderBy>>;
};

/** The output of our create `Company` mutation. */
export type CreateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Company` that was created by this mutation. */
  company: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge: Maybe<CompaniesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `Company` mutation. */
export type CreateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the create `CompiledPetition` mutation. */
export type CreateCompiledPetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `CompiledPetition` to be created by this mutation. */
  compiledPetition: CompiledPetitionInput;
};

/** The output of our create `CompiledPetition` mutation. */
export type CreateCompiledPetitionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `CompiledPetition` that was created by this mutation. */
  compiledPetition: Maybe<CompiledPetition>;
  /** An edge for our `CompiledPetition`. May be used by Relay 1. */
  compiledPetitionEdge: Maybe<CompiledPetitionsEdge>;
  /** Reads a single `File` that is related to this `CompiledPetition`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `CompiledPetition`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByCreatorId: Maybe<User>;
};

/** The output of our create `CompiledPetition` mutation. */
export type CreateCompiledPetitionPayloadCompiledPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

/** All input for the create `CustomerCommsLog` mutation. */
export type CreateCustomerCommsLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `CustomerCommsLog` to be created by this mutation. */
  customerCommsLog: CustomerCommsLogInput;
};

/** The output of our create `CustomerCommsLog` mutation. */
export type CreateCustomerCommsLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CustomerCommsLog`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CustomerCommsLog` that was created by this mutation. */
  customerCommsLog: Maybe<CustomerCommsLog>;
  /** An edge for our `CustomerCommsLog`. May be used by Relay 1. */
  customerCommsLogEdge: Maybe<CustomerCommsLogsEdge>;
  /** Reads a single `Petition` that is related to this `CustomerCommsLog`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CustomerCommsLog`. */
  userByCustomerUserId: Maybe<User>;
};

/** The output of our create `CustomerCommsLog` mutation. */
export type CreateCustomerCommsLogPayloadCustomerCommsLogEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

/** All input for the create `EntityFile` mutation. */
export type CreateEntityFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `EntityFile` to be created by this mutation. */
  entityFile: EntityFileInput;
};

/** The output of our create `EntityFile` mutation. */
export type CreateEntityFilePayload = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityFile`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EntityFile` that was created by this mutation. */
  entityFile: Maybe<EntityFile>;
  /** An edge for our `EntityFile`. May be used by Relay 1. */
  entityFileEdge: Maybe<EntityFilesEdge>;
  /** Reads a single `File` that is related to this `EntityFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `EntityFile` mutation. */
export type CreateEntityFilePayloadEntityFileEdgeArgs = {
  orderBy?: InputMaybe<Array<EntityFilesOrderBy>>;
};

/** All input for the create `EntityRelation` mutation. */
export type CreateEntityRelationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `EntityRelation` to be created by this mutation. */
  entityRelation: EntityRelationInput;
};

/** The output of our create `EntityRelation` mutation. */
export type CreateEntityRelationPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityRelation`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EntityRelation` that was created by this mutation. */
  entityRelation: Maybe<EntityRelation>;
  /** An edge for our `EntityRelation`. May be used by Relay 1. */
  entityRelationEdge: Maybe<EntityRelationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `EntityRelation` mutation. */
export type CreateEntityRelationPayloadEntityRelationEdgeArgs = {
  orderBy?: InputMaybe<Array<EntityRelationsOrderBy>>;
};

/** All input for the create `EntitySchema` mutation. */
export type CreateEntitySchemaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `EntitySchema` to be created by this mutation. */
  entitySchema: EntitySchemaInput;
};

/** The output of our create `EntitySchema` mutation. */
export type CreateEntitySchemaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EntitySchema` that was created by this mutation. */
  entitySchema: Maybe<EntitySchema>;
  /** An edge for our `EntitySchema`. May be used by Relay 1. */
  entitySchemaEdge: Maybe<EntitySchemasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `EntitySchema` mutation. */
export type CreateEntitySchemaPayloadEntitySchemaEdgeArgs = {
  orderBy?: InputMaybe<Array<EntitySchemasOrderBy>>;
};

/** All input for the create `ExhibitFile` mutation. */
export type CreateExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ExhibitFile` to be created by this mutation. */
  exhibitFile: ExhibitFileInput;
};

/** The output of our create `ExhibitFile` mutation. */
export type CreateExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Exhibit` that is related to this `ExhibitFile`. */
  exhibitByExhibitId: Maybe<Exhibit>;
  /** The `ExhibitFile` that was created by this mutation. */
  exhibitFile: Maybe<ExhibitFile>;
  /** An edge for our `ExhibitFile`. May be used by Relay 1. */
  exhibitFileEdge: Maybe<ExhibitFilesEdge>;
  /** Reads a single `File` that is related to this `ExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ExhibitFile` mutation. */
export type CreateExhibitFilePayloadExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

/** All input for the create `Exhibit` mutation. */
export type CreateExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Exhibit` to be created by this mutation. */
  exhibit: ExhibitInput;
};

/** The output of our create `Exhibit` mutation. */
export type CreateExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Exhibit` that was created by this mutation. */
  exhibit: Maybe<Exhibit>;
  /** Reads a single `Exhibit` that is related to this `Exhibit`. */
  exhibitByParentExhibitId: Maybe<Exhibit>;
  /** An edge for our `Exhibit`. May be used by Relay 1. */
  exhibitEdge: Maybe<ExhibitsEdge>;
  /** Reads a single `ExhibitType` that is related to this `Exhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `Module` that is related to this `Exhibit`. */
  moduleByModuleId: Maybe<Module>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `Exhibit` mutation. */
export type CreateExhibitPayloadExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** All input for the create `ExhibitType` mutation. */
export type CreateExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ExhibitType` to be created by this mutation. */
  exhibitType: ExhibitTypeInput;
};

/** The output of our create `ExhibitType` mutation. */
export type CreateExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ExhibitType` that was created by this mutation. */
  exhibitType: Maybe<ExhibitType>;
  /** An edge for our `ExhibitType`. May be used by Relay 1. */
  exhibitTypeEdge: Maybe<ExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ExhibitType` mutation. */
export type CreateExhibitTypePayloadExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitTypesOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `File` that was created by this mutation. */
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `FlywaySchemaHistory` to be created by this mutation. */
  flywaySchemaHistory: FlywaySchemaHistoryInput;
};

/** The output of our create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `FlywaySchemaHistory` that was created by this mutation. */
  flywaySchemaHistory: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `FlywaySchemaHistory` mutation. */
export type CreateFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the create `I94Entry` mutation. */
export type CreateI94EntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `I94Entry` to be created by this mutation. */
  i94Entry: I94EntryInput;
};

/** The output of our create `I94Entry` mutation. */
export type CreateI94EntryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `I94Entry`. */
  fileByFileId: Maybe<File>;
  /** The `I94Entry` that was created by this mutation. */
  i94Entry: Maybe<I94Entry>;
  /** An edge for our `I94Entry`. May be used by Relay 1. */
  i94EntryEdge: Maybe<I94EntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `I94Entry`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `I94Entry` mutation. */
export type CreateI94EntryPayloadI94EntryEdgeArgs = {
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

/** All input for the create `ImmigrationDocument` mutation. */
export type CreateImmigrationDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ImmigrationDocument` to be created by this mutation. */
  immigrationDocument: ImmigrationDocumentInput;
};

/** The output of our create `ImmigrationDocument` mutation. */
export type CreateImmigrationDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `ImmigrationDocument`. */
  fileByFileId: Maybe<File>;
  /** The `ImmigrationDocument` that was created by this mutation. */
  immigrationDocument: Maybe<ImmigrationDocument>;
  /** An edge for our `ImmigrationDocument`. May be used by Relay 1. */
  immigrationDocumentEdge: Maybe<ImmigrationDocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ImmigrationDocument`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our create `ImmigrationDocument` mutation. */
export type CreateImmigrationDocumentPayloadImmigrationDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

/** All input for the create `IndividualVisaHistory` mutation. */
export type CreateIndividualVisaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `IndividualVisaHistory` to be created by this mutation. */
  individualVisaHistory: IndividualVisaHistoryInput;
};

/** The output of our create `IndividualVisaHistory` mutation. */
export type CreateIndividualVisaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `IndividualVisaHistory`. */
  fileByFileId: Maybe<File>;
  /** The `IndividualVisaHistory` that was created by this mutation. */
  individualVisaHistory: Maybe<IndividualVisaHistory>;
  /** An edge for our `IndividualVisaHistory`. May be used by Relay 1. */
  individualVisaHistoryEdge: Maybe<IndividualVisaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `IndividualVisaHistory`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `IndividualVisaHistory` mutation. */
export type CreateIndividualVisaHistoryPayloadIndividualVisaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

/** All input for the create `Module` mutation. */
export type CreateModuleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Module` to be created by this mutation. */
  module: ModuleInput;
};

/** The output of our create `Module` mutation. */
export type CreateModulePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Module` that was created by this mutation. */
  module: Maybe<Module>;
  /** An edge for our `Module`. May be used by Relay 1. */
  moduleEdge: Maybe<ModulesEdge>;
  /** Reads a single `ModuleType` that is related to this `Module`. */
  moduleTypeByType: Maybe<ModuleType>;
  /** Reads a single `Petition` that is related to this `Module`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `Module` mutation. */
export type CreateModulePayloadModuleEdgeArgs = {
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

/** All input for the create `ModuleType` mutation. */
export type CreateModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ModuleType` to be created by this mutation. */
  moduleType: ModuleTypeInput;
};

/** The output of our create `ModuleType` mutation. */
export type CreateModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ModuleType` that was created by this mutation. */
  moduleType: Maybe<ModuleType>;
  /** An edge for our `ModuleType`. May be used by Relay 1. */
  moduleTypeEdge: Maybe<ModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ModuleType` mutation. */
export type CreateModuleTypePayloadModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ModuleTypesOrderBy>>;
};

/** All input for the create `Note` mutation. */
export type CreateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Note` to be created by this mutation. */
  note: NoteInput;
};

/** The output of our create `Note` mutation. */
export type CreateNotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Note` that was created by this mutation. */
  note: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge: Maybe<NotesEdge>;
  /** Reads a single `Petition` that is related to this `Note`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  userBySenderId: Maybe<User>;
  /** Reads a single `User` that is related to this `Note`. */
  userByUserId: Maybe<User>;
};

/** The output of our create `Note` mutation. */
export type CreateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the create `OnboardingExhibitType` mutation. */
export type CreateOnboardingExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `OnboardingExhibitType` to be created by this mutation. */
  onboardingExhibitType: OnboardingExhibitTypeInput;
};

/** The output of our create `OnboardingExhibitType` mutation. */
export type CreateOnboardingExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `OnboardingExhibitType` that was created by this mutation. */
  onboardingExhibitType: Maybe<OnboardingExhibitType>;
  /** An edge for our `OnboardingExhibitType`. May be used by Relay 1. */
  onboardingExhibitTypeEdge: Maybe<OnboardingExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `OnboardingExhibitType` mutation. */
export type CreateOnboardingExhibitTypePayloadOnboardingExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingExhibitTypesOrderBy>>;
};

/** All input for the create `Onboarding` mutation. */
export type CreateOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Onboarding` to be created by this mutation. */
  onboarding: OnboardingInput;
};

/** All input for the create `OnboardingModuleType` mutation. */
export type CreateOnboardingModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `OnboardingModuleType` to be created by this mutation. */
  onboardingModuleType: OnboardingModuleTypeInput;
};

/** The output of our create `OnboardingModuleType` mutation. */
export type CreateOnboardingModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `OnboardingModuleType` that was created by this mutation. */
  onboardingModuleType: Maybe<OnboardingModuleType>;
  /** An edge for our `OnboardingModuleType`. May be used by Relay 1. */
  onboardingModuleTypeEdge: Maybe<OnboardingModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `OnboardingModuleType` mutation. */
export type CreateOnboardingModuleTypePayloadOnboardingModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingModuleTypesOrderBy>>;
};

/** The output of our create `Onboarding` mutation. */
export type CreateOnboardingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Onboarding` that was created by this mutation. */
  onboarding: Maybe<Onboarding>;
  /** An edge for our `Onboarding`. May be used by Relay 1. */
  onboardingEdge: Maybe<OnboardingsEdge>;
  /** Reads a single `Petition` that is related to this `Onboarding`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `Onboarding` mutation. */
export type CreateOnboardingPayloadOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingsOrderBy>>;
};

/** All input for the create `PetitionForm` mutation. */
export type CreatePetitionFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `PetitionForm` to be created by this mutation. */
  petitionForm: PetitionFormInput;
};

/** The output of our create `PetitionForm` mutation. */
export type CreatePetitionFormPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionForm`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionForm`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionForm` that was created by this mutation. */
  petitionForm: Maybe<PetitionForm>;
  /** An edge for our `PetitionForm`. May be used by Relay 1. */
  petitionFormEdge: Maybe<PetitionFormsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `PetitionForm` mutation. */
export type CreatePetitionFormPayloadPetitionFormEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

/** All input for the create `Petition` mutation. */
export type CreatePetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `Petition` to be created by this mutation. */
  petition: PetitionInput;
};

/** All input for the create `PetitionLetter` mutation. */
export type CreatePetitionLetterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `PetitionLetter` to be created by this mutation. */
  petitionLetter: PetitionLetterInput;
};

/** The output of our create `PetitionLetter` mutation. */
export type CreatePetitionLetterPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionLetter`. */
  fileByFinalFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionLetter`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionLetter` that was created by this mutation. */
  petitionLetter: Maybe<PetitionLetter>;
  /** An edge for our `PetitionLetter`. May be used by Relay 1. */
  petitionLetterEdge: Maybe<PetitionLettersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `PetitionLetter` mutation. */
export type CreatePetitionLetterPayloadPetitionLetterEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

/** All input for the create `PetitionLetterSignatory` mutation. */
export type CreatePetitionLetterSignatoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `PetitionLetterSignatory` to be created by this mutation. */
  petitionLetterSignatory: PetitionLetterSignatoryInput;
};

/** The output of our create `PetitionLetterSignatory` mutation. */
export type CreatePetitionLetterSignatoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `PetitionLetter` that is related to this `PetitionLetterSignatory`. */
  petitionLetterByLetterId: Maybe<PetitionLetter>;
  /** The `PetitionLetterSignatory` that was created by this mutation. */
  petitionLetterSignatory: Maybe<PetitionLetterSignatory>;
  /** An edge for our `PetitionLetterSignatory`. May be used by Relay 1. */
  petitionLetterSignatoryEdge: Maybe<PetitionLetterSignatoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `PetitionLetterSignatory`. */
  userBySignatoryId: Maybe<User>;
};

/** The output of our create `PetitionLetterSignatory` mutation. */
export type CreatePetitionLetterSignatoryPayloadPetitionLetterSignatoryEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
  };

/** The output of our create `Petition` mutation. */
export type CreatePetitionPayload = {
  /** Reads a single `CaseType` that is related to this `Petition`. */
  caseTypeByCaseType: Maybe<CaseType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `Petition`. */
  companyByPetitionerId: Maybe<Company>;
  /** The `Petition` that was created by this mutation. */
  petition: Maybe<Petition>;
  /** An edge for our `Petition`. May be used by Relay 1. */
  petitionEdge: Maybe<PetitionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByBeneficiaryId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByCsmUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByOaUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByPetitionerRepId: Maybe<User>;
};

/** The output of our create `Petition` mutation. */
export type CreatePetitionPayloadPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

/** All input for the create `ReusableExhibitEntity` mutation. */
export type CreateReusableExhibitEntityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ReusableExhibitEntity` to be created by this mutation. */
  reusableExhibitEntity: ReusableExhibitEntityInput;
};

/** The output of our create `ReusableExhibitEntity` mutation. */
export type CreateReusableExhibitEntityPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `ReusableExhibitEntity`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitEntity`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitEntity` that was created by this mutation. */
  reusableExhibitEntity: Maybe<ReusableExhibitEntity>;
  /** An edge for our `ReusableExhibitEntity`. May be used by Relay 1. */
  reusableExhibitEntityEdge: Maybe<ReusableExhibitEntitiesEdge>;
};

/** The output of our create `ReusableExhibitEntity` mutation. */
export type CreateReusableExhibitEntityPayloadReusableExhibitEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

/** All input for the create `ReusableExhibitFile` mutation. */
export type CreateReusableExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ReusableExhibitFile` to be created by this mutation. */
  reusableExhibitFile: ReusableExhibitFileInput;
};

/** The output of our create `ReusableExhibitFile` mutation. */
export type CreateReusableExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `ReusableExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitFile`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitFile` that was created by this mutation. */
  reusableExhibitFile: Maybe<ReusableExhibitFile>;
  /** An edge for our `ReusableExhibitFile`. May be used by Relay 1. */
  reusableExhibitFileEdge: Maybe<ReusableExhibitFilesEdge>;
};

/** The output of our create `ReusableExhibitFile` mutation. */
export type CreateReusableExhibitFilePayloadReusableExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

/** All input for the create `ReusableExhibit` mutation. */
export type CreateReusableExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `ReusableExhibit` to be created by this mutation. */
  reusableExhibit: ReusableExhibitInput;
};

/** The output of our create `ReusableExhibit` mutation. */
export type CreateReusableExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExhibitType` that is related to this `ReusableExhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `ModuleType` that is related to this `ReusableExhibit`. */
  moduleTypeByModuleType: Maybe<ModuleType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ReusableExhibit` that was created by this mutation. */
  reusableExhibit: Maybe<ReusableExhibit>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibit`. */
  reusableExhibitByParentExhibitId: Maybe<ReusableExhibit>;
  /** An edge for our `ReusableExhibit`. May be used by Relay 1. */
  reusableExhibitEdge: Maybe<ReusableExhibitsEdge>;
};

/** The output of our create `ReusableExhibit` mutation. */
export type CreateReusableExhibitPayloadReusableExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/** All input for the create `TaskQueue` mutation. */
export type CreateTaskQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `TaskQueue` to be created by this mutation. */
  taskQueue: TaskQueueInput;
};

/** The output of our create `TaskQueue` mutation. */
export type CreateTaskQueuePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskQueue` that was created by this mutation. */
  taskQueue: Maybe<TaskQueue>;
  /** An edge for our `TaskQueue`. May be used by Relay 1. */
  taskQueueEdge: Maybe<TaskQueuesEdge>;
};

/** The output of our create `TaskQueue` mutation. */
export type CreateTaskQueuePayloadTaskQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskQueuesOrderBy>>;
};

/** All input for the create `UserDatum` mutation. */
export type CreateUserDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `UserDatum` to be created by this mutation. */
  userDatum: UserDatumInput;
};

/** The output of our create `UserDatum` mutation. */
export type CreateUserDatumPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `CompanyOffice` that is related to this `UserDatum`. */
  companyOfficeByOfficeId: Maybe<CompanyOffice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDatum`. */
  userById: Maybe<User>;
  /** The `UserDatum` that was created by this mutation. */
  userDatum: Maybe<UserDatum>;
  /** An edge for our `UserDatum`. May be used by Relay 1. */
  userDatumEdge: Maybe<UserDataEdge>;
};

/** The output of our create `UserDatum` mutation. */
export type CreateUserDatumPayloadUserDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

/** All input for the create `UserDocument` mutation. */
export type CreateUserDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `UserDocument` to be created by this mutation. */
  userDocument: UserDocumentInput;
};

/** The output of our create `UserDocument` mutation. */
export type CreateUserDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `UserDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDocument`. */
  userByUserId: Maybe<User>;
  /** The `UserDocument` that was created by this mutation. */
  userDocument: Maybe<UserDocument>;
  /** An edge for our `UserDocument`. May be used by Relay 1. */
  userDocumentEdge: Maybe<UserDocumentsEdge>;
};

/** The output of our create `UserDocument` mutation. */
export type CreateUserDocumentPayloadUserDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

/** All input for the create `UserEmployment` mutation. */
export type CreateUserEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `UserEmployment` to be created by this mutation. */
  userEmployment: UserEmploymentInput;
};

/** The output of our create `UserEmployment` mutation. */
export type CreateUserEmploymentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `UserEmployment`. */
  companyByCompanyId: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmployment`. */
  userByUserId: Maybe<User>;
  /** The `UserEmployment` that was created by this mutation. */
  userEmployment: Maybe<UserEmployment>;
  /** An edge for our `UserEmployment`. May be used by Relay 1. */
  userEmploymentEdge: Maybe<UserEmploymentsEdge>;
};

/** The output of our create `UserEmployment` mutation. */
export type CreateUserEmploymentPayloadUserEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `VisaType` mutation. */
export type CreateVisaTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The `VisaType` to be created by this mutation. */
  visaType: VisaTypeInput;
};

/** The output of our create `VisaType` mutation. */
export type CreateVisaTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `VisaType` that was created by this mutation. */
  visaType: Maybe<VisaType>;
  /** An edge for our `VisaType`. May be used by Relay 1. */
  visaTypeEdge: Maybe<VisaTypesEdge>;
};

/** The output of our create `VisaType` mutation. */
export type CreateVisaTypePayloadVisaTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<VisaTypesOrderBy>>;
};

export type CustomerCommsLog = Node & {
  commContent: Maybe<Scalars["JSON"]["output"]>;
  commDate: Maybe<Scalars["Datetime"]["output"]>;
  commSource: Maybe<Scalars["String"]["output"]>;
  commType: CommTypeEnum;
  /** Reads a single `Company` that is related to this `CustomerCommsLog`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Maybe<Scalars["BigInt"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  customerUserId: Maybe<Scalars["BigInt"]["output"]>;
  logId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  participants: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `Petition` that is related to this `CustomerCommsLog`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Maybe<Scalars["BigInt"]["output"]>;
  recordId: Scalars["String"]["output"];
  status: CommStatusEnum;
  /** Reads a single `User` that is related to this `CustomerCommsLog`. */
  userByCustomerUserId: Maybe<User>;
};

/**
 * A condition to be used against `CustomerCommsLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CustomerCommsLogCondition = {
  /** Checks for equality with the object’s `commContent` field. */
  commContent: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `commDate` field. */
  commDate: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `commSource` field. */
  commSource: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `commType` field. */
  commType: InputMaybe<CommTypeEnum>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `customerUserId` field. */
  customerUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `logId` field. */
  logId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `participants` field. */
  participants: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `recordId` field. */
  recordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<CommStatusEnum>;
};

/** An input for mutations affecting `CustomerCommsLog` */
export type CustomerCommsLogInput = {
  commContent: InputMaybe<Scalars["JSON"]["input"]>;
  commDate: InputMaybe<Scalars["Datetime"]["input"]>;
  commSource: InputMaybe<Scalars["String"]["input"]>;
  commType: CommTypeEnum;
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  customerUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  logId: InputMaybe<Scalars["BigInt"]["input"]>;
  participants: InputMaybe<Scalars["JSON"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  recordId: Scalars["String"]["input"];
  status: InputMaybe<CommStatusEnum>;
};

/** Represents an update to a `CustomerCommsLog`. Fields that are set will be updated. */
export type CustomerCommsLogPatch = {
  commContent: InputMaybe<Scalars["JSON"]["input"]>;
  commDate: InputMaybe<Scalars["Datetime"]["input"]>;
  commSource: InputMaybe<Scalars["String"]["input"]>;
  commType: InputMaybe<CommTypeEnum>;
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  customerUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  logId: InputMaybe<Scalars["BigInt"]["input"]>;
  participants: InputMaybe<Scalars["JSON"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  recordId: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<CommStatusEnum>;
};

/** A connection to a list of `CustomerCommsLog` values. */
export type CustomerCommsLogsConnection = {
  /** A list of edges which contains the `CustomerCommsLog` and cursor to aid in pagination. */
  edges: Array<CustomerCommsLogsEdge>;
  /** A list of `CustomerCommsLog` objects. */
  nodes: Array<Maybe<CustomerCommsLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CustomerCommsLog` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CustomerCommsLog` edge in the connection. */
export type CustomerCommsLogsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CustomerCommsLog` at the end of the edge. */
  node: Maybe<CustomerCommsLog>;
};

/** Methods to use when ordering `CustomerCommsLog`. */
export enum CustomerCommsLogsOrderBy {
  CommContentAsc = "COMM_CONTENT_ASC",
  CommContentDesc = "COMM_CONTENT_DESC",
  CommDateAsc = "COMM_DATE_ASC",
  CommDateDesc = "COMM_DATE_DESC",
  CommSourceAsc = "COMM_SOURCE_ASC",
  CommSourceDesc = "COMM_SOURCE_DESC",
  CommTypeAsc = "COMM_TYPE_ASC",
  CommTypeDesc = "COMM_TYPE_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CustomerUserIdAsc = "CUSTOMER_USER_ID_ASC",
  CustomerUserIdDesc = "CUSTOMER_USER_ID_DESC",
  LogIdAsc = "LOG_ID_ASC",
  LogIdDesc = "LOG_ID_DESC",
  Natural = "NATURAL",
  ParticipantsAsc = "PARTICIPANTS_ASC",
  ParticipantsDesc = "PARTICIPANTS_DESC",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecordIdAsc = "RECORD_ID_ASC",
  RecordIdDesc = "RECORD_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
}

/** All input for the `deleteAdditionalEntityById` mutation. */
export type DeleteAdditionalEntityByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteAdditionalEntity` mutation. */
export type DeleteAdditionalEntityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AdditionalEntity` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `AdditionalEntity` mutation. */
export type DeleteAdditionalEntityPayload = {
  /** The `AdditionalEntity` that was deleted by this mutation. */
  additionalEntity: Maybe<AdditionalEntity>;
  /** An edge for our `AdditionalEntity`. May be used by Relay 1. */
  additionalEntityEdge: Maybe<AdditionalEntitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `AdditionalEntity`. */
  companyByCompanyId: Maybe<Company>;
  deletedAdditionalEntityId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AdditionalEntity`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `AdditionalEntity` mutation. */
export type DeleteAdditionalEntityPayloadAdditionalEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

/** All input for the `deleteAdminByUserId` mutation. */
export type DeleteAdminByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteAdmin` mutation. */
export type DeleteAdminInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Admin` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `Admin` mutation. */
export type DeleteAdminPayload = {
  /** The `Admin` that was deleted by this mutation. */
  admin: Maybe<Admin>;
  /** An edge for our `Admin`. May be used by Relay 1. */
  adminEdge: Maybe<AdminsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAdminId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Admin`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `Admin` mutation. */
export type DeleteAdminPayloadAdminEdgeArgs = {
  orderBy?: InputMaybe<Array<AdminsOrderBy>>;
};

/** All input for the `deleteAuditLogById` mutation. */
export type DeleteAuditLogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteAuditLog` mutation. */
export type DeleteAuditLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditLog` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `AuditLog` mutation. */
export type DeleteAuditLogPayload = {
  /** The `AuditLog` that was deleted by this mutation. */
  auditLog: Maybe<AuditLog>;
  /** An edge for our `AuditLog`. May be used by Relay 1. */
  auditLogEdge: Maybe<AuditLogsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAuditLogId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AuditLog` mutation. */
export type DeleteAuditLogPayloadAuditLogEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditLogsOrderBy>>;
};

/** All input for the `deleteCaseStrategyById` mutation. */
export type DeleteCaseStrategyByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteCaseStrategy` mutation. */
export type DeleteCaseStrategyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CaseStrategy` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CaseStrategy` mutation. */
export type DeleteCaseStrategyPayload = {
  /** The `CaseStrategy` that was deleted by this mutation. */
  caseStrategy: Maybe<CaseStrategy>;
  /** An edge for our `CaseStrategy`. May be used by Relay 1. */
  caseStrategyEdge: Maybe<CaseStrategiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedCaseStrategyId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Petition` that is related to this `CaseStrategy`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CaseStrategy`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `CaseStrategy` mutation. */
export type DeleteCaseStrategyPayloadCaseStrategyEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

/** All input for the `deleteCaseTypeById` mutation. */
export type DeleteCaseTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

/** All input for the `deleteCaseType` mutation. */
export type DeleteCaseTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CaseType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CaseType` mutation. */
export type DeleteCaseTypePayload = {
  /** The `CaseType` that was deleted by this mutation. */
  caseType: Maybe<CaseType>;
  /** An edge for our `CaseType`. May be used by Relay 1. */
  caseTypeEdge: Maybe<CaseTypesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedCaseTypeId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `CaseType` mutation. */
export type DeleteCaseTypePayloadCaseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseTypesOrderBy>>;
};

/** All input for the `deleteCompanyByAtRecordId` mutation. */
export type DeleteCompanyByAtRecordIdInput = {
  atRecordId: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the `deleteCompanyById` mutation. */
export type DeleteCompanyByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteCompanyDocumentByCompanyIdAndFileId` mutation. */
export type DeleteCompanyDocumentByCompanyIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteCompanyDocument` mutation. */
export type DeleteCompanyDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CompanyDocument` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CompanyDocument` mutation. */
export type DeleteCompanyDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyDocument`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyDocument` that was deleted by this mutation. */
  companyDocument: Maybe<CompanyDocument>;
  /** An edge for our `CompanyDocument`. May be used by Relay 1. */
  companyDocumentEdge: Maybe<CompanyDocumentsEdge>;
  deletedCompanyDocumentId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `CompanyDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `CompanyDocument` mutation. */
export type DeleteCompanyDocumentPayloadCompanyDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

/** All input for the `deleteCompany` mutation. */
export type DeleteCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Company` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** All input for the `deleteCompanyMemberByCompanyIdAndUserId` mutation. */
export type DeleteCompanyMemberByCompanyIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteCompanyMember` mutation. */
export type DeleteCompanyMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CompanyMember` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CompanyMember` mutation. */
export type DeleteCompanyMemberPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyMember`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyMember` that was deleted by this mutation. */
  companyMember: Maybe<CompanyMember>;
  /** An edge for our `CompanyMember`. May be used by Relay 1. */
  companyMemberEdge: Maybe<CompanyMembersEdge>;
  deletedCompanyMemberId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompanyMember`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `CompanyMember` mutation. */
export type DeleteCompanyMemberPayloadCompanyMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

/** All input for the `deleteCompanyOfficeById` mutation. */
export type DeleteCompanyOfficeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteCompanyOffice` mutation. */
export type DeleteCompanyOfficeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CompanyOffice` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CompanyOffice` mutation. */
export type DeleteCompanyOfficePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyOffice`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyOffice` that was deleted by this mutation. */
  companyOffice: Maybe<CompanyOffice>;
  /** An edge for our `CompanyOffice`. May be used by Relay 1. */
  companyOfficeEdge: Maybe<CompanyOfficesEdge>;
  deletedCompanyOfficeId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `CompanyOffice` mutation. */
export type DeleteCompanyOfficePayloadCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyOfficesOrderBy>>;
};

/** The output of our delete `Company` mutation. */
export type DeleteCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Company` that was deleted by this mutation. */
  company: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge: Maybe<CompaniesEdge>;
  deletedCompanyId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `Company` mutation. */
export type DeleteCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the `deleteCompiledPetitionByJobId` mutation. */
export type DeleteCompiledPetitionByJobIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  jobId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteCompiledPetition` mutation. */
export type DeleteCompiledPetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CompiledPetition` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CompiledPetition` mutation. */
export type DeleteCompiledPetitionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `CompiledPetition` that was deleted by this mutation. */
  compiledPetition: Maybe<CompiledPetition>;
  /** An edge for our `CompiledPetition`. May be used by Relay 1. */
  compiledPetitionEdge: Maybe<CompiledPetitionsEdge>;
  deletedCompiledPetitionId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `CompiledPetition`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `CompiledPetition`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByCreatorId: Maybe<User>;
};

/** The output of our delete `CompiledPetition` mutation. */
export type DeleteCompiledPetitionPayloadCompiledPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

/** All input for the `deleteCustomerCommsLogByLogId` mutation. */
export type DeleteCustomerCommsLogByLogIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  logId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteCustomerCommsLogByRecordIdAndCommSource` mutation. */
export type DeleteCustomerCommsLogByRecordIdAndCommSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  commSource: Scalars["String"]["input"];
  recordId: Scalars["String"]["input"];
};

/** All input for the `deleteCustomerCommsLog` mutation. */
export type DeleteCustomerCommsLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CustomerCommsLog` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `CustomerCommsLog` mutation. */
export type DeleteCustomerCommsLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CustomerCommsLog`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CustomerCommsLog` that was deleted by this mutation. */
  customerCommsLog: Maybe<CustomerCommsLog>;
  /** An edge for our `CustomerCommsLog`. May be used by Relay 1. */
  customerCommsLogEdge: Maybe<CustomerCommsLogsEdge>;
  deletedCustomerCommsLogId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Petition` that is related to this `CustomerCommsLog`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CustomerCommsLog`. */
  userByCustomerUserId: Maybe<User>;
};

/** The output of our delete `CustomerCommsLog` mutation. */
export type DeleteCustomerCommsLogPayloadCustomerCommsLogEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

/** All input for the `deleteEntityRelationByParentAndParentTypeAndEntityId` mutation. */
export type DeleteEntityRelationByParentAndParentTypeAndEntityIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  parent: Scalars["BigInt"]["input"];
  parentType: ComponentTypes;
};

/** The output of our delete `EntityRelation` mutation. */
export type DeleteEntityRelationPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityRelation`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedEntityRelationId: Maybe<Scalars["ID"]["output"]>;
  /** The `EntityRelation` that was deleted by this mutation. */
  entityRelation: Maybe<EntityRelation>;
  /** An edge for our `EntityRelation`. May be used by Relay 1. */
  entityRelationEdge: Maybe<EntityRelationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `EntityRelation` mutation. */
export type DeleteEntityRelationPayloadEntityRelationEdgeArgs = {
  orderBy?: InputMaybe<Array<EntityRelationsOrderBy>>;
};

/** All input for the `deleteEntitySchemaByEntityType` mutation. */
export type DeleteEntitySchemaByEntityTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  entityType: EntityTypes;
};

/** All input for the `deleteEntitySchema` mutation. */
export type DeleteEntitySchemaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `EntitySchema` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `EntitySchema` mutation. */
export type DeleteEntitySchemaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedEntitySchemaId: Maybe<Scalars["ID"]["output"]>;
  /** The `EntitySchema` that was deleted by this mutation. */
  entitySchema: Maybe<EntitySchema>;
  /** An edge for our `EntitySchema`. May be used by Relay 1. */
  entitySchemaEdge: Maybe<EntitySchemasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `EntitySchema` mutation. */
export type DeleteEntitySchemaPayloadEntitySchemaEdgeArgs = {
  orderBy?: InputMaybe<Array<EntitySchemasOrderBy>>;
};

/** All input for the `deleteExhibitById` mutation. */
export type DeleteExhibitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteExhibitFileByExhibitIdAndFileId` mutation. */
export type DeleteExhibitFileByExhibitIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  exhibitId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteExhibitFile` mutation. */
export type DeleteExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ExhibitFile` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ExhibitFile` mutation. */
export type DeleteExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExhibitFileId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Exhibit` that is related to this `ExhibitFile`. */
  exhibitByExhibitId: Maybe<Exhibit>;
  /** The `ExhibitFile` that was deleted by this mutation. */
  exhibitFile: Maybe<ExhibitFile>;
  /** An edge for our `ExhibitFile`. May be used by Relay 1. */
  exhibitFileEdge: Maybe<ExhibitFilesEdge>;
  /** Reads a single `File` that is related to this `ExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ExhibitFile` mutation. */
export type DeleteExhibitFilePayloadExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

/** All input for the `deleteExhibit` mutation. */
export type DeleteExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Exhibit` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `Exhibit` mutation. */
export type DeleteExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExhibitId: Maybe<Scalars["ID"]["output"]>;
  /** The `Exhibit` that was deleted by this mutation. */
  exhibit: Maybe<Exhibit>;
  /** Reads a single `Exhibit` that is related to this `Exhibit`. */
  exhibitByParentExhibitId: Maybe<Exhibit>;
  /** An edge for our `Exhibit`. May be used by Relay 1. */
  exhibitEdge: Maybe<ExhibitsEdge>;
  /** Reads a single `ExhibitType` that is related to this `Exhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `Module` that is related to this `Exhibit`. */
  moduleByModuleId: Maybe<Module>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `Exhibit` mutation. */
export type DeleteExhibitPayloadExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** All input for the `deleteExhibitTypeById` mutation. */
export type DeleteExhibitTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

/** All input for the `deleteExhibitType` mutation. */
export type DeleteExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ExhibitType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ExhibitType` mutation. */
export type DeleteExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExhibitTypeId: Maybe<Scalars["ID"]["output"]>;
  /** The `ExhibitType` that was deleted by this mutation. */
  exhibitType: Maybe<ExhibitType>;
  /** An edge for our `ExhibitType`. May be used by Relay 1. */
  exhibitTypeEdge: Maybe<ExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ExhibitType` mutation. */
export type DeleteExhibitTypePayloadExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitTypesOrderBy>>;
};

/** All input for the `deleteFileById` mutation. */
export type DeleteFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFileId: Maybe<Scalars["ID"]["output"]>;
  /** The `File` that was deleted by this mutation. */
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteFlywaySchemaHistoryByInstalledRank` mutation. */
export type DeleteFlywaySchemaHistoryByInstalledRankInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  installedRank: Scalars["Int"]["input"];
};

/** All input for the `deleteFlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `FlywaySchemaHistory` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `FlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFlywaySchemaHistoryId: Maybe<Scalars["ID"]["output"]>;
  /** The `FlywaySchemaHistory` that was deleted by this mutation. */
  flywaySchemaHistory: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `FlywaySchemaHistory` mutation. */
export type DeleteFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the `deleteI94EntryByI94Number` mutation. */
export type DeleteI94EntryByI94NumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  i94Number: Scalars["String"]["input"];
};

/** All input for the `deleteI94Entry` mutation. */
export type DeleteI94EntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `I94Entry` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `I94Entry` mutation. */
export type DeleteI94EntryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedI94EntryId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `I94Entry`. */
  fileByFileId: Maybe<File>;
  /** The `I94Entry` that was deleted by this mutation. */
  i94Entry: Maybe<I94Entry>;
  /** An edge for our `I94Entry`. May be used by Relay 1. */
  i94EntryEdge: Maybe<I94EntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `I94Entry`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `I94Entry` mutation. */
export type DeleteI94EntryPayloadI94EntryEdgeArgs = {
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

/** All input for the `deleteImmigrationDocumentByOwnerIdAndFileId` mutation. */
export type DeleteImmigrationDocumentByOwnerIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  ownerId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteImmigrationDocument` mutation. */
export type DeleteImmigrationDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ImmigrationDocument` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ImmigrationDocument` mutation. */
export type DeleteImmigrationDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedImmigrationDocumentId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `ImmigrationDocument`. */
  fileByFileId: Maybe<File>;
  /** The `ImmigrationDocument` that was deleted by this mutation. */
  immigrationDocument: Maybe<ImmigrationDocument>;
  /** An edge for our `ImmigrationDocument`. May be used by Relay 1. */
  immigrationDocumentEdge: Maybe<ImmigrationDocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ImmigrationDocument`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our delete `ImmigrationDocument` mutation. */
export type DeleteImmigrationDocumentPayloadImmigrationDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

/** All input for the `deleteIndividualVisaHistoryById` mutation. */
export type DeleteIndividualVisaHistoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteIndividualVisaHistoryByUserIdAndVisaNumber` mutation. */
export type DeleteIndividualVisaHistoryByUserIdAndVisaNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["BigInt"]["input"];
  visaNumber: Scalars["String"]["input"];
};

/** All input for the `deleteIndividualVisaHistory` mutation. */
export type DeleteIndividualVisaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `IndividualVisaHistory` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `IndividualVisaHistory` mutation. */
export type DeleteIndividualVisaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedIndividualVisaHistoryId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `IndividualVisaHistory`. */
  fileByFileId: Maybe<File>;
  /** The `IndividualVisaHistory` that was deleted by this mutation. */
  individualVisaHistory: Maybe<IndividualVisaHistory>;
  /** An edge for our `IndividualVisaHistory`. May be used by Relay 1. */
  individualVisaHistoryEdge: Maybe<IndividualVisaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `IndividualVisaHistory`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `IndividualVisaHistory` mutation. */
export type DeleteIndividualVisaHistoryPayloadIndividualVisaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

/** All input for the `deleteModuleById` mutation. */
export type DeleteModuleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteModule` mutation. */
export type DeleteModuleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Module` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `Module` mutation. */
export type DeleteModulePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedModuleId: Maybe<Scalars["ID"]["output"]>;
  /** The `Module` that was deleted by this mutation. */
  module: Maybe<Module>;
  /** An edge for our `Module`. May be used by Relay 1. */
  moduleEdge: Maybe<ModulesEdge>;
  /** Reads a single `ModuleType` that is related to this `Module`. */
  moduleTypeByType: Maybe<ModuleType>;
  /** Reads a single `Petition` that is related to this `Module`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `Module` mutation. */
export type DeleteModulePayloadModuleEdgeArgs = {
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

/** All input for the `deleteModuleTypeById` mutation. */
export type DeleteModuleTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

/** All input for the `deleteModuleType` mutation. */
export type DeleteModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ModuleType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ModuleType` mutation. */
export type DeleteModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedModuleTypeId: Maybe<Scalars["ID"]["output"]>;
  /** The `ModuleType` that was deleted by this mutation. */
  moduleType: Maybe<ModuleType>;
  /** An edge for our `ModuleType`. May be used by Relay 1. */
  moduleTypeEdge: Maybe<ModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ModuleType` mutation. */
export type DeleteModuleTypePayloadModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ModuleTypesOrderBy>>;
};

/** All input for the `deleteNoteById` mutation. */
export type DeleteNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteNote` mutation. */
export type DeleteNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Note` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `Note` mutation. */
export type DeleteNotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedNoteId: Maybe<Scalars["ID"]["output"]>;
  /** The `Note` that was deleted by this mutation. */
  note: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge: Maybe<NotesEdge>;
  /** Reads a single `Petition` that is related to this `Note`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  userBySenderId: Maybe<User>;
  /** Reads a single `User` that is related to this `Note`. */
  userByUserId: Maybe<User>;
};

/** The output of our delete `Note` mutation. */
export type DeleteNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `deleteOnboardingById` mutation. */
export type DeleteOnboardingByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteOnboardingExhibitTypeByExhibitType` mutation. */
export type DeleteOnboardingExhibitTypeByExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: Scalars["String"]["input"];
};

/** All input for the `deleteOnboardingExhibitType` mutation. */
export type DeleteOnboardingExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `OnboardingExhibitType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `OnboardingExhibitType` mutation. */
export type DeleteOnboardingExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedOnboardingExhibitTypeId: Maybe<Scalars["ID"]["output"]>;
  /** The `OnboardingExhibitType` that was deleted by this mutation. */
  onboardingExhibitType: Maybe<OnboardingExhibitType>;
  /** An edge for our `OnboardingExhibitType`. May be used by Relay 1. */
  onboardingExhibitTypeEdge: Maybe<OnboardingExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `OnboardingExhibitType` mutation. */
export type DeleteOnboardingExhibitTypePayloadOnboardingExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingExhibitTypesOrderBy>>;
};

/** All input for the `deleteOnboarding` mutation. */
export type DeleteOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Onboarding` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** All input for the `deleteOnboardingModuleTypeByModuleType` mutation. */
export type DeleteOnboardingModuleTypeByModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  moduleType: Scalars["String"]["input"];
};

/** All input for the `deleteOnboardingModuleType` mutation. */
export type DeleteOnboardingModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `OnboardingModuleType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `OnboardingModuleType` mutation. */
export type DeleteOnboardingModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedOnboardingModuleTypeId: Maybe<Scalars["ID"]["output"]>;
  /** The `OnboardingModuleType` that was deleted by this mutation. */
  onboardingModuleType: Maybe<OnboardingModuleType>;
  /** An edge for our `OnboardingModuleType`. May be used by Relay 1. */
  onboardingModuleTypeEdge: Maybe<OnboardingModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `OnboardingModuleType` mutation. */
export type DeleteOnboardingModuleTypePayloadOnboardingModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingModuleTypesOrderBy>>;
};

/** The output of our delete `Onboarding` mutation. */
export type DeleteOnboardingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedOnboardingId: Maybe<Scalars["ID"]["output"]>;
  /** The `Onboarding` that was deleted by this mutation. */
  onboarding: Maybe<Onboarding>;
  /** An edge for our `Onboarding`. May be used by Relay 1. */
  onboardingEdge: Maybe<OnboardingsEdge>;
  /** Reads a single `Petition` that is related to this `Onboarding`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `Onboarding` mutation. */
export type DeleteOnboardingPayloadOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingsOrderBy>>;
};

/** All input for the `deletePetitionById` mutation. */
export type DeletePetitionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deletePetitionFormById` mutation. */
export type DeletePetitionFormByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deletePetitionForm` mutation. */
export type DeletePetitionFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionForm` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `PetitionForm` mutation. */
export type DeletePetitionFormPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedPetitionFormId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionForm`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionForm`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionForm` that was deleted by this mutation. */
  petitionForm: Maybe<PetitionForm>;
  /** An edge for our `PetitionForm`. May be used by Relay 1. */
  petitionFormEdge: Maybe<PetitionFormsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `PetitionForm` mutation. */
export type DeletePetitionFormPayloadPetitionFormEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

/** All input for the `deletePetition` mutation. */
export type DeletePetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Petition` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** All input for the `deletePetitionLetterById` mutation. */
export type DeletePetitionLetterByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deletePetitionLetter` mutation. */
export type DeletePetitionLetterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionLetter` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `PetitionLetter` mutation. */
export type DeletePetitionLetterPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedPetitionLetterId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionLetter`. */
  fileByFinalFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionLetter`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionLetter` that was deleted by this mutation. */
  petitionLetter: Maybe<PetitionLetter>;
  /** An edge for our `PetitionLetter`. May be used by Relay 1. */
  petitionLetterEdge: Maybe<PetitionLettersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `PetitionLetter` mutation. */
export type DeletePetitionLetterPayloadPetitionLetterEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

/** All input for the `deletePetitionLetterSignatoryByLetterId` mutation. */
export type DeletePetitionLetterSignatoryByLetterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  letterId: Scalars["BigInt"]["input"];
};

/** All input for the `deletePetitionLetterSignatory` mutation. */
export type DeletePetitionLetterSignatoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionLetterSignatory` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `PetitionLetterSignatory` mutation. */
export type DeletePetitionLetterSignatoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedPetitionLetterSignatoryId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `PetitionLetter` that is related to this `PetitionLetterSignatory`. */
  petitionLetterByLetterId: Maybe<PetitionLetter>;
  /** The `PetitionLetterSignatory` that was deleted by this mutation. */
  petitionLetterSignatory: Maybe<PetitionLetterSignatory>;
  /** An edge for our `PetitionLetterSignatory`. May be used by Relay 1. */
  petitionLetterSignatoryEdge: Maybe<PetitionLetterSignatoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `PetitionLetterSignatory`. */
  userBySignatoryId: Maybe<User>;
};

/** The output of our delete `PetitionLetterSignatory` mutation. */
export type DeletePetitionLetterSignatoryPayloadPetitionLetterSignatoryEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
  };

/** The output of our delete `Petition` mutation. */
export type DeletePetitionPayload = {
  /** Reads a single `CaseType` that is related to this `Petition`. */
  caseTypeByCaseType: Maybe<CaseType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `Petition`. */
  companyByPetitionerId: Maybe<Company>;
  deletedPetitionId: Maybe<Scalars["ID"]["output"]>;
  /** The `Petition` that was deleted by this mutation. */
  petition: Maybe<Petition>;
  /** An edge for our `Petition`. May be used by Relay 1. */
  petitionEdge: Maybe<PetitionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByBeneficiaryId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByCsmUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByOaUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByPetitionerRepId: Maybe<User>;
};

/** The output of our delete `Petition` mutation. */
export type DeletePetitionPayloadPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

/** All input for the `deleteReusableExhibitById` mutation. */
export type DeleteReusableExhibitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteReusableExhibitEntityByReusableExhibitIdAndEntityId` mutation. */
export type DeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteReusableExhibitEntity` mutation. */
export type DeleteReusableExhibitEntityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibitEntity` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ReusableExhibitEntity` mutation. */
export type DeleteReusableExhibitEntityPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `ReusableExhibitEntity`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedReusableExhibitEntityId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitEntity`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitEntity` that was deleted by this mutation. */
  reusableExhibitEntity: Maybe<ReusableExhibitEntity>;
  /** An edge for our `ReusableExhibitEntity`. May be used by Relay 1. */
  reusableExhibitEntityEdge: Maybe<ReusableExhibitEntitiesEdge>;
};

/** The output of our delete `ReusableExhibitEntity` mutation. */
export type DeleteReusableExhibitEntityPayloadReusableExhibitEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

/** All input for the `deleteReusableExhibitFileByReusableExhibitIdAndFileId` mutation. */
export type DeleteReusableExhibitFileByReusableExhibitIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteReusableExhibitFile` mutation. */
export type DeleteReusableExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibitFile` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ReusableExhibitFile` mutation. */
export type DeleteReusableExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedReusableExhibitFileId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `ReusableExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitFile`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitFile` that was deleted by this mutation. */
  reusableExhibitFile: Maybe<ReusableExhibitFile>;
  /** An edge for our `ReusableExhibitFile`. May be used by Relay 1. */
  reusableExhibitFileEdge: Maybe<ReusableExhibitFilesEdge>;
};

/** The output of our delete `ReusableExhibitFile` mutation. */
export type DeleteReusableExhibitFilePayloadReusableExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

/** All input for the `deleteReusableExhibit` mutation. */
export type DeleteReusableExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibit` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `ReusableExhibit` mutation. */
export type DeleteReusableExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedReusableExhibitId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `ExhibitType` that is related to this `ReusableExhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `ModuleType` that is related to this `ReusableExhibit`. */
  moduleTypeByModuleType: Maybe<ModuleType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ReusableExhibit` that was deleted by this mutation. */
  reusableExhibit: Maybe<ReusableExhibit>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibit`. */
  reusableExhibitByParentExhibitId: Maybe<ReusableExhibit>;
  /** An edge for our `ReusableExhibit`. May be used by Relay 1. */
  reusableExhibitEdge: Maybe<ReusableExhibitsEdge>;
};

/** The output of our delete `ReusableExhibit` mutation. */
export type DeleteReusableExhibitPayloadReusableExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/** All input for the `deleteTaskQueueById` mutation. */
export type DeleteTaskQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTaskQueueByIdempotencyKey` mutation. */
export type DeleteTaskQueueByIdempotencyKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  idempotencyKey: Scalars["String"]["input"];
};

/** All input for the `deleteTaskQueue` mutation. */
export type DeleteTaskQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `TaskQueue` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `TaskQueue` mutation. */
export type DeleteTaskQueuePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTaskQueueId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskQueue` that was deleted by this mutation. */
  taskQueue: Maybe<TaskQueue>;
  /** An edge for our `TaskQueue`. May be used by Relay 1. */
  taskQueueEdge: Maybe<TaskQueuesEdge>;
};

/** The output of our delete `TaskQueue` mutation. */
export type DeleteTaskQueuePayloadTaskQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskQueuesOrderBy>>;
};

/** All input for the `deleteUserByAtRecordId` mutation. */
export type DeleteUserByAtRecordIdInput = {
  atRecordId: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the `deleteUserById` mutation. */
export type DeleteUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteUserDatumById` mutation. */
export type DeleteUserDatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteUserDatum` mutation. */
export type DeleteUserDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserDatum` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `UserDatum` mutation. */
export type DeleteUserDatumPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `CompanyOffice` that is related to this `UserDatum`. */
  companyOfficeByOfficeId: Maybe<CompanyOffice>;
  deletedUserDatumId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDatum`. */
  userById: Maybe<User>;
  /** The `UserDatum` that was deleted by this mutation. */
  userDatum: Maybe<UserDatum>;
  /** An edge for our `UserDatum`. May be used by Relay 1. */
  userDatumEdge: Maybe<UserDataEdge>;
};

/** The output of our delete `UserDatum` mutation. */
export type DeleteUserDatumPayloadUserDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

/** All input for the `deleteUserDocumentByUserIdAndFileId` mutation. */
export type DeleteUserDocumentByUserIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteUserDocument` mutation. */
export type DeleteUserDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserDocument` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `UserDocument` mutation. */
export type DeleteUserDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedUserDocumentId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `UserDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDocument`. */
  userByUserId: Maybe<User>;
  /** The `UserDocument` that was deleted by this mutation. */
  userDocument: Maybe<UserDocument>;
  /** An edge for our `UserDocument`. May be used by Relay 1. */
  userDocumentEdge: Maybe<UserDocumentsEdge>;
};

/** The output of our delete `UserDocument` mutation. */
export type DeleteUserDocumentPayloadUserDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

/** All input for the `deleteUserEmploymentById` mutation. */
export type DeleteUserEmploymentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteUserEmploymentByUserIdAndCompanyId` mutation. */
export type DeleteUserEmploymentByUserIdAndCompanyIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `deleteUserEmployment` mutation. */
export type DeleteUserEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserEmployment` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `UserEmployment` mutation. */
export type DeleteUserEmploymentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `UserEmployment`. */
  companyByCompanyId: Maybe<Company>;
  deletedUserEmploymentId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmployment`. */
  userByUserId: Maybe<User>;
  /** The `UserEmployment` that was deleted by this mutation. */
  userEmployment: Maybe<UserEmployment>;
  /** An edge for our `UserEmployment`. May be used by Relay 1. */
  userEmploymentEdge: Maybe<UserEmploymentsEdge>;
};

/** The output of our delete `UserEmployment` mutation. */
export type DeleteUserEmploymentPayloadUserEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedUserId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteVisaTypeByVisaTypeCode` mutation. */
export type DeleteVisaTypeByVisaTypeCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  visaTypeCode: Scalars["String"]["input"];
};

/** All input for the `deleteVisaType` mutation. */
export type DeleteVisaTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `VisaType` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our delete `VisaType` mutation. */
export type DeleteVisaTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedVisaTypeId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `VisaType` that was deleted by this mutation. */
  visaType: Maybe<VisaType>;
  /** An edge for our `VisaType`. May be used by Relay 1. */
  visaTypeEdge: Maybe<VisaTypesEdge>;
};

/** The output of our delete `VisaType` mutation. */
export type DeleteVisaTypePayloadVisaTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<VisaTypesOrderBy>>;
};

export type EntityFile = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityFile`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  entityId: Scalars["BigInt"]["output"];
  /** Reads a single `File` that is related to this `EntityFile`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  metadata: Maybe<Scalars["JSON"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `EntityFile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EntityFileCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `EntityFile` */
export type EntityFileInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `EntityFile` values. */
export type EntityFilesConnection = {
  /** A list of edges which contains the `EntityFile` and cursor to aid in pagination. */
  edges: Array<EntityFilesEdge>;
  /** A list of `EntityFile` objects. */
  nodes: Array<Maybe<EntityFile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntityFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EntityFile` edge in the connection. */
export type EntityFilesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EntityFile` at the end of the edge. */
  node: Maybe<EntityFile>;
};

/** Methods to use when ordering `EntityFile`. */
export enum EntityFilesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type EntityRelation = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityRelation`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  entityId: Scalars["BigInt"]["output"];
  parent: Scalars["BigInt"]["output"];
  parentType: ComponentTypes;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `EntityRelation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EntityRelationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `parent` field. */
  parent: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `parentType` field. */
  parentType: InputMaybe<ComponentTypes>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `EntityRelation` */
export type EntityRelationInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  parent: Scalars["BigInt"]["input"];
  parentType: ComponentTypes;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `EntityRelation`. Fields that are set will be updated. */
export type EntityRelationPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityId: InputMaybe<Scalars["BigInt"]["input"]>;
  parent: InputMaybe<Scalars["BigInt"]["input"]>;
  parentType: InputMaybe<ComponentTypes>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `EntityRelation` values. */
export type EntityRelationsConnection = {
  /** A list of edges which contains the `EntityRelation` and cursor to aid in pagination. */
  edges: Array<EntityRelationsEdge>;
  /** A list of `EntityRelation` objects. */
  nodes: Array<Maybe<EntityRelation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntityRelation` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EntityRelation` edge in the connection. */
export type EntityRelationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EntityRelation` at the end of the edge. */
  node: Maybe<EntityRelation>;
};

/** Methods to use when ordering `EntityRelation`. */
export enum EntityRelationsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  Natural = "NATURAL",
  ParentAsc = "PARENT_ASC",
  ParentDesc = "PARENT_DESC",
  ParentTypeAsc = "PARENT_TYPE_ASC",
  ParentTypeDesc = "PARENT_TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type EntitySchema = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  entityType: EntityTypes;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  schema: Scalars["JSON"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `EntitySchema` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EntitySchemaCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypes>;
  /** Checks for equality with the object’s `schema` field. */
  schema: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `EntitySchema` */
export type EntitySchemaInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityType: EntityTypes;
  schema: Scalars["JSON"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `EntitySchema`. Fields that are set will be updated. */
export type EntitySchemaPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityType: InputMaybe<EntityTypes>;
  schema: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `EntitySchema` values. */
export type EntitySchemasConnection = {
  /** A list of edges which contains the `EntitySchema` and cursor to aid in pagination. */
  edges: Array<EntitySchemasEdge>;
  /** A list of `EntitySchema` objects. */
  nodes: Array<Maybe<EntitySchema>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntitySchema` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EntitySchema` edge in the connection. */
export type EntitySchemasEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EntitySchema` at the end of the edge. */
  node: Maybe<EntitySchema>;
};

/** Methods to use when ordering `EntitySchema`. */
export enum EntitySchemasOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EntityTypeAsc = "ENTITY_TYPE_ASC",
  EntityTypeDesc = "ENTITY_TYPE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SchemaAsc = "SCHEMA_ASC",
  SchemaDesc = "SCHEMA_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type EntitySubscriptionPayload = {
  id: Maybe<Scalars["String"]["output"]>;
};

export enum EntityTypes {
  AuthorshipPublisher = "AUTHORSHIP_PUBLISHER",
  Award = "AWARD",
  Company = "COMPANY",
  JudgingEntity = "JUDGING_ENTITY",
  Location = "LOCATION",
  Membership = "MEMBERSHIP",
  PressPublisher = "PRESS_PUBLISHER",
  Role = "ROLE",
  User = "USER",
  Vc = "VC",
}

export type Exhibit = Node & {
  atRecordId: Maybe<Scalars["String"]["output"]>;
  compilerOverrideName: Scalars["Boolean"]["output"];
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Exhibit` that is related to this `Exhibit`. */
  exhibitByParentExhibitId: Maybe<Exhibit>;
  /** Reads and enables pagination through a set of `ExhibitFile`. */
  exhibitFilesByExhibitId: ExhibitFilesConnection;
  exhibitType: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExhibitType` that is related to this `Exhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads and enables pagination through a set of `Exhibit`. */
  exhibitsByParentExhibitId: ExhibitsConnection;
  id: Scalars["BigInt"]["output"];
  index: Maybe<Scalars["Int"]["output"]>;
  metaData: Maybe<Scalars["JSON"]["output"]>;
  metadata: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `Module` that is related to this `Exhibit`. */
  moduleByModuleId: Maybe<Module>;
  moduleId: Scalars["BigInt"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Scalars["Int"]["output"];
  ownedBy: ExhibitOwnerType;
  parentExhibitId: Maybe<Scalars["BigInt"]["output"]>;
  status: ExhibitStatusType;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  visible: Scalars["Boolean"]["output"];
};

export type ExhibitExhibitFilesByExhibitIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

export type ExhibitExhibitsByParentExhibitIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** A condition to be used against `Exhibit` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ExhibitCondition = {
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `compilerOverrideName` field. */
  compilerOverrideName: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `exhibitType` field. */
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `moduleId` field. */
  moduleId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `ownedBy` field. */
  ownedBy: InputMaybe<ExhibitOwnerType>;
  /** Checks for equality with the object’s `parentExhibitId` field. */
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ExhibitStatusType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `visible` field. */
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExhibitFile = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `Exhibit` that is related to this `ExhibitFile`. */
  exhibitByExhibitId: Maybe<Exhibit>;
  exhibitId: Scalars["BigInt"]["output"];
  /** Reads a single `File` that is related to this `ExhibitFile`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `ExhibitFile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ExhibitFileCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `exhibitId` field. */
  exhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ExhibitFile` */
export type ExhibitFileInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  exhibitId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ExhibitFile`. Fields that are set will be updated. */
export type ExhibitFilePatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  exhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ExhibitFileUpdateInputRecord` */
export type ExhibitFileUpdateInputRecordInput = {
  exhibitId: InputMaybe<Scalars["Int"]["input"]>;
  fileId: InputMaybe<Scalars["Int"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `ExhibitFile` values. */
export type ExhibitFilesConnection = {
  /** A list of edges which contains the `ExhibitFile` and cursor to aid in pagination. */
  edges: Array<ExhibitFilesEdge>;
  /** A list of `ExhibitFile` objects. */
  nodes: Array<Maybe<ExhibitFile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExhibitFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ExhibitFile` edge in the connection. */
export type ExhibitFilesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ExhibitFile` at the end of the edge. */
  node: Maybe<ExhibitFile>;
};

/** Methods to use when ordering `ExhibitFile`. */
export enum ExhibitFilesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  ExhibitIdAsc = "EXHIBIT_ID_ASC",
  ExhibitIdDesc = "EXHIBIT_ID_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** An input for mutations affecting `Exhibit` */
export type ExhibitInput = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  compilerOverrideName: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  metaData: InputMaybe<Scalars["JSON"]["input"]>;
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  moduleId: Scalars["BigInt"]["input"];
  name: Scalars["String"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownedBy: InputMaybe<ExhibitOwnerType>;
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  status: InputMaybe<ExhibitStatusType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `ExhibitOrderUpdateInputRecord` */
export type ExhibitOrderUpdateInputRecordInput = {
  exhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ExhibitOwnerType {
  Beneficiary = "BENEFICIARY",
  Petitioner = "PETITIONER",
  Plymouth = "PLYMOUTH",
}

/** Represents an update to a `Exhibit`. Fields that are set will be updated. */
export type ExhibitPatch = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  compilerOverrideName: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  metaData: InputMaybe<Scalars["JSON"]["input"]>;
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  moduleId: InputMaybe<Scalars["BigInt"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownedBy: InputMaybe<ExhibitOwnerType>;
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  status: InputMaybe<ExhibitStatusType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum ExhibitStatusType {
  ApprovedExhibit = "APPROVED_EXHIBIT",
  ApprovedExhibitDescription = "APPROVED_EXHIBIT_DESCRIPTION",
  CreateExhibitDescription = "CREATE_EXHIBIT_DESCRIPTION",
  DocumentApproval = "DOCUMENT_APPROVAL",
  DocumentUploaded = "DOCUMENT_UPLOADED",
  PendingDocuments = "PENDING_DOCUMENTS",
  ReviewExhibitDescription = "REVIEW_EXHIBIT_DESCRIPTION",
}

export type ExhibitType = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `Exhibit`. */
  exhibitsByExhibitType: ExhibitsConnection;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `ReusableExhibit`. */
  reusableExhibitsByExhibitType: ReusableExhibitsConnection;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

export type ExhibitTypeExhibitsByExhibitTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

export type ExhibitTypeReusableExhibitsByExhibitTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/**
 * A condition to be used against `ExhibitType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ExhibitTypeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ExhibitType` */
export type ExhibitTypeInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ExhibitType`. Fields that are set will be updated. */
export type ExhibitTypePatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `ExhibitType` values. */
export type ExhibitTypesConnection = {
  /** A list of edges which contains the `ExhibitType` and cursor to aid in pagination. */
  edges: Array<ExhibitTypesEdge>;
  /** A list of `ExhibitType` objects. */
  nodes: Array<Maybe<ExhibitType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExhibitType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ExhibitType` edge in the connection. */
export type ExhibitTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ExhibitType` at the end of the edge. */
  node: Maybe<ExhibitType>;
};

/** Methods to use when ordering `ExhibitType`. */
export enum ExhibitTypesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** A connection to a list of `Exhibit` values. */
export type ExhibitsConnection = {
  /** A list of edges which contains the `Exhibit` and cursor to aid in pagination. */
  edges: Array<ExhibitsEdge>;
  /** A list of `Exhibit` objects. */
  nodes: Array<Maybe<Exhibit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Exhibit` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Exhibit` edge in the connection. */
export type ExhibitsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Exhibit` at the end of the edge. */
  node: Maybe<Exhibit>;
};

/** Methods to use when ordering `Exhibit`. */
export enum ExhibitsOrderBy {
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  CompilerOverrideNameAsc = "COMPILER_OVERRIDE_NAME_ASC",
  CompilerOverrideNameDesc = "COMPILER_OVERRIDE_NAME_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  ExhibitTypeAsc = "EXHIBIT_TYPE_ASC",
  ExhibitTypeDesc = "EXHIBIT_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  ModuleIdAsc = "MODULE_ID_ASC",
  ModuleIdDesc = "MODULE_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  OwnedByAsc = "OWNED_BY_ASC",
  OwnedByDesc = "OWNED_BY_DESC",
  ParentExhibitIdAsc = "PARENT_EXHIBIT_ID_ASC",
  ParentExhibitIdDesc = "PARENT_EXHIBIT_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VisibleAsc = "VISIBLE_ASC",
  VisibleDesc = "VISIBLE_DESC",
}

export type File = Node & {
  atRecordId: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `CompanyDocument`. */
  companyDocumentsByFileId: CompanyDocumentsConnection;
  /** Reads and enables pagination through a set of `CompiledPetition`. */
  compiledPetitionsByFileId: CompiledPetitionsConnection;
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  /** Reads and enables pagination through a set of `EntityFile`. */
  entityFilesByFileId: EntityFilesConnection;
  /** Reads and enables pagination through a set of `ExhibitFile`. */
  exhibitFilesByFileId: ExhibitFilesConnection;
  /** Reads and enables pagination through a set of `I94Entry`. */
  i94EntriesByFileId: I94EntriesConnection;
  id: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `ImmigrationDocument`. */
  immigrationDocumentsByFileId: ImmigrationDocumentsConnection;
  /** Reads and enables pagination through a set of `IndividualVisaHistory`. */
  individualVisaHistoriesByFileId: IndividualVisaHistoriesConnection;
  mimeType: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  ocrStartTimestamp: Maybe<Scalars["Datetime"]["output"]>;
  ocrTimestamp: Maybe<Scalars["Datetime"]["output"]>;
  ownerId: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `PetitionForm`. */
  petitionFormsByFileId: PetitionFormsConnection;
  /** Reads and enables pagination through a set of `PetitionLetter`. */
  petitionLettersByFinalFileId: PetitionLettersConnection;
  /** Reads and enables pagination through a set of `ReusableExhibitFile`. */
  reusableExhibitFilesByFileId: ReusableExhibitFilesConnection;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `File`. */
  userByOwnerId: Maybe<User>;
  /** Reads and enables pagination through a set of `UserDocument`. */
  userDocumentsByFileId: UserDocumentsConnection;
};

export type FileCompanyDocumentsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

export type FileCompiledPetitionsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompiledPetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

export type FileEntityFilesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntityFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntityFilesOrderBy>>;
};

export type FileExhibitFilesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

export type FileI94EntriesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<I94EntryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

export type FileImmigrationDocumentsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ImmigrationDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

export type FileIndividualVisaHistoriesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<IndividualVisaHistoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

export type FilePetitionFormsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionFormCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

export type FilePetitionLettersByFinalFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

export type FileReusableExhibitFilesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

export type FileUserDocumentsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `mimeType` field. */
  mimeType: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ocrStartTimestamp` field. */
  ocrStartTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `ocrTimestamp` field. */
  ocrTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  mimeType: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  ocrStartTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  ocrTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  ownerId: Scalars["BigInt"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  mimeType: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  ocrStartTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  ocrTimestamp: InputMaybe<Scalars["Datetime"]["input"]>;
  ownerId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MimeTypeAsc = "MIME_TYPE_ASC",
  MimeTypeDesc = "MIME_TYPE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OcrStartTimestampAsc = "OCR_START_TIMESTAMP_ASC",
  OcrStartTimestampDesc = "OCR_START_TIMESTAMP_DESC",
  OcrTimestampAsc = "OCR_TIMESTAMP_ASC",
  OcrTimestampDesc = "OCR_TIMESTAMP_DESC",
  OwnerIdAsc = "OWNER_ID_ASC",
  OwnerIdDesc = "OWNER_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export enum FilingType {
  ChangeOfStatus = "CHANGE_OF_STATUS",
  ConsularProcessing = "CONSULAR_PROCESSING",
}

/** A connection to a list of `FlywaySchemaHistory` values. */
export type FlywaySchemaHistoriesConnection = {
  /** A list of edges which contains the `FlywaySchemaHistory` and cursor to aid in pagination. */
  edges: Array<FlywaySchemaHistoriesEdge>;
  /** A list of `FlywaySchemaHistory` objects. */
  nodes: Array<Maybe<FlywaySchemaHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FlywaySchemaHistory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FlywaySchemaHistory` edge in the connection. */
export type FlywaySchemaHistoriesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FlywaySchemaHistory` at the end of the edge. */
  node: Maybe<FlywaySchemaHistory>;
};

/** Methods to use when ordering `FlywaySchemaHistory`. */
export enum FlywaySchemaHistoriesOrderBy {
  ChecksumAsc = "CHECKSUM_ASC",
  ChecksumDesc = "CHECKSUM_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  ExecutionTimeAsc = "EXECUTION_TIME_ASC",
  ExecutionTimeDesc = "EXECUTION_TIME_DESC",
  InstalledByAsc = "INSTALLED_BY_ASC",
  InstalledByDesc = "INSTALLED_BY_DESC",
  InstalledOnAsc = "INSTALLED_ON_ASC",
  InstalledOnDesc = "INSTALLED_ON_DESC",
  InstalledRankAsc = "INSTALLED_RANK_ASC",
  InstalledRankDesc = "INSTALLED_RANK_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScriptAsc = "SCRIPT_ASC",
  ScriptDesc = "SCRIPT_DESC",
  SuccessAsc = "SUCCESS_ASC",
  SuccessDesc = "SUCCESS_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

export type FlywaySchemaHistory = Node & {
  checksum: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  executionTime: Scalars["Int"]["output"];
  installedBy: Scalars["String"]["output"];
  installedOn: Scalars["Datetime"]["output"];
  installedRank: Scalars["Int"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  script: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
  version: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `FlywaySchemaHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FlywaySchemaHistoryCondition = {
  /** Checks for equality with the object’s `checksum` field. */
  checksum: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `executionTime` field. */
  executionTime: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `installedBy` field. */
  installedBy: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `installedOn` field. */
  installedOn: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `installedRank` field. */
  installedRank: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `script` field. */
  script: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `success` field. */
  success: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `FlywaySchemaHistory` */
export type FlywaySchemaHistoryInput = {
  checksum: InputMaybe<Scalars["Int"]["input"]>;
  description: Scalars["String"]["input"];
  executionTime: Scalars["Int"]["input"];
  installedBy: Scalars["String"]["input"];
  installedOn: InputMaybe<Scalars["Datetime"]["input"]>;
  installedRank: Scalars["Int"]["input"];
  script: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
  type: Scalars["String"]["input"];
  version: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents an update to a `FlywaySchemaHistory`. Fields that are set will be updated. */
export type FlywaySchemaHistoryPatch = {
  checksum: InputMaybe<Scalars["Int"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  executionTime: InputMaybe<Scalars["Int"]["input"]>;
  installedBy: InputMaybe<Scalars["String"]["input"]>;
  installedOn: InputMaybe<Scalars["Datetime"]["input"]>;
  installedRank: InputMaybe<Scalars["Int"]["input"]>;
  script: InputMaybe<Scalars["String"]["input"]>;
  success: InputMaybe<Scalars["Boolean"]["input"]>;
  type: InputMaybe<Scalars["String"]["input"]>;
  version: InputMaybe<Scalars["String"]["input"]>;
};

export enum FormType {
  G28 = "G28",
  I129 = "I129",
  I140 = "I140",
  I907 = "I907",
}

/** All input for the `hasAccessToDocument` mutation. */
export type HasAccessToDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `hasAccessToDocument` mutation. */
export type HasAccessToDocumentPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `hasAccessToEmployment` mutation. */
export type HasAccessToEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  employmentId: InputMaybe<Scalars["Int"]["input"]>;
};

/** The output of our `hasAccessToEmployment` mutation. */
export type HasAccessToEmploymentPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `hasAccessToManageEmployment` mutation. */
export type HasAccessToManageEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  employmentId: InputMaybe<Scalars["Int"]["input"]>;
};

/** The output of our `hasAccessToManageEmployment` mutation. */
export type HasAccessToManageEmploymentPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `hasAccessToNote` mutation. */
export type HasAccessToNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `hasAccessToNote` mutation. */
export type HasAccessToNotePayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `hasAccessToUser` mutation. */
export type HasAccessToUserInput = {
  cid: InputMaybe<Scalars["BigInt"]["input"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `hasAccessToUser` mutation. */
export type HasAccessToUserPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** A connection to a list of `I94Entry` values. */
export type I94EntriesConnection = {
  /** A list of edges which contains the `I94Entry` and cursor to aid in pagination. */
  edges: Array<I94EntriesEdge>;
  /** A list of `I94Entry` objects. */
  nodes: Array<Maybe<I94Entry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `I94Entry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `I94Entry` edge in the connection. */
export type I94EntriesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `I94Entry` at the end of the edge. */
  node: Maybe<I94Entry>;
};

/** Methods to use when ordering `I94Entry`. */
export enum I94EntriesOrderBy {
  AdmitUntilDateAsc = "ADMIT_UNTIL_DATE_ASC",
  AdmitUntilDateDesc = "ADMIT_UNTIL_DATE_DESC",
  AdmitUntilStatusAsc = "ADMIT_UNTIL_STATUS_ASC",
  AdmitUntilStatusDesc = "ADMIT_UNTIL_STATUS_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DateOfEntryAsc = "DATE_OF_ENTRY_ASC",
  DateOfEntryDesc = "DATE_OF_ENTRY_DESC",
  DepartureDateAsc = "DEPARTURE_DATE_ASC",
  DepartureDateDesc = "DEPARTURE_DATE_DESC",
  DeparturePortAsc = "DEPARTURE_PORT_ASC",
  DeparturePortDesc = "DEPARTURE_PORT_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  I94NumberAsc = "I94_NUMBER_ASC",
  I94NumberDesc = "I94_NUMBER_DESC",
  Natural = "NATURAL",
  PassportNumberAsc = "PASSPORT_NUMBER_ASC",
  PassportNumberDesc = "PASSPORT_NUMBER_DESC",
  PortOfEntryAsc = "PORT_OF_ENTRY_ASC",
  PortOfEntryDesc = "PORT_OF_ENTRY_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  VisaClassAsc = "VISA_CLASS_ASC",
  VisaClassDesc = "VISA_CLASS_DESC",
}

export type I94Entry = Node & {
  admitUntilDate: Maybe<Scalars["Date"]["output"]>;
  admitUntilStatus: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  dateOfEntry: Scalars["Date"]["output"];
  departureDate: Maybe<Scalars["Date"]["output"]>;
  departurePort: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `I94Entry`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  i94Number: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  passportNumber: Scalars["String"]["output"];
  portOfEntry: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `I94Entry`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
  visaClass: Scalars["String"]["output"];
};

/**
 * A condition to be used against `I94Entry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type I94EntryCondition = {
  /** Checks for equality with the object’s `admitUntilDate` field. */
  admitUntilDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `admitUntilStatus` field. */
  admitUntilStatus: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfEntry` field. */
  dateOfEntry: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `departureDate` field. */
  departureDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `departurePort` field. */
  departurePort: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `i94Number` field. */
  i94Number: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `passportNumber` field. */
  passportNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `portOfEntry` field. */
  portOfEntry: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `visaClass` field. */
  visaClass: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `I94Entry` */
export type I94EntryInput = {
  admitUntilDate: InputMaybe<Scalars["Date"]["input"]>;
  admitUntilStatus: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfEntry: Scalars["Date"]["input"];
  departureDate: InputMaybe<Scalars["Date"]["input"]>;
  departurePort: InputMaybe<Scalars["String"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  i94Number: Scalars["String"]["input"];
  passportNumber: Scalars["String"]["input"];
  portOfEntry: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: Scalars["BigInt"]["input"];
  visaClass: Scalars["String"]["input"];
};

/** Represents an update to a `I94Entry`. Fields that are set will be updated. */
export type I94EntryPatch = {
  admitUntilDate: InputMaybe<Scalars["Date"]["input"]>;
  admitUntilStatus: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfEntry: InputMaybe<Scalars["Date"]["input"]>;
  departureDate: InputMaybe<Scalars["Date"]["input"]>;
  departurePort: InputMaybe<Scalars["String"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  i94Number: InputMaybe<Scalars["String"]["input"]>;
  passportNumber: InputMaybe<Scalars["String"]["input"]>;
  portOfEntry: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  visaClass: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `ImmigrationDocsUpdateInputRecord` */
export type ImmigrationDocsUpdateInputRecordInput = {
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownerId: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type ImmigrationDocument = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
  /** Reads a single `File` that is related to this `ImmigrationDocument`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Scalars["Int"]["output"];
  ownerId: Scalars["BigInt"]["output"];
  type: ImmigrationDocumentType;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `ImmigrationDocument`. */
  userByOwnerId: Maybe<User>;
};

/**
 * A condition to be used against `ImmigrationDocument` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ImmigrationDocumentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<ImmigrationDocumentType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ImmigrationDocument` */
export type ImmigrationDocumentInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownerId: Scalars["BigInt"]["input"];
  type: ImmigrationDocumentType;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ImmigrationDocument`. Fields that are set will be updated. */
export type ImmigrationDocumentPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownerId: InputMaybe<Scalars["BigInt"]["input"]>;
  type: InputMaybe<ImmigrationDocumentType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

export enum ImmigrationDocumentType {
  ApprovalNotice = "APPROVAL_NOTICE",
  Ds_2019 = "DS_2019",
  EadCard = "EAD_CARD",
  I_20 = "I_20",
  I_94 = "I_94",
  I_94History = "I_94_HISTORY",
  Passport = "PASSPORT",
  StudentVisa = "STUDENT_VISA",
  Visa = "VISA",
  VisaStamp = "VISA_STAMP",
}

/** A connection to a list of `ImmigrationDocument` values. */
export type ImmigrationDocumentsConnection = {
  /** A list of edges which contains the `ImmigrationDocument` and cursor to aid in pagination. */
  edges: Array<ImmigrationDocumentsEdge>;
  /** A list of `ImmigrationDocument` objects. */
  nodes: Array<Maybe<ImmigrationDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ImmigrationDocument` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ImmigrationDocument` edge in the connection. */
export type ImmigrationDocumentsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ImmigrationDocument` at the end of the edge. */
  node: Maybe<ImmigrationDocument>;
};

/** Methods to use when ordering `ImmigrationDocument`. */
export enum ImmigrationDocumentsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  OwnerIdAsc = "OWNER_ID_ASC",
  OwnerIdDesc = "OWNER_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export enum IndividualDocumentType {
  AcademicContractProposal = "ACADEMIC_CONTRACT_PROPOSAL",
  AcademicPaper = "ACADEMIC_PAPER",
  AoLetter = "AO_LETTER",
  AwardEvidence = "AWARD_EVIDENCE",
  BeneficiaryCv = "BENEFICIARY_CV",
  Calendar = "CALENDAR",
  CapTable = "CAP_TABLE",
  CitationPage = "CITATION_PAGE",
  CodeContributorPage = "CODE_CONTRIBUTOR_PAGE",
  CodeProjectOwnership = "CODE_PROJECT_OWNERSHIP",
  CompensationBenchmark = "COMPENSATION_BENCHMARK",
  CompensationBenchmarkSummary = "COMPENSATION_BENCHMARK_SUMMARY",
  ConferencePresentation = "CONFERENCE_PRESENTATION",
  CustomerContract = "CUSTOMER_CONTRACT",
  DistinguishedReputation = "DISTINGUISHED_REPUTATION",
  EmployerDocuments = "EMPLOYER_DOCUMENTS",
  EquityStatement = "EQUITY_STATEMENT",
  EvidenceOfCitation = "EVIDENCE_OF_CITATION",
  EvidenceOfCommercialAdoption = "EVIDENCE_OF_COMMERCIAL_ADOPTION",
  EvidenceOfCriticality = "EVIDENCE_OF_CRITICALITY",
  EvidenceOfIndustryAwards = "EVIDENCE_OF_INDUSTRY_AWARDS",
  EvidenceOfRecognition = "EVIDENCE_OF_RECOGNITION",
  EvidenceOfSelectionCriteria = "EVIDENCE_OF_SELECTION_CRITERIA",
  EvidenceOfSelectivity = "EVIDENCE_OF_SELECTIVITY",
  EvidenceOfSignificantUsage = "EVIDENCE_OF_SIGNIFICANT_USAGE",
  EvLetter = "EV_LETTER",
  ExpertLetter = "EXPERT_LETTER",
  FundingDocument = "FUNDING_DOCUMENT",
  GoogleScholarPage = "GOOGLE_SCHOLAR_PAGE",
  InternalDocs = "INTERNAL_DOCS",
  JournalArticle = "JOURNAL_ARTICLE",
  Judges = "JUDGES",
  JudgingDocumentation = "JUDGING_DOCUMENTATION",
  JudgingInviteOrConfirmation = "JUDGING_INVITE_OR_CONFIRMATION",
  LinkToEvent = "LINK_TO_EVENT",
  MembershipDocumentation = "MEMBERSHIP_DOCUMENTATION",
  ModuleAuthorship = "MODULE_AUTHORSHIP",
  ModuleAwards = "MODULE_AWARDS",
  ModuleCriticalRole = "MODULE_CRITICAL_ROLE",
  ModuleHighRemuneration = "MODULE_HIGH_REMUNERATION",
  ModuleJudging = "MODULE_JUDGING",
  ModuleMembership = "MODULE_MEMBERSHIP",
  ModuleOriginalContribution = "MODULE_ORIGINAL_CONTRIBUTION",
  ModulePress = "MODULE_PRESS",
  OccupationalOutlookHandbook = "OCCUPATIONAL_OUTLOOK_HANDBOOK",
  OfferLetter = "OFFER_LETTER",
  OrgChart = "ORG_CHART",
  OriginalCode = "ORIGINAL_CODE",
  OriginalContributionsSummary = "ORIGINAL_CONTRIBUTIONS_SUMMARY",
  Other = "OTHER",
  Patent = "PATENT",
  PayBonus = "PAY_BONUS",
  PayRaiseEvidence = "PAY_RAISE_EVIDENCE",
  PayStubs = "PAY_STUBS",
  PitchDeck = "PITCH_DECK",
  PressArticle = "PRESS_ARTICLE",
  PressSummary = "PRESS_SUMMARY",
  ProductRoadmap = "PRODUCT_ROADMAP",
  ProfileOfJudges = "PROFILE_OF_JUDGES",
  PromotionEvidence = "PROMOTION_EVIDENCE",
  ProofOfEnrollment = "PROOF_OF_ENROLLMENT",
  ProofOfMembershipLetterOrEmail = "PROOF_OF_MEMBERSHIP_LETTER_OR_EMAIL",
  ProofOfMembershipProfilePage = "PROOF_OF_MEMBERSHIP_PROFILE_PAGE",
  PublicationProminence = "PUBLICATION_PROMINENCE",
  ScreenshotOfEventOrPrizes = "SCREENSHOT_OF_EVENT_OR_PRIZES",
  SupportLetter = "SUPPORT_LETTER",
  TaxReturn = "TAX_RETURN",
  Tbd = "TBD",
  TechnicalDoc = "TECHNICAL_DOC",
  TnOccupationList = "TN_OCCUPATION_LIST",
  Transcript = "TRANSCRIPT",
  UscisCriticalRoleExcerpt = "USCIS_CRITICAL_ROLE_EXCERPT",
  UscisHighRemunerationExcerpt = "USCIS_HIGH_REMUNERATION_EXCERPT",
  UserCountOrRevenue = "USER_COUNT_OR_REVENUE",
  VcEvidence = "VC_EVIDENCE",
  VcProfiles = "VC_PROFILES",
}

/** A connection to a list of `IndividualVisaHistory` values. */
export type IndividualVisaHistoriesConnection = {
  /** A list of edges which contains the `IndividualVisaHistory` and cursor to aid in pagination. */
  edges: Array<IndividualVisaHistoriesEdge>;
  /** A list of `IndividualVisaHistory` objects. */
  nodes: Array<Maybe<IndividualVisaHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IndividualVisaHistory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `IndividualVisaHistory` edge in the connection. */
export type IndividualVisaHistoriesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `IndividualVisaHistory` at the end of the edge. */
  node: Maybe<IndividualVisaHistory>;
};

/** Methods to use when ordering `IndividualVisaHistory`. */
export enum IndividualVisaHistoriesOrderBy {
  CountryOfIssuanceAsc = "COUNTRY_OF_ISSUANCE_ASC",
  CountryOfIssuanceDesc = "COUNTRY_OF_ISSUANCE_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  ExpirationDateAsc = "EXPIRATION_DATE_ASC",
  ExpirationDateDesc = "EXPIRATION_DATE_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FilingTypeAsc = "FILING_TYPE_ASC",
  FilingTypeDesc = "FILING_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IssueDateAsc = "ISSUE_DATE_ASC",
  IssueDateDesc = "ISSUE_DATE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RemarksAsc = "REMARKS_ASC",
  RemarksDesc = "REMARKS_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  VisaNumberAsc = "VISA_NUMBER_ASC",
  VisaNumberDesc = "VISA_NUMBER_DESC",
  VisaTypeAsc = "VISA_TYPE_ASC",
  VisaTypeDesc = "VISA_TYPE_DESC",
}

export type IndividualVisaHistory = Node & {
  countryOfIssuance: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  expirationDate: Scalars["Date"]["output"];
  /** Reads a single `File` that is related to this `IndividualVisaHistory`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  filingType: Maybe<FilingType>;
  id: Scalars["BigInt"]["output"];
  issueDate: Scalars["Date"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  remarks: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `IndividualVisaHistory`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
  visaNumber: Scalars["String"]["output"];
  visaType: Scalars["String"]["output"];
};

/**
 * A condition to be used against `IndividualVisaHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IndividualVisaHistoryCondition = {
  /** Checks for equality with the object’s `countryOfIssuance` field. */
  countryOfIssuance: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `expirationDate` field. */
  expirationDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `filingType` field. */
  filingType: InputMaybe<FilingType>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `issueDate` field. */
  issueDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `remarks` field. */
  remarks: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `visaNumber` field. */
  visaNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visaType` field. */
  visaType: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `IndividualVisaHistory` */
export type IndividualVisaHistoryInput = {
  countryOfIssuance: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  expirationDate: Scalars["Date"]["input"];
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  filingType: InputMaybe<FilingType>;
  issueDate: Scalars["Date"]["input"];
  remarks: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: Scalars["BigInt"]["input"];
  visaNumber: Scalars["String"]["input"];
  visaType: Scalars["String"]["input"];
};

/** Represents an update to a `IndividualVisaHistory`. Fields that are set will be updated. */
export type IndividualVisaHistoryPatch = {
  countryOfIssuance: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  expirationDate: InputMaybe<Scalars["Date"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  filingType: InputMaybe<FilingType>;
  issueDate: InputMaybe<Scalars["Date"]["input"]>;
  remarks: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  visaNumber: InputMaybe<Scalars["String"]["input"]>;
  visaType: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the `isCurrentUserAdminMember` mutation. */
export type IsCurrentUserAdminMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `isCurrentUserAdminMember` mutation. */
export type IsCurrentUserAdminMemberPayload = {
  boolean: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export enum LetterStatus {
  Approved = "APPROVED",
  Drafted = "DRAFTED",
  PendingBeneficiaryFeedback = "PENDING_BENEFICIARY_FEEDBACK",
  PendingDraft = "PENDING_DRAFT",
  PendingSignatoryFeedback = "PENDING_SIGNATORY_FEEDBACK",
}

export enum LetterType {
  AdvisoryOpinion = "ADVISORY_OPINION",
  EmployerVerification = "EMPLOYER_VERIFICATION",
}

export type Module = Node & {
  atRecordId: Maybe<Scalars["String"]["output"]>;
  compilerHideTitleRow: Scalars["Boolean"]["output"];
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `Exhibit`. */
  exhibitsByModuleId: ExhibitsConnection;
  id: Scalars["BigInt"]["output"];
  /** Reads a single `ModuleType` that is related to this `Module`. */
  moduleTypeByType: Maybe<ModuleType>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Scalars["Int"]["output"];
  /** Reads a single `Petition` that is related to this `Module`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Scalars["BigInt"]["output"];
  type: Scalars["String"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  visible: Scalars["Boolean"]["output"];
};

export type ModuleExhibitsByModuleIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** A condition to be used against `Module` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ModuleCondition = {
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `compilerHideTitleRow` field. */
  compilerHideTitleRow: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `visible` field. */
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Module` */
export type ModuleInput = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  compilerHideTitleRow: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  petitionId: Scalars["BigInt"]["input"];
  type: Scalars["String"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `ModuleOrderUpdateInputRecord` */
export type ModuleOrderUpdateInputRecordInput = {
  moduleId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
};

/** Represents an update to a `Module`. Fields that are set will be updated. */
export type ModulePatch = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  compilerHideTitleRow: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  type: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleType = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  inputs: Scalars["JSON"]["output"];
  /** Reads and enables pagination through a set of `Module`. */
  modulesByType: ModulesConnection;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `ReusableExhibit`. */
  reusableExhibitsByModuleType: ReusableExhibitsConnection;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

export type ModuleTypeModulesByTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ModuleCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

export type ModuleTypeReusableExhibitsByModuleTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/**
 * A condition to be used against `ModuleType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ModuleTypeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `inputs` field. */
  inputs: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ModuleType` */
export type ModuleTypeInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  inputs: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ModuleType`. Fields that are set will be updated. */
export type ModuleTypePatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  id: InputMaybe<Scalars["String"]["input"]>;
  inputs: InputMaybe<Scalars["JSON"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `ModuleType` values. */
export type ModuleTypesConnection = {
  /** A list of edges which contains the `ModuleType` and cursor to aid in pagination. */
  edges: Array<ModuleTypesEdge>;
  /** A list of `ModuleType` objects. */
  nodes: Array<Maybe<ModuleType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModuleType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ModuleType` edge in the connection. */
export type ModuleTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ModuleType` at the end of the edge. */
  node: Maybe<ModuleType>;
};

/** Methods to use when ordering `ModuleType`. */
export enum ModuleTypesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InputsAsc = "INPUTS_ASC",
  InputsDesc = "INPUTS_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** A connection to a list of `Module` values. */
export type ModulesConnection = {
  /** A list of edges which contains the `Module` and cursor to aid in pagination. */
  edges: Array<ModulesEdge>;
  /** A list of `Module` objects. */
  nodes: Array<Maybe<Module>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Module` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Module` edge in the connection. */
export type ModulesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Module` at the end of the edge. */
  node: Maybe<Module>;
};

/** Methods to use when ordering `Module`. */
export enum ModulesOrderBy {
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  CompilerHideTitleRowAsc = "COMPILER_HIDE_TITLE_ROW_ASC",
  CompilerHideTitleRowDesc = "COMPILER_HIDE_TITLE_ROW_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VisibleAsc = "VISIBLE_ASC",
  VisibleDesc = "VISIBLE_DESC",
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  adminInsertCompanyCheck: Maybe<AdminInsertCompanyCheckPayload>;
  /** Creates a single `AdditionalEntity`. */
  createAdditionalEntity: Maybe<CreateAdditionalEntityPayload>;
  /** Creates a single `Admin`. */
  createAdmin: Maybe<CreateAdminPayload>;
  /** Creates a single `AuditLog`. */
  createAuditLog: Maybe<CreateAuditLogPayload>;
  /** Creates a single `CaseStrategy`. */
  createCaseStrategy: Maybe<CreateCaseStrategyPayload>;
  /** Creates a single `CaseType`. */
  createCaseType: Maybe<CreateCaseTypePayload>;
  /** Creates a single `Company`. */
  createCompany: Maybe<CreateCompanyPayload>;
  /** Creates a single `CompanyDocument`. */
  createCompanyDocument: Maybe<CreateCompanyDocumentPayload>;
  /** Creates a single `CompanyMember`. */
  createCompanyMember: Maybe<CreateCompanyMemberPayload>;
  /** Creates a single `CompanyOffice`. */
  createCompanyOffice: Maybe<CreateCompanyOfficePayload>;
  /** Creates a single `CompiledPetition`. */
  createCompiledPetition: Maybe<CreateCompiledPetitionPayload>;
  /** Creates a single `CustomerCommsLog`. */
  createCustomerCommsLog: Maybe<CreateCustomerCommsLogPayload>;
  /** Creates a single `EntityFile`. */
  createEntityFile: Maybe<CreateEntityFilePayload>;
  /** Creates a single `EntityRelation`. */
  createEntityRelation: Maybe<CreateEntityRelationPayload>;
  /** Creates a single `EntitySchema`. */
  createEntitySchema: Maybe<CreateEntitySchemaPayload>;
  /** Creates a single `Exhibit`. */
  createExhibit: Maybe<CreateExhibitPayload>;
  /** Creates a single `ExhibitFile`. */
  createExhibitFile: Maybe<CreateExhibitFilePayload>;
  /** Creates a single `ExhibitType`. */
  createExhibitType: Maybe<CreateExhibitTypePayload>;
  /** Creates a single `File`. */
  createFile: Maybe<CreateFilePayload>;
  /** Creates a single `FlywaySchemaHistory`. */
  createFlywaySchemaHistory: Maybe<CreateFlywaySchemaHistoryPayload>;
  /** Creates a single `I94Entry`. */
  createI94Entry: Maybe<CreateI94EntryPayload>;
  /** Creates a single `ImmigrationDocument`. */
  createImmigrationDocument: Maybe<CreateImmigrationDocumentPayload>;
  /** Creates a single `IndividualVisaHistory`. */
  createIndividualVisaHistory: Maybe<CreateIndividualVisaHistoryPayload>;
  /** Creates a single `Module`. */
  createModule: Maybe<CreateModulePayload>;
  /** Creates a single `ModuleType`. */
  createModuleType: Maybe<CreateModuleTypePayload>;
  /** Creates a single `Note`. */
  createNote: Maybe<CreateNotePayload>;
  /** Creates a single `Onboarding`. */
  createOnboarding: Maybe<CreateOnboardingPayload>;
  /** Creates a single `OnboardingExhibitType`. */
  createOnboardingExhibitType: Maybe<CreateOnboardingExhibitTypePayload>;
  /** Creates a single `OnboardingModuleType`. */
  createOnboardingModuleType: Maybe<CreateOnboardingModuleTypePayload>;
  /** Creates a single `Petition`. */
  createPetition: Maybe<CreatePetitionPayload>;
  /** Creates a single `PetitionForm`. */
  createPetitionForm: Maybe<CreatePetitionFormPayload>;
  /** Creates a single `PetitionLetter`. */
  createPetitionLetter: Maybe<CreatePetitionLetterPayload>;
  /** Creates a single `PetitionLetterSignatory`. */
  createPetitionLetterSignatory: Maybe<CreatePetitionLetterSignatoryPayload>;
  /** Creates a single `ReusableExhibit`. */
  createReusableExhibit: Maybe<CreateReusableExhibitPayload>;
  /** Creates a single `ReusableExhibitEntity`. */
  createReusableExhibitEntity: Maybe<CreateReusableExhibitEntityPayload>;
  /** Creates a single `ReusableExhibitFile`. */
  createReusableExhibitFile: Maybe<CreateReusableExhibitFilePayload>;
  /** Creates a single `TaskQueue`. */
  createTaskQueue: Maybe<CreateTaskQueuePayload>;
  /** Creates a single `User`. */
  createUser: Maybe<CreateUserPayload>;
  /** Creates a single `UserDatum`. */
  createUserDatum: Maybe<CreateUserDatumPayload>;
  /** Creates a single `UserDocument`. */
  createUserDocument: Maybe<CreateUserDocumentPayload>;
  /** Creates a single `UserEmployment`. */
  createUserEmployment: Maybe<CreateUserEmploymentPayload>;
  /** Creates a single `VisaType`. */
  createVisaType: Maybe<CreateVisaTypePayload>;
  /** Deletes a single `AdditionalEntity` using its globally unique id. */
  deleteAdditionalEntity: Maybe<DeleteAdditionalEntityPayload>;
  /** Deletes a single `AdditionalEntity` using a unique key. */
  deleteAdditionalEntityById: Maybe<DeleteAdditionalEntityPayload>;
  /** Deletes a single `Admin` using its globally unique id. */
  deleteAdmin: Maybe<DeleteAdminPayload>;
  /** Deletes a single `Admin` using a unique key. */
  deleteAdminByUserId: Maybe<DeleteAdminPayload>;
  /** Deletes a single `AuditLog` using its globally unique id. */
  deleteAuditLog: Maybe<DeleteAuditLogPayload>;
  /** Deletes a single `AuditLog` using a unique key. */
  deleteAuditLogById: Maybe<DeleteAuditLogPayload>;
  /** Deletes a single `CaseStrategy` using its globally unique id. */
  deleteCaseStrategy: Maybe<DeleteCaseStrategyPayload>;
  /** Deletes a single `CaseStrategy` using a unique key. */
  deleteCaseStrategyById: Maybe<DeleteCaseStrategyPayload>;
  /** Deletes a single `CaseType` using its globally unique id. */
  deleteCaseType: Maybe<DeleteCaseTypePayload>;
  /** Deletes a single `CaseType` using a unique key. */
  deleteCaseTypeById: Maybe<DeleteCaseTypePayload>;
  /** Deletes a single `Company` using its globally unique id. */
  deleteCompany: Maybe<DeleteCompanyPayload>;
  /** Deletes a single `Company` using a unique key. */
  deleteCompanyByAtRecordId: Maybe<DeleteCompanyPayload>;
  /** Deletes a single `Company` using a unique key. */
  deleteCompanyById: Maybe<DeleteCompanyPayload>;
  /** Deletes a single `CompanyDocument` using its globally unique id. */
  deleteCompanyDocument: Maybe<DeleteCompanyDocumentPayload>;
  /** Deletes a single `CompanyDocument` using a unique key. */
  deleteCompanyDocumentByCompanyIdAndFileId: Maybe<DeleteCompanyDocumentPayload>;
  /** Deletes a single `CompanyMember` using its globally unique id. */
  deleteCompanyMember: Maybe<DeleteCompanyMemberPayload>;
  /** Deletes a single `CompanyMember` using a unique key. */
  deleteCompanyMemberByCompanyIdAndUserId: Maybe<DeleteCompanyMemberPayload>;
  /** Deletes a single `CompanyOffice` using its globally unique id. */
  deleteCompanyOffice: Maybe<DeleteCompanyOfficePayload>;
  /** Deletes a single `CompanyOffice` using a unique key. */
  deleteCompanyOfficeById: Maybe<DeleteCompanyOfficePayload>;
  /** Deletes a single `CompiledPetition` using its globally unique id. */
  deleteCompiledPetition: Maybe<DeleteCompiledPetitionPayload>;
  /** Deletes a single `CompiledPetition` using a unique key. */
  deleteCompiledPetitionByJobId: Maybe<DeleteCompiledPetitionPayload>;
  /** Deletes a single `CustomerCommsLog` using its globally unique id. */
  deleteCustomerCommsLog: Maybe<DeleteCustomerCommsLogPayload>;
  /** Deletes a single `CustomerCommsLog` using a unique key. */
  deleteCustomerCommsLogByLogId: Maybe<DeleteCustomerCommsLogPayload>;
  /** Deletes a single `CustomerCommsLog` using a unique key. */
  deleteCustomerCommsLogByRecordIdAndCommSource: Maybe<DeleteCustomerCommsLogPayload>;
  /** Deletes a single `EntityRelation` using a unique key. */
  deleteEntityRelationByParentAndParentTypeAndEntityId: Maybe<DeleteEntityRelationPayload>;
  /** Deletes a single `EntitySchema` using its globally unique id. */
  deleteEntitySchema: Maybe<DeleteEntitySchemaPayload>;
  /** Deletes a single `EntitySchema` using a unique key. */
  deleteEntitySchemaByEntityType: Maybe<DeleteEntitySchemaPayload>;
  /** Deletes a single `Exhibit` using its globally unique id. */
  deleteExhibit: Maybe<DeleteExhibitPayload>;
  /** Deletes a single `Exhibit` using a unique key. */
  deleteExhibitById: Maybe<DeleteExhibitPayload>;
  /** Deletes a single `ExhibitFile` using its globally unique id. */
  deleteExhibitFile: Maybe<DeleteExhibitFilePayload>;
  /** Deletes a single `ExhibitFile` using a unique key. */
  deleteExhibitFileByExhibitIdAndFileId: Maybe<DeleteExhibitFilePayload>;
  /** Deletes a single `ExhibitType` using its globally unique id. */
  deleteExhibitType: Maybe<DeleteExhibitTypePayload>;
  /** Deletes a single `ExhibitType` using a unique key. */
  deleteExhibitTypeById: Maybe<DeleteExhibitTypePayload>;
  /** Deletes a single `File` using its globally unique id. */
  deleteFile: Maybe<DeleteFilePayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFileById: Maybe<DeleteFilePayload>;
  /** Deletes a single `FlywaySchemaHistory` using its globally unique id. */
  deleteFlywaySchemaHistory: Maybe<DeleteFlywaySchemaHistoryPayload>;
  /** Deletes a single `FlywaySchemaHistory` using a unique key. */
  deleteFlywaySchemaHistoryByInstalledRank: Maybe<DeleteFlywaySchemaHistoryPayload>;
  /** Deletes a single `I94Entry` using its globally unique id. */
  deleteI94Entry: Maybe<DeleteI94EntryPayload>;
  /** Deletes a single `I94Entry` using a unique key. */
  deleteI94EntryByI94Number: Maybe<DeleteI94EntryPayload>;
  /** Deletes a single `ImmigrationDocument` using its globally unique id. */
  deleteImmigrationDocument: Maybe<DeleteImmigrationDocumentPayload>;
  /** Deletes a single `ImmigrationDocument` using a unique key. */
  deleteImmigrationDocumentByOwnerIdAndFileId: Maybe<DeleteImmigrationDocumentPayload>;
  /** Deletes a single `IndividualVisaHistory` using its globally unique id. */
  deleteIndividualVisaHistory: Maybe<DeleteIndividualVisaHistoryPayload>;
  /** Deletes a single `IndividualVisaHistory` using a unique key. */
  deleteIndividualVisaHistoryById: Maybe<DeleteIndividualVisaHistoryPayload>;
  /** Deletes a single `IndividualVisaHistory` using a unique key. */
  deleteIndividualVisaHistoryByUserIdAndVisaNumber: Maybe<DeleteIndividualVisaHistoryPayload>;
  /** Deletes a single `Module` using its globally unique id. */
  deleteModule: Maybe<DeleteModulePayload>;
  /** Deletes a single `Module` using a unique key. */
  deleteModuleById: Maybe<DeleteModulePayload>;
  /** Deletes a single `ModuleType` using its globally unique id. */
  deleteModuleType: Maybe<DeleteModuleTypePayload>;
  /** Deletes a single `ModuleType` using a unique key. */
  deleteModuleTypeById: Maybe<DeleteModuleTypePayload>;
  /** Deletes a single `Note` using its globally unique id. */
  deleteNote: Maybe<DeleteNotePayload>;
  /** Deletes a single `Note` using a unique key. */
  deleteNoteById: Maybe<DeleteNotePayload>;
  /** Deletes a single `Onboarding` using its globally unique id. */
  deleteOnboarding: Maybe<DeleteOnboardingPayload>;
  /** Deletes a single `Onboarding` using a unique key. */
  deleteOnboardingById: Maybe<DeleteOnboardingPayload>;
  /** Deletes a single `OnboardingExhibitType` using its globally unique id. */
  deleteOnboardingExhibitType: Maybe<DeleteOnboardingExhibitTypePayload>;
  /** Deletes a single `OnboardingExhibitType` using a unique key. */
  deleteOnboardingExhibitTypeByExhibitType: Maybe<DeleteOnboardingExhibitTypePayload>;
  /** Deletes a single `OnboardingModuleType` using its globally unique id. */
  deleteOnboardingModuleType: Maybe<DeleteOnboardingModuleTypePayload>;
  /** Deletes a single `OnboardingModuleType` using a unique key. */
  deleteOnboardingModuleTypeByModuleType: Maybe<DeleteOnboardingModuleTypePayload>;
  /** Deletes a single `Petition` using its globally unique id. */
  deletePetition: Maybe<DeletePetitionPayload>;
  /** Deletes a single `Petition` using a unique key. */
  deletePetitionById: Maybe<DeletePetitionPayload>;
  /** Deletes a single `PetitionForm` using its globally unique id. */
  deletePetitionForm: Maybe<DeletePetitionFormPayload>;
  /** Deletes a single `PetitionForm` using a unique key. */
  deletePetitionFormById: Maybe<DeletePetitionFormPayload>;
  /** Deletes a single `PetitionLetter` using its globally unique id. */
  deletePetitionLetter: Maybe<DeletePetitionLetterPayload>;
  /** Deletes a single `PetitionLetter` using a unique key. */
  deletePetitionLetterById: Maybe<DeletePetitionLetterPayload>;
  /** Deletes a single `PetitionLetterSignatory` using its globally unique id. */
  deletePetitionLetterSignatory: Maybe<DeletePetitionLetterSignatoryPayload>;
  /** Deletes a single `PetitionLetterSignatory` using a unique key. */
  deletePetitionLetterSignatoryByLetterId: Maybe<DeletePetitionLetterSignatoryPayload>;
  /** Deletes a single `ReusableExhibit` using its globally unique id. */
  deleteReusableExhibit: Maybe<DeleteReusableExhibitPayload>;
  /** Deletes a single `ReusableExhibit` using a unique key. */
  deleteReusableExhibitById: Maybe<DeleteReusableExhibitPayload>;
  /** Deletes a single `ReusableExhibitEntity` using its globally unique id. */
  deleteReusableExhibitEntity: Maybe<DeleteReusableExhibitEntityPayload>;
  /** Deletes a single `ReusableExhibitEntity` using a unique key. */
  deleteReusableExhibitEntityByReusableExhibitIdAndEntityId: Maybe<DeleteReusableExhibitEntityPayload>;
  /** Deletes a single `ReusableExhibitFile` using its globally unique id. */
  deleteReusableExhibitFile: Maybe<DeleteReusableExhibitFilePayload>;
  /** Deletes a single `ReusableExhibitFile` using a unique key. */
  deleteReusableExhibitFileByReusableExhibitIdAndFileId: Maybe<DeleteReusableExhibitFilePayload>;
  /** Deletes a single `TaskQueue` using its globally unique id. */
  deleteTaskQueue: Maybe<DeleteTaskQueuePayload>;
  /** Deletes a single `TaskQueue` using a unique key. */
  deleteTaskQueueById: Maybe<DeleteTaskQueuePayload>;
  /** Deletes a single `TaskQueue` using a unique key. */
  deleteTaskQueueByIdempotencyKey: Maybe<DeleteTaskQueuePayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUser: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByAtRecordId: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserById: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserDatum` using its globally unique id. */
  deleteUserDatum: Maybe<DeleteUserDatumPayload>;
  /** Deletes a single `UserDatum` using a unique key. */
  deleteUserDatumById: Maybe<DeleteUserDatumPayload>;
  /** Deletes a single `UserDocument` using its globally unique id. */
  deleteUserDocument: Maybe<DeleteUserDocumentPayload>;
  /** Deletes a single `UserDocument` using a unique key. */
  deleteUserDocumentByUserIdAndFileId: Maybe<DeleteUserDocumentPayload>;
  /** Deletes a single `UserEmployment` using its globally unique id. */
  deleteUserEmployment: Maybe<DeleteUserEmploymentPayload>;
  /** Deletes a single `UserEmployment` using a unique key. */
  deleteUserEmploymentById: Maybe<DeleteUserEmploymentPayload>;
  /** Deletes a single `UserEmployment` using a unique key. */
  deleteUserEmploymentByUserIdAndCompanyId: Maybe<DeleteUserEmploymentPayload>;
  /** Deletes a single `VisaType` using its globally unique id. */
  deleteVisaType: Maybe<DeleteVisaTypePayload>;
  /** Deletes a single `VisaType` using a unique key. */
  deleteVisaTypeByVisaTypeCode: Maybe<DeleteVisaTypePayload>;
  hasAccessToDocument: Maybe<HasAccessToDocumentPayload>;
  hasAccessToEmployment: Maybe<HasAccessToEmploymentPayload>;
  hasAccessToManageEmployment: Maybe<HasAccessToManageEmploymentPayload>;
  hasAccessToNote: Maybe<HasAccessToNotePayload>;
  hasAccessToUser: Maybe<HasAccessToUserPayload>;
  isCurrentUserAdminMember: Maybe<IsCurrentUserAdminMemberPayload>;
  populateNotes: Maybe<PopulateNotesPayload>;
  /** Updates a single `AdditionalEntity` using its globally unique id and a patch. */
  updateAdditionalEntity: Maybe<UpdateAdditionalEntityPayload>;
  /** Updates a single `AdditionalEntity` using a unique key and a patch. */
  updateAdditionalEntityById: Maybe<UpdateAdditionalEntityPayload>;
  /** Updates a single `Admin` using its globally unique id and a patch. */
  updateAdmin: Maybe<UpdateAdminPayload>;
  /** Updates a single `Admin` using a unique key and a patch. */
  updateAdminByUserId: Maybe<UpdateAdminPayload>;
  /** Updates a single `AuditLog` using its globally unique id and a patch. */
  updateAuditLog: Maybe<UpdateAuditLogPayload>;
  /** Updates a single `AuditLog` using a unique key and a patch. */
  updateAuditLogById: Maybe<UpdateAuditLogPayload>;
  /** Updates a single `CaseStrategy` using its globally unique id and a patch. */
  updateCaseStrategy: Maybe<UpdateCaseStrategyPayload>;
  /** Updates a single `CaseStrategy` using a unique key and a patch. */
  updateCaseStrategyById: Maybe<UpdateCaseStrategyPayload>;
  /** Updates a single `CaseType` using its globally unique id and a patch. */
  updateCaseType: Maybe<UpdateCaseTypePayload>;
  /** Updates a single `CaseType` using a unique key and a patch. */
  updateCaseTypeById: Maybe<UpdateCaseTypePayload>;
  /** Updates a single `Company` using its globally unique id and a patch. */
  updateCompany: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByAtRecordId: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyById: Maybe<UpdateCompanyPayload>;
  /** Updates a single `CompanyDocument` using its globally unique id and a patch. */
  updateCompanyDocument: Maybe<UpdateCompanyDocumentPayload>;
  /** Updates a single `CompanyDocument` using a unique key and a patch. */
  updateCompanyDocumentByCompanyIdAndFileId: Maybe<UpdateCompanyDocumentPayload>;
  /** Updates a single `CompanyMember` using its globally unique id and a patch. */
  updateCompanyMember: Maybe<UpdateCompanyMemberPayload>;
  /** Updates a single `CompanyMember` using a unique key and a patch. */
  updateCompanyMemberByCompanyIdAndUserId: Maybe<UpdateCompanyMemberPayload>;
  /** Updates a single `CompanyOffice` using its globally unique id and a patch. */
  updateCompanyOffice: Maybe<UpdateCompanyOfficePayload>;
  /** Updates a single `CompanyOffice` using a unique key and a patch. */
  updateCompanyOfficeById: Maybe<UpdateCompanyOfficePayload>;
  /** Updates a single `CompiledPetition` using its globally unique id and a patch. */
  updateCompiledPetition: Maybe<UpdateCompiledPetitionPayload>;
  /** Updates a single `CompiledPetition` using a unique key and a patch. */
  updateCompiledPetitionByJobId: Maybe<UpdateCompiledPetitionPayload>;
  /** Updates a single `CustomerCommsLog` using its globally unique id and a patch. */
  updateCustomerCommsLog: Maybe<UpdateCustomerCommsLogPayload>;
  /** Updates a single `CustomerCommsLog` using a unique key and a patch. */
  updateCustomerCommsLogByLogId: Maybe<UpdateCustomerCommsLogPayload>;
  /** Updates a single `CustomerCommsLog` using a unique key and a patch. */
  updateCustomerCommsLogByRecordIdAndCommSource: Maybe<UpdateCustomerCommsLogPayload>;
  /** Updates a single `EntityRelation` using a unique key and a patch. */
  updateEntityRelationByParentAndParentTypeAndEntityId: Maybe<UpdateEntityRelationPayload>;
  /** Updates a single `EntitySchema` using its globally unique id and a patch. */
  updateEntitySchema: Maybe<UpdateEntitySchemaPayload>;
  /** Updates a single `EntitySchema` using a unique key and a patch. */
  updateEntitySchemaByEntityType: Maybe<UpdateEntitySchemaPayload>;
  /** Updates a single `Exhibit` using its globally unique id and a patch. */
  updateExhibit: Maybe<UpdateExhibitPayload>;
  /** Updates a single `Exhibit` using a unique key and a patch. */
  updateExhibitById: Maybe<UpdateExhibitPayload>;
  /** Updates a single `ExhibitFile` using its globally unique id and a patch. */
  updateExhibitFile: Maybe<UpdateExhibitFilePayload>;
  /** Updates a single `ExhibitFile` using a unique key and a patch. */
  updateExhibitFileByExhibitIdAndFileId: Maybe<UpdateExhibitFilePayload>;
  /** Updates a single `ExhibitType` using its globally unique id and a patch. */
  updateExhibitType: Maybe<UpdateExhibitTypePayload>;
  /** Updates a single `ExhibitType` using a unique key and a patch. */
  updateExhibitTypeById: Maybe<UpdateExhibitTypePayload>;
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFile: Maybe<UpdateFilePayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFileById: Maybe<UpdateFilePayload>;
  /** Updates a single `FlywaySchemaHistory` using its globally unique id and a patch. */
  updateFlywaySchemaHistory: Maybe<UpdateFlywaySchemaHistoryPayload>;
  /** Updates a single `FlywaySchemaHistory` using a unique key and a patch. */
  updateFlywaySchemaHistoryByInstalledRank: Maybe<UpdateFlywaySchemaHistoryPayload>;
  /** Updates a single `I94Entry` using its globally unique id and a patch. */
  updateI94Entry: Maybe<UpdateI94EntryPayload>;
  /** Updates a single `I94Entry` using a unique key and a patch. */
  updateI94EntryByI94Number: Maybe<UpdateI94EntryPayload>;
  /** Updates a single `ImmigrationDocument` using its globally unique id and a patch. */
  updateImmigrationDocument: Maybe<UpdateImmigrationDocumentPayload>;
  /** Updates a single `ImmigrationDocument` using a unique key and a patch. */
  updateImmigrationDocumentByOwnerIdAndFileId: Maybe<UpdateImmigrationDocumentPayload>;
  /** Updates a single `IndividualVisaHistory` using its globally unique id and a patch. */
  updateIndividualVisaHistory: Maybe<UpdateIndividualVisaHistoryPayload>;
  /** Updates a single `IndividualVisaHistory` using a unique key and a patch. */
  updateIndividualVisaHistoryById: Maybe<UpdateIndividualVisaHistoryPayload>;
  /** Updates a single `IndividualVisaHistory` using a unique key and a patch. */
  updateIndividualVisaHistoryByUserIdAndVisaNumber: Maybe<UpdateIndividualVisaHistoryPayload>;
  /** Updates a single `Module` using its globally unique id and a patch. */
  updateModule: Maybe<UpdateModulePayload>;
  /** Updates a single `Module` using a unique key and a patch. */
  updateModuleById: Maybe<UpdateModulePayload>;
  /** Updates a single `ModuleType` using its globally unique id and a patch. */
  updateModuleType: Maybe<UpdateModuleTypePayload>;
  /** Updates a single `ModuleType` using a unique key and a patch. */
  updateModuleTypeById: Maybe<UpdateModuleTypePayload>;
  updateMultipleExhibitFiles: Maybe<UpdateMultipleExhibitFilesPayload>;
  updateMultipleExhibitsOrder: Maybe<UpdateMultipleExhibitsOrderPayload>;
  updateMultipleImmigrationDocs: Maybe<UpdateMultipleImmigrationDocsPayload>;
  updateMultipleModuleOrder: Maybe<UpdateMultipleModuleOrderPayload>;
  updateMultipleReusableExhibitFiles: Maybe<UpdateMultipleReusableExhibitFilesPayload>;
  updateMultipleReusableExhibitsOrder: Maybe<UpdateMultipleReusableExhibitsOrderPayload>;
  /** Updates a single `Note` using its globally unique id and a patch. */
  updateNote: Maybe<UpdateNotePayload>;
  /** Updates a single `Note` using a unique key and a patch. */
  updateNoteById: Maybe<UpdateNotePayload>;
  /** Updates a single `Onboarding` using its globally unique id and a patch. */
  updateOnboarding: Maybe<UpdateOnboardingPayload>;
  /** Updates a single `Onboarding` using a unique key and a patch. */
  updateOnboardingById: Maybe<UpdateOnboardingPayload>;
  /** Updates a single `OnboardingExhibitType` using its globally unique id and a patch. */
  updateOnboardingExhibitType: Maybe<UpdateOnboardingExhibitTypePayload>;
  /** Updates a single `OnboardingExhibitType` using a unique key and a patch. */
  updateOnboardingExhibitTypeByExhibitType: Maybe<UpdateOnboardingExhibitTypePayload>;
  /** Updates a single `OnboardingModuleType` using its globally unique id and a patch. */
  updateOnboardingModuleType: Maybe<UpdateOnboardingModuleTypePayload>;
  /** Updates a single `OnboardingModuleType` using a unique key and a patch. */
  updateOnboardingModuleTypeByModuleType: Maybe<UpdateOnboardingModuleTypePayload>;
  /** Updates a single `Petition` using its globally unique id and a patch. */
  updatePetition: Maybe<UpdatePetitionPayload>;
  /** Updates a single `Petition` using a unique key and a patch. */
  updatePetitionById: Maybe<UpdatePetitionPayload>;
  /** Updates a single `PetitionForm` using its globally unique id and a patch. */
  updatePetitionForm: Maybe<UpdatePetitionFormPayload>;
  /** Updates a single `PetitionForm` using a unique key and a patch. */
  updatePetitionFormById: Maybe<UpdatePetitionFormPayload>;
  /** Updates a single `PetitionLetter` using its globally unique id and a patch. */
  updatePetitionLetter: Maybe<UpdatePetitionLetterPayload>;
  /** Updates a single `PetitionLetter` using a unique key and a patch. */
  updatePetitionLetterById: Maybe<UpdatePetitionLetterPayload>;
  /** Updates a single `PetitionLetterSignatory` using its globally unique id and a patch. */
  updatePetitionLetterSignatory: Maybe<UpdatePetitionLetterSignatoryPayload>;
  /** Updates a single `PetitionLetterSignatory` using a unique key and a patch. */
  updatePetitionLetterSignatoryByLetterId: Maybe<UpdatePetitionLetterSignatoryPayload>;
  /** Updates a single `ReusableExhibit` using its globally unique id and a patch. */
  updateReusableExhibit: Maybe<UpdateReusableExhibitPayload>;
  /** Updates a single `ReusableExhibit` using a unique key and a patch. */
  updateReusableExhibitById: Maybe<UpdateReusableExhibitPayload>;
  /** Updates a single `ReusableExhibitEntity` using its globally unique id and a patch. */
  updateReusableExhibitEntity: Maybe<UpdateReusableExhibitEntityPayload>;
  /** Updates a single `ReusableExhibitEntity` using a unique key and a patch. */
  updateReusableExhibitEntityByReusableExhibitIdAndEntityId: Maybe<UpdateReusableExhibitEntityPayload>;
  /** Updates a single `ReusableExhibitFile` using its globally unique id and a patch. */
  updateReusableExhibitFile: Maybe<UpdateReusableExhibitFilePayload>;
  /** Updates a single `ReusableExhibitFile` using a unique key and a patch. */
  updateReusableExhibitFileByReusableExhibitIdAndFileId: Maybe<UpdateReusableExhibitFilePayload>;
  /** Updates a single `TaskQueue` using its globally unique id and a patch. */
  updateTaskQueue: Maybe<UpdateTaskQueuePayload>;
  /** Updates a single `TaskQueue` using a unique key and a patch. */
  updateTaskQueueById: Maybe<UpdateTaskQueuePayload>;
  /** Updates a single `TaskQueue` using a unique key and a patch. */
  updateTaskQueueByIdempotencyKey: Maybe<UpdateTaskQueuePayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUser: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByAtRecordId: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserById: Maybe<UpdateUserPayload>;
  /** Updates a single `UserDatum` using its globally unique id and a patch. */
  updateUserDatum: Maybe<UpdateUserDatumPayload>;
  /** Updates a single `UserDatum` using a unique key and a patch. */
  updateUserDatumById: Maybe<UpdateUserDatumPayload>;
  /** Updates a single `UserDocument` using its globally unique id and a patch. */
  updateUserDocument: Maybe<UpdateUserDocumentPayload>;
  /** Updates a single `UserDocument` using a unique key and a patch. */
  updateUserDocumentByUserIdAndFileId: Maybe<UpdateUserDocumentPayload>;
  /** Updates a single `UserEmployment` using its globally unique id and a patch. */
  updateUserEmployment: Maybe<UpdateUserEmploymentPayload>;
  /** Updates a single `UserEmployment` using a unique key and a patch. */
  updateUserEmploymentById: Maybe<UpdateUserEmploymentPayload>;
  /** Updates a single `UserEmployment` using a unique key and a patch. */
  updateUserEmploymentByUserIdAndCompanyId: Maybe<UpdateUserEmploymentPayload>;
  /** Updates a single `VisaType` using its globally unique id and a patch. */
  updateVisaType: Maybe<UpdateVisaTypePayload>;
  /** Updates a single `VisaType` using a unique key and a patch. */
  updateVisaTypeByVisaTypeCode: Maybe<UpdateVisaTypePayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminInsertCompanyCheckArgs = {
  input: AdminInsertCompanyCheckInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdditionalEntityArgs = {
  input: CreateAdditionalEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuditLogArgs = {
  input: CreateAuditLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCaseStrategyArgs = {
  input: CreateCaseStrategyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCaseTypeArgs = {
  input: CreateCaseTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDocumentArgs = {
  input: CreateCompanyDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyMemberArgs = {
  input: CreateCompanyMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyOfficeArgs = {
  input: CreateCompanyOfficeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompiledPetitionArgs = {
  input: CreateCompiledPetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCustomerCommsLogArgs = {
  input: CreateCustomerCommsLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntityFileArgs = {
  input: CreateEntityFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntityRelationArgs = {
  input: CreateEntityRelationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntitySchemaArgs = {
  input: CreateEntitySchemaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExhibitArgs = {
  input: CreateExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExhibitFileArgs = {
  input: CreateExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExhibitTypeArgs = {
  input: CreateExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFlywaySchemaHistoryArgs = {
  input: CreateFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateI94EntryArgs = {
  input: CreateI94EntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateImmigrationDocumentArgs = {
  input: CreateImmigrationDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIndividualVisaHistoryArgs = {
  input: CreateIndividualVisaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModuleArgs = {
  input: CreateModuleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModuleTypeArgs = {
  input: CreateModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOnboardingArgs = {
  input: CreateOnboardingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOnboardingExhibitTypeArgs = {
  input: CreateOnboardingExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOnboardingModuleTypeArgs = {
  input: CreateOnboardingModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePetitionArgs = {
  input: CreatePetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePetitionFormArgs = {
  input: CreatePetitionFormInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePetitionLetterArgs = {
  input: CreatePetitionLetterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePetitionLetterSignatoryArgs = {
  input: CreatePetitionLetterSignatoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReusableExhibitArgs = {
  input: CreateReusableExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReusableExhibitEntityArgs = {
  input: CreateReusableExhibitEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReusableExhibitFileArgs = {
  input: CreateReusableExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskQueueArgs = {
  input: CreateTaskQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserDatumArgs = {
  input: CreateUserDatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserDocumentArgs = {
  input: CreateUserDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserEmploymentArgs = {
  input: CreateUserEmploymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVisaTypeArgs = {
  input: CreateVisaTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdditionalEntityArgs = {
  input: DeleteAdditionalEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdditionalEntityByIdArgs = {
  input: DeleteAdditionalEntityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdminArgs = {
  input: DeleteAdminInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdminByUserIdArgs = {
  input: DeleteAdminByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuditLogArgs = {
  input: DeleteAuditLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuditLogByIdArgs = {
  input: DeleteAuditLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCaseStrategyArgs = {
  input: DeleteCaseStrategyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCaseStrategyByIdArgs = {
  input: DeleteCaseStrategyByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCaseTypeArgs = {
  input: DeleteCaseTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCaseTypeByIdArgs = {
  input: DeleteCaseTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyByAtRecordIdArgs = {
  input: DeleteCompanyByAtRecordIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyByIdArgs = {
  input: DeleteCompanyByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDocumentArgs = {
  input: DeleteCompanyDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDocumentByCompanyIdAndFileIdArgs = {
  input: DeleteCompanyDocumentByCompanyIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyMemberArgs = {
  input: DeleteCompanyMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyMemberByCompanyIdAndUserIdArgs = {
  input: DeleteCompanyMemberByCompanyIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyOfficeArgs = {
  input: DeleteCompanyOfficeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyOfficeByIdArgs = {
  input: DeleteCompanyOfficeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompiledPetitionArgs = {
  input: DeleteCompiledPetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompiledPetitionByJobIdArgs = {
  input: DeleteCompiledPetitionByJobIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerCommsLogArgs = {
  input: DeleteCustomerCommsLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerCommsLogByLogIdArgs = {
  input: DeleteCustomerCommsLogByLogIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerCommsLogByRecordIdAndCommSourceArgs = {
  input: DeleteCustomerCommsLogByRecordIdAndCommSourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntityRelationByParentAndParentTypeAndEntityIdArgs = {
  input: DeleteEntityRelationByParentAndParentTypeAndEntityIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntitySchemaArgs = {
  input: DeleteEntitySchemaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntitySchemaByEntityTypeArgs = {
  input: DeleteEntitySchemaByEntityTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitArgs = {
  input: DeleteExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitByIdArgs = {
  input: DeleteExhibitByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitFileArgs = {
  input: DeleteExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitFileByExhibitIdAndFileIdArgs = {
  input: DeleteExhibitFileByExhibitIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitTypeArgs = {
  input: DeleteExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExhibitTypeByIdArgs = {
  input: DeleteExhibitTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByIdArgs = {
  input: DeleteFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFlywaySchemaHistoryArgs = {
  input: DeleteFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFlywaySchemaHistoryByInstalledRankArgs = {
  input: DeleteFlywaySchemaHistoryByInstalledRankInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteI94EntryArgs = {
  input: DeleteI94EntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteI94EntryByI94NumberArgs = {
  input: DeleteI94EntryByI94NumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteImmigrationDocumentArgs = {
  input: DeleteImmigrationDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteImmigrationDocumentByOwnerIdAndFileIdArgs = {
  input: DeleteImmigrationDocumentByOwnerIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndividualVisaHistoryArgs = {
  input: DeleteIndividualVisaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndividualVisaHistoryByIdArgs = {
  input: DeleteIndividualVisaHistoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndividualVisaHistoryByUserIdAndVisaNumberArgs = {
  input: DeleteIndividualVisaHistoryByUserIdAndVisaNumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModuleArgs = {
  input: DeleteModuleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModuleByIdArgs = {
  input: DeleteModuleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModuleTypeArgs = {
  input: DeleteModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModuleTypeByIdArgs = {
  input: DeleteModuleTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteByIdArgs = {
  input: DeleteNoteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingArgs = {
  input: DeleteOnboardingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingByIdArgs = {
  input: DeleteOnboardingByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingExhibitTypeArgs = {
  input: DeleteOnboardingExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingExhibitTypeByExhibitTypeArgs = {
  input: DeleteOnboardingExhibitTypeByExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingModuleTypeArgs = {
  input: DeleteOnboardingModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOnboardingModuleTypeByModuleTypeArgs = {
  input: DeleteOnboardingModuleTypeByModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionArgs = {
  input: DeletePetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionByIdArgs = {
  input: DeletePetitionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionFormArgs = {
  input: DeletePetitionFormInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionFormByIdArgs = {
  input: DeletePetitionFormByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionLetterArgs = {
  input: DeletePetitionLetterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionLetterByIdArgs = {
  input: DeletePetitionLetterByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionLetterSignatoryArgs = {
  input: DeletePetitionLetterSignatoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePetitionLetterSignatoryByLetterIdArgs = {
  input: DeletePetitionLetterSignatoryByLetterIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitArgs = {
  input: DeleteReusableExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitByIdArgs = {
  input: DeleteReusableExhibitByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitEntityArgs = {
  input: DeleteReusableExhibitEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdArgs =
  {
    input: DeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitFileArgs = {
  input: DeleteReusableExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReusableExhibitFileByReusableExhibitIdAndFileIdArgs =
  {
    input: DeleteReusableExhibitFileByReusableExhibitIdAndFileIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskQueueArgs = {
  input: DeleteTaskQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskQueueByIdArgs = {
  input: DeleteTaskQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskQueueByIdempotencyKeyArgs = {
  input: DeleteTaskQueueByIdempotencyKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByAtRecordIdArgs = {
  input: DeleteUserByAtRecordIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByIdArgs = {
  input: DeleteUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserDatumArgs = {
  input: DeleteUserDatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserDatumByIdArgs = {
  input: DeleteUserDatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserDocumentArgs = {
  input: DeleteUserDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserDocumentByUserIdAndFileIdArgs = {
  input: DeleteUserDocumentByUserIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmploymentArgs = {
  input: DeleteUserEmploymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmploymentByIdArgs = {
  input: DeleteUserEmploymentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmploymentByUserIdAndCompanyIdArgs = {
  input: DeleteUserEmploymentByUserIdAndCompanyIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVisaTypeArgs = {
  input: DeleteVisaTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVisaTypeByVisaTypeCodeArgs = {
  input: DeleteVisaTypeByVisaTypeCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasAccessToDocumentArgs = {
  input: HasAccessToDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasAccessToEmploymentArgs = {
  input: HasAccessToEmploymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasAccessToManageEmploymentArgs = {
  input: HasAccessToManageEmploymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasAccessToNoteArgs = {
  input: HasAccessToNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationHasAccessToUserArgs = {
  input: HasAccessToUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsCurrentUserAdminMemberArgs = {
  input: IsCurrentUserAdminMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPopulateNotesArgs = {
  input: PopulateNotesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdditionalEntityArgs = {
  input: UpdateAdditionalEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdditionalEntityByIdArgs = {
  input: UpdateAdditionalEntityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdminByUserIdArgs = {
  input: UpdateAdminByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuditLogArgs = {
  input: UpdateAuditLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuditLogByIdArgs = {
  input: UpdateAuditLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCaseStrategyArgs = {
  input: UpdateCaseStrategyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCaseStrategyByIdArgs = {
  input: UpdateCaseStrategyByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCaseTypeArgs = {
  input: UpdateCaseTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCaseTypeByIdArgs = {
  input: UpdateCaseTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByAtRecordIdArgs = {
  input: UpdateCompanyByAtRecordIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByIdArgs = {
  input: UpdateCompanyByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDocumentArgs = {
  input: UpdateCompanyDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDocumentByCompanyIdAndFileIdArgs = {
  input: UpdateCompanyDocumentByCompanyIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyMemberArgs = {
  input: UpdateCompanyMemberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyMemberByCompanyIdAndUserIdArgs = {
  input: UpdateCompanyMemberByCompanyIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyOfficeArgs = {
  input: UpdateCompanyOfficeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyOfficeByIdArgs = {
  input: UpdateCompanyOfficeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompiledPetitionArgs = {
  input: UpdateCompiledPetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompiledPetitionByJobIdArgs = {
  input: UpdateCompiledPetitionByJobIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerCommsLogArgs = {
  input: UpdateCustomerCommsLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerCommsLogByLogIdArgs = {
  input: UpdateCustomerCommsLogByLogIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerCommsLogByRecordIdAndCommSourceArgs = {
  input: UpdateCustomerCommsLogByRecordIdAndCommSourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntityRelationByParentAndParentTypeAndEntityIdArgs = {
  input: UpdateEntityRelationByParentAndParentTypeAndEntityIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntitySchemaArgs = {
  input: UpdateEntitySchemaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntitySchemaByEntityTypeArgs = {
  input: UpdateEntitySchemaByEntityTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitArgs = {
  input: UpdateExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitByIdArgs = {
  input: UpdateExhibitByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitFileArgs = {
  input: UpdateExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitFileByExhibitIdAndFileIdArgs = {
  input: UpdateExhibitFileByExhibitIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitTypeArgs = {
  input: UpdateExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExhibitTypeByIdArgs = {
  input: UpdateExhibitTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByIdArgs = {
  input: UpdateFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFlywaySchemaHistoryArgs = {
  input: UpdateFlywaySchemaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFlywaySchemaHistoryByInstalledRankArgs = {
  input: UpdateFlywaySchemaHistoryByInstalledRankInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateI94EntryArgs = {
  input: UpdateI94EntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateI94EntryByI94NumberArgs = {
  input: UpdateI94EntryByI94NumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateImmigrationDocumentArgs = {
  input: UpdateImmigrationDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateImmigrationDocumentByOwnerIdAndFileIdArgs = {
  input: UpdateImmigrationDocumentByOwnerIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndividualVisaHistoryArgs = {
  input: UpdateIndividualVisaHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndividualVisaHistoryByIdArgs = {
  input: UpdateIndividualVisaHistoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndividualVisaHistoryByUserIdAndVisaNumberArgs = {
  input: UpdateIndividualVisaHistoryByUserIdAndVisaNumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModuleArgs = {
  input: UpdateModuleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModuleByIdArgs = {
  input: UpdateModuleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModuleTypeArgs = {
  input: UpdateModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModuleTypeByIdArgs = {
  input: UpdateModuleTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleExhibitFilesArgs = {
  input: UpdateMultipleExhibitFilesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleExhibitsOrderArgs = {
  input: UpdateMultipleExhibitsOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleImmigrationDocsArgs = {
  input: UpdateMultipleImmigrationDocsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleModuleOrderArgs = {
  input: UpdateMultipleModuleOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleReusableExhibitFilesArgs = {
  input: UpdateMultipleReusableExhibitFilesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMultipleReusableExhibitsOrderArgs = {
  input: UpdateMultipleReusableExhibitsOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteByIdArgs = {
  input: UpdateNoteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingArgs = {
  input: UpdateOnboardingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingByIdArgs = {
  input: UpdateOnboardingByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingExhibitTypeArgs = {
  input: UpdateOnboardingExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingExhibitTypeByExhibitTypeArgs = {
  input: UpdateOnboardingExhibitTypeByExhibitTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingModuleTypeArgs = {
  input: UpdateOnboardingModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOnboardingModuleTypeByModuleTypeArgs = {
  input: UpdateOnboardingModuleTypeByModuleTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionArgs = {
  input: UpdatePetitionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionByIdArgs = {
  input: UpdatePetitionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionFormArgs = {
  input: UpdatePetitionFormInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionFormByIdArgs = {
  input: UpdatePetitionFormByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionLetterArgs = {
  input: UpdatePetitionLetterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionLetterByIdArgs = {
  input: UpdatePetitionLetterByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionLetterSignatoryArgs = {
  input: UpdatePetitionLetterSignatoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePetitionLetterSignatoryByLetterIdArgs = {
  input: UpdatePetitionLetterSignatoryByLetterIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitArgs = {
  input: UpdateReusableExhibitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitByIdArgs = {
  input: UpdateReusableExhibitByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitEntityArgs = {
  input: UpdateReusableExhibitEntityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitEntityByReusableExhibitIdAndEntityIdArgs =
  {
    input: UpdateReusableExhibitEntityByReusableExhibitIdAndEntityIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitFileArgs = {
  input: UpdateReusableExhibitFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReusableExhibitFileByReusableExhibitIdAndFileIdArgs =
  {
    input: UpdateReusableExhibitFileByReusableExhibitIdAndFileIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskQueueArgs = {
  input: UpdateTaskQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskQueueByIdArgs = {
  input: UpdateTaskQueueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskQueueByIdempotencyKeyArgs = {
  input: UpdateTaskQueueByIdempotencyKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByAtRecordIdArgs = {
  input: UpdateUserByAtRecordIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdArgs = {
  input: UpdateUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserDatumArgs = {
  input: UpdateUserDatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserDatumByIdArgs = {
  input: UpdateUserDatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserDocumentArgs = {
  input: UpdateUserDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserDocumentByUserIdAndFileIdArgs = {
  input: UpdateUserDocumentByUserIdAndFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmploymentArgs = {
  input: UpdateUserEmploymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmploymentByIdArgs = {
  input: UpdateUserEmploymentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmploymentByUserIdAndCompanyIdArgs = {
  input: UpdateUserEmploymentByUserIdAndCompanyIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVisaTypeArgs = {
  input: UpdateVisaTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVisaTypeByVisaTypeCodeArgs = {
  input: UpdateVisaTypeByVisaTypeCodeInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type Note = Node & {
  content: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `Note`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Maybe<Scalars["BigInt"]["output"]>;
  senderId: Maybe<Scalars["BigInt"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
  /** Reads a single `User` that is related to this `Note`. */
  userBySenderId: Maybe<User>;
  /** Reads a single `User` that is related to this `Note`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
};

/** A condition to be used against `Note` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NoteCondition = {
  /** Checks for equality with the object’s `content` field. */
  content: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `senderId` field. */
  senderId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `Note` */
export type NoteInput = {
  content: Scalars["String"]["input"];
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  senderId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: Scalars["BigInt"]["input"];
};

/** Represents an update to a `Note`. Fields that are set will be updated. */
export type NotePatch = {
  content: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  senderId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `Note` values. */
export type NotesConnection = {
  /** A list of edges which contains the `Note` and cursor to aid in pagination. */
  edges: Array<NotesEdge>;
  /** A list of `Note` objects. */
  nodes: Array<Maybe<Note>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Note` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Note` edge in the connection. */
export type NotesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Note` at the end of the edge. */
  node: Maybe<Note>;
};

/** Methods to use when ordering `Note`. */
export enum NotesOrderBy {
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SenderIdAsc = "SENDER_ID_ASC",
  SenderIdDesc = "SENDER_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Onboarding = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["BigInt"]["output"];
  modules: Scalars["JSON"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `Onboarding`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Scalars["BigInt"]["output"];
  status: Maybe<OnboardingStatusType>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  version: Scalars["BigFloat"]["output"];
};

/**
 * A condition to be used against `Onboarding` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OnboardingCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `modules` field. */
  modules: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<OnboardingStatusType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version: InputMaybe<Scalars["BigFloat"]["input"]>;
};

export type OnboardingExhibitType = Node & {
  description: Maybe<Scalars["String"]["output"]>;
  exhibitType: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

/**
 * A condition to be used against `OnboardingExhibitType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OnboardingExhibitTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `exhibitType` field. */
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `OnboardingExhibitType` */
export type OnboardingExhibitTypeInput = {
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: Scalars["String"]["input"];
};

/** Represents an update to a `OnboardingExhibitType`. Fields that are set will be updated. */
export type OnboardingExhibitTypePatch = {
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `OnboardingExhibitType` values. */
export type OnboardingExhibitTypesConnection = {
  /** A list of edges which contains the `OnboardingExhibitType` and cursor to aid in pagination. */
  edges: Array<OnboardingExhibitTypesEdge>;
  /** A list of `OnboardingExhibitType` objects. */
  nodes: Array<Maybe<OnboardingExhibitType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OnboardingExhibitType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `OnboardingExhibitType` edge in the connection. */
export type OnboardingExhibitTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OnboardingExhibitType` at the end of the edge. */
  node: Maybe<OnboardingExhibitType>;
};

/** Methods to use when ordering `OnboardingExhibitType`. */
export enum OnboardingExhibitTypesOrderBy {
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  ExhibitTypeAsc = "EXHIBIT_TYPE_ASC",
  ExhibitTypeDesc = "EXHIBIT_TYPE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** An input for mutations affecting `Onboarding` */
export type OnboardingInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  modules: Scalars["JSON"]["input"];
  petitionId: Scalars["BigInt"]["input"];
  status: InputMaybe<OnboardingStatusType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  version: InputMaybe<Scalars["BigFloat"]["input"]>;
};

export type OnboardingModuleType = Node & {
  description: Maybe<Scalars["String"]["output"]>;
  moduleType: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

/**
 * A condition to be used against `OnboardingModuleType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OnboardingModuleTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `moduleType` field. */
  moduleType: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `OnboardingModuleType` */
export type OnboardingModuleTypeInput = {
  description: InputMaybe<Scalars["String"]["input"]>;
  moduleType: Scalars["String"]["input"];
};

/** Represents an update to a `OnboardingModuleType`. Fields that are set will be updated. */
export type OnboardingModuleTypePatch = {
  description: InputMaybe<Scalars["String"]["input"]>;
  moduleType: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `OnboardingModuleType` values. */
export type OnboardingModuleTypesConnection = {
  /** A list of edges which contains the `OnboardingModuleType` and cursor to aid in pagination. */
  edges: Array<OnboardingModuleTypesEdge>;
  /** A list of `OnboardingModuleType` objects. */
  nodes: Array<Maybe<OnboardingModuleType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OnboardingModuleType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `OnboardingModuleType` edge in the connection. */
export type OnboardingModuleTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OnboardingModuleType` at the end of the edge. */
  node: Maybe<OnboardingModuleType>;
};

/** Methods to use when ordering `OnboardingModuleType`. */
export enum OnboardingModuleTypesOrderBy {
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  ModuleTypeAsc = "MODULE_TYPE_ASC",
  ModuleTypeDesc = "MODULE_TYPE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** Represents an update to a `Onboarding`. Fields that are set will be updated. */
export type OnboardingPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  modules: InputMaybe<Scalars["JSON"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  status: InputMaybe<OnboardingStatusType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  version: InputMaybe<Scalars["BigFloat"]["input"]>;
};

export enum OnboardingStatusType {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

/** A connection to a list of `Onboarding` values. */
export type OnboardingsConnection = {
  /** A list of edges which contains the `Onboarding` and cursor to aid in pagination. */
  edges: Array<OnboardingsEdge>;
  /** A list of `Onboarding` objects. */
  nodes: Array<Maybe<Onboarding>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Onboarding` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Onboarding` edge in the connection. */
export type OnboardingsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Onboarding` at the end of the edge. */
  node: Maybe<Onboarding>;
};

/** Methods to use when ordering `Onboarding`. */
export enum OnboardingsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ModulesAsc = "MODULES_ASC",
  ModulesDesc = "MODULES_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["Cursor"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["Cursor"]["output"]>;
};

export type Petition = Node & {
  atRecordId: Maybe<Scalars["String"]["output"]>;
  attorneyUserId: Maybe<Scalars["BigInt"]["output"]>;
  beneficiaryId: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `CaseStrategy`. */
  caseStrategiesByPetitionId: CaseStrategiesConnection;
  caseType: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `CaseType` that is related to this `Petition`. */
  caseTypeByCaseType: Maybe<CaseType>;
  /** Reads a single `Company` that is related to this `Petition`. */
  companyByPetitionerId: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompiledPetition`. */
  compiledPetitionsByPetitionId: CompiledPetitionsConnection;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  csmUserId: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `CustomerCommsLog`. */
  customerCommsLogsByPetitionId: CustomerCommsLogsConnection;
  filingType: Maybe<FilingType>;
  id: Scalars["BigInt"]["output"];
  latestPetition: Scalars["Boolean"]["output"];
  metadata: Scalars["JSON"]["output"];
  /** Reads and enables pagination through a set of `Module`. */
  modulesByPetitionId: ModulesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  noteLog: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `Note`. */
  notesByPetitionId: NotesConnection;
  oaUserId: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `Onboarding`. */
  onboardingsByPetitionId: OnboardingsConnection;
  /** Reads and enables pagination through a set of `PetitionForm`. */
  petitionFormsByPetitionId: PetitionFormsConnection;
  /** Reads and enables pagination through a set of `PetitionLetter`. */
  petitionLettersByPetitionId: PetitionLettersConnection;
  petitionShippedDate: Maybe<Scalars["Date"]["output"]>;
  petitionerId: Scalars["BigInt"]["output"];
  petitionerRepId: Maybe<Scalars["BigInt"]["output"]>;
  priority: Maybe<Scalars["String"]["output"]>;
  projectManagementStatus: Maybe<Scalars["String"]["output"]>;
  status: PetitionStatus;
  targetSubmissionDate: Maybe<Scalars["Date"]["output"]>;
  trackingNumber: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  uscisAddress: Maybe<Scalars["String"]["output"]>;
  uscisReceiptNumber: Maybe<Scalars["String"]["output"]>;
  uscisStatus: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByBeneficiaryId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByCsmUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByOaUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByPetitionerRepId: Maybe<User>;
  visaClass: VisaClassType;
};

export type PetitionCaseStrategiesByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CaseStrategyCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

export type PetitionCompiledPetitionsByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompiledPetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

export type PetitionCustomerCommsLogsByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CustomerCommsLogCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

export type PetitionModulesByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ModuleCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

export type PetitionNotesByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<NoteCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

export type PetitionOnboardingsByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<OnboardingCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnboardingsOrderBy>>;
};

export type PetitionPetitionFormsByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionFormCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

export type PetitionPetitionLettersByPetitionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

/**
 * A condition to be used against `Petition` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PetitionCondition = {
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `attorneyUserId` field. */
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `beneficiaryId` field. */
  beneficiaryId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `caseType` field. */
  caseType: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `csmUserId` field. */
  csmUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `filingType` field. */
  filingType: InputMaybe<FilingType>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `latestPetition` field. */
  latestPetition: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `noteLog` field. */
  noteLog: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `oaUserId` field. */
  oaUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `petitionShippedDate` field. */
  petitionShippedDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `petitionerId` field. */
  petitionerId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `petitionerRepId` field. */
  petitionerRepId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectManagementStatus` field. */
  projectManagementStatus: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<PetitionStatus>;
  /** Checks for equality with the object’s `targetSubmissionDate` field. */
  targetSubmissionDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `trackingNumber` field. */
  trackingNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `uscisAddress` field. */
  uscisAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `uscisReceiptNumber` field. */
  uscisReceiptNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `uscisStatus` field. */
  uscisStatus: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visaClass` field. */
  visaClass: InputMaybe<VisaClassType>;
};

export type PetitionForm = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionForm`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  generatedAt: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `PetitionForm`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Scalars["BigInt"]["output"];
  type: FormType;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  values: Maybe<Scalars["JSON"]["output"]>;
};

/**
 * A condition to be used against `PetitionForm` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PetitionFormCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `generatedAt` field. */
  generatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<FormType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `values` field. */
  values: InputMaybe<Scalars["JSON"]["input"]>;
};

/** An input for mutations affecting `PetitionForm` */
export type PetitionFormInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  generatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  petitionId: Scalars["BigInt"]["input"];
  type: FormType;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  values: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Represents an update to a `PetitionForm`. Fields that are set will be updated. */
export type PetitionFormPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  generatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  type: InputMaybe<FormType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  values: InputMaybe<Scalars["JSON"]["input"]>;
};

/** A connection to a list of `PetitionForm` values. */
export type PetitionFormsConnection = {
  /** A list of edges which contains the `PetitionForm` and cursor to aid in pagination. */
  edges: Array<PetitionFormsEdge>;
  /** A list of `PetitionForm` objects. */
  nodes: Array<Maybe<PetitionForm>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PetitionForm` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `PetitionForm` edge in the connection. */
export type PetitionFormsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `PetitionForm` at the end of the edge. */
  node: Maybe<PetitionForm>;
};

/** Methods to use when ordering `PetitionForm`. */
export enum PetitionFormsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  GeneratedAtAsc = "GENERATED_AT_ASC",
  GeneratedAtDesc = "GENERATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  ValuesAsc = "VALUES_ASC",
  ValuesDesc = "VALUES_DESC",
}

/** An input for mutations affecting `Petition` */
export type PetitionInput = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  beneficiaryId: Scalars["BigInt"]["input"];
  caseType: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  csmUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  filingType: InputMaybe<FilingType>;
  latestPetition: InputMaybe<Scalars["Boolean"]["input"]>;
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  noteLog: InputMaybe<Scalars["JSON"]["input"]>;
  oaUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  petitionShippedDate: InputMaybe<Scalars["Date"]["input"]>;
  petitionerId: Scalars["BigInt"]["input"];
  petitionerRepId: InputMaybe<Scalars["BigInt"]["input"]>;
  priority: InputMaybe<Scalars["String"]["input"]>;
  projectManagementStatus: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<PetitionStatus>;
  targetSubmissionDate: InputMaybe<Scalars["Date"]["input"]>;
  trackingNumber: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  uscisAddress: InputMaybe<Scalars["String"]["input"]>;
  uscisReceiptNumber: InputMaybe<Scalars["String"]["input"]>;
  uscisStatus: InputMaybe<Scalars["String"]["input"]>;
  visaClass: VisaClassType;
};

export type PetitionLetter = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  draftBody: Scalars["String"]["output"];
  /** Reads a single `File` that is related to this `PetitionLetter`. */
  fileByFinalFileId: Maybe<File>;
  finalFileId: Maybe<Scalars["BigInt"]["output"]>;
  id: Scalars["BigInt"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Petition` that is related to this `PetitionLetter`. */
  petitionByPetitionId: Maybe<Petition>;
  petitionId: Scalars["BigInt"]["output"];
  /**
   * Reads and enables pagination through a set of `PetitionLetterSignatory`.
   * @deprecated Please use petitionLetterSignatoryByLetterId instead
   */
  petitionLetterSignatoriesByLetterId: PetitionLetterSignatoriesConnection;
  /** Reads a single `PetitionLetterSignatory` that is related to this `PetitionLetter`. */
  petitionLetterSignatoryByLetterId: Maybe<PetitionLetterSignatory>;
  status: LetterStatus;
  type: LetterType;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

export type PetitionLetterPetitionLetterSignatoriesByLetterIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterSignatoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
};

/**
 * A condition to be used against `PetitionLetter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PetitionLetterCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `draftBody` field. */
  draftBody: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `finalFileId` field. */
  finalFileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `petitionId` field. */
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<LetterStatus>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<LetterType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `PetitionLetter` */
export type PetitionLetterInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  draftBody: InputMaybe<Scalars["String"]["input"]>;
  finalFileId: InputMaybe<Scalars["BigInt"]["input"]>;
  name: Scalars["String"]["input"];
  petitionId: Scalars["BigInt"]["input"];
  status: InputMaybe<LetterStatus>;
  type: LetterType;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `PetitionLetter`. Fields that are set will be updated. */
export type PetitionLetterPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  draftBody: InputMaybe<Scalars["String"]["input"]>;
  finalFileId: InputMaybe<Scalars["BigInt"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  petitionId: InputMaybe<Scalars["BigInt"]["input"]>;
  status: InputMaybe<LetterStatus>;
  type: InputMaybe<LetterType>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `PetitionLetterSignatory` values. */
export type PetitionLetterSignatoriesConnection = {
  /** A list of edges which contains the `PetitionLetterSignatory` and cursor to aid in pagination. */
  edges: Array<PetitionLetterSignatoriesEdge>;
  /** A list of `PetitionLetterSignatory` objects. */
  nodes: Array<Maybe<PetitionLetterSignatory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PetitionLetterSignatory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `PetitionLetterSignatory` edge in the connection. */
export type PetitionLetterSignatoriesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `PetitionLetterSignatory` at the end of the edge. */
  node: Maybe<PetitionLetterSignatory>;
};

/** Methods to use when ordering `PetitionLetterSignatory`. */
export enum PetitionLetterSignatoriesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  LastNotifiedAsc = "LAST_NOTIFIED_ASC",
  LastNotifiedDesc = "LAST_NOTIFIED_DESC",
  LetterIdAsc = "LETTER_ID_ASC",
  LetterIdDesc = "LETTER_ID_DESC",
  Natural = "NATURAL",
  NotifiedAsc = "NOTIFIED_ASC",
  NotifiedDesc = "NOTIFIED_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SignatoryIdAsc = "SIGNATORY_ID_ASC",
  SignatoryIdDesc = "SIGNATORY_ID_DESC",
  SignedAsc = "SIGNED_ASC",
  SignedDesc = "SIGNED_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type PetitionLetterSignatory = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  lastNotified: Maybe<Scalars["Datetime"]["output"]>;
  letterId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  notified: Scalars["Boolean"]["output"];
  /** Reads a single `PetitionLetter` that is related to this `PetitionLetterSignatory`. */
  petitionLetterByLetterId: Maybe<PetitionLetter>;
  signatoryId: Scalars["BigInt"]["output"];
  signed: Scalars["Boolean"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `PetitionLetterSignatory`. */
  userBySignatoryId: Maybe<User>;
};

/**
 * A condition to be used against `PetitionLetterSignatory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type PetitionLetterSignatoryCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastNotified` field. */
  lastNotified: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `letterId` field. */
  letterId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `notified` field. */
  notified: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `signatoryId` field. */
  signatoryId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `signed` field. */
  signed: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `PetitionLetterSignatory` */
export type PetitionLetterSignatoryInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  lastNotified: InputMaybe<Scalars["Datetime"]["input"]>;
  letterId: Scalars["BigInt"]["input"];
  notified: InputMaybe<Scalars["Boolean"]["input"]>;
  signatoryId: Scalars["BigInt"]["input"];
  signed: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `PetitionLetterSignatory`. Fields that are set will be updated. */
export type PetitionLetterSignatoryPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  lastNotified: InputMaybe<Scalars["Datetime"]["input"]>;
  letterId: InputMaybe<Scalars["BigInt"]["input"]>;
  notified: InputMaybe<Scalars["Boolean"]["input"]>;
  signatoryId: InputMaybe<Scalars["BigInt"]["input"]>;
  signed: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `PetitionLetter` values. */
export type PetitionLettersConnection = {
  /** A list of edges which contains the `PetitionLetter` and cursor to aid in pagination. */
  edges: Array<PetitionLettersEdge>;
  /** A list of `PetitionLetter` objects. */
  nodes: Array<Maybe<PetitionLetter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PetitionLetter` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `PetitionLetter` edge in the connection. */
export type PetitionLettersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `PetitionLetter` at the end of the edge. */
  node: Maybe<PetitionLetter>;
};

/** Methods to use when ordering `PetitionLetter`. */
export enum PetitionLettersOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DraftBodyAsc = "DRAFT_BODY_ASC",
  DraftBodyDesc = "DRAFT_BODY_DESC",
  FinalFileIdAsc = "FINAL_FILE_ID_ASC",
  FinalFileIdDesc = "FINAL_FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PetitionIdAsc = "PETITION_ID_ASC",
  PetitionIdDesc = "PETITION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** Represents an update to a `Petition`. Fields that are set will be updated. */
export type PetitionPatch = {
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  attorneyUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  beneficiaryId: InputMaybe<Scalars["BigInt"]["input"]>;
  caseType: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  csmUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  filingType: InputMaybe<FilingType>;
  latestPetition: InputMaybe<Scalars["Boolean"]["input"]>;
  metadata: InputMaybe<Scalars["JSON"]["input"]>;
  noteLog: InputMaybe<Scalars["JSON"]["input"]>;
  oaUserId: InputMaybe<Scalars["BigInt"]["input"]>;
  petitionShippedDate: InputMaybe<Scalars["Date"]["input"]>;
  petitionerId: InputMaybe<Scalars["BigInt"]["input"]>;
  petitionerRepId: InputMaybe<Scalars["BigInt"]["input"]>;
  priority: InputMaybe<Scalars["String"]["input"]>;
  projectManagementStatus: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<PetitionStatus>;
  targetSubmissionDate: InputMaybe<Scalars["Date"]["input"]>;
  trackingNumber: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  uscisAddress: InputMaybe<Scalars["String"]["input"]>;
  uscisReceiptNumber: InputMaybe<Scalars["String"]["input"]>;
  uscisStatus: InputMaybe<Scalars["String"]["input"]>;
  visaClass: InputMaybe<VisaClassType>;
};

export enum PetitionStatus {
  Approved = "APPROVED",
  Archived = "ARCHIVED",
  Denied = "DENIED",
  InAttorneyReview = "IN_ATTORNEY_REVIEW",
  PendingDocs = "PENDING_DOCS",
  PrintShipRequested = "PRINT_SHIP_REQUESTED",
  Rfeed = "RFEED",
  Shipped = "SHIPPED",
  Submitted = "SUBMITTED",
}

/** A connection to a list of `Petition` values. */
export type PetitionsConnection = {
  /** A list of edges which contains the `Petition` and cursor to aid in pagination. */
  edges: Array<PetitionsEdge>;
  /** A list of `Petition` objects. */
  nodes: Array<Maybe<Petition>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Petition` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Petition` edge in the connection. */
export type PetitionsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Petition` at the end of the edge. */
  node: Maybe<Petition>;
};

/** Methods to use when ordering `Petition`. */
export enum PetitionsOrderBy {
  AttorneyUserIdAsc = "ATTORNEY_USER_ID_ASC",
  AttorneyUserIdDesc = "ATTORNEY_USER_ID_DESC",
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  BeneficiaryIdAsc = "BENEFICIARY_ID_ASC",
  BeneficiaryIdDesc = "BENEFICIARY_ID_DESC",
  CaseTypeAsc = "CASE_TYPE_ASC",
  CaseTypeDesc = "CASE_TYPE_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CsmUserIdAsc = "CSM_USER_ID_ASC",
  CsmUserIdDesc = "CSM_USER_ID_DESC",
  FilingTypeAsc = "FILING_TYPE_ASC",
  FilingTypeDesc = "FILING_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LatestPetitionAsc = "LATEST_PETITION_ASC",
  LatestPetitionDesc = "LATEST_PETITION_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  NoteLogAsc = "NOTE_LOG_ASC",
  NoteLogDesc = "NOTE_LOG_DESC",
  OaUserIdAsc = "OA_USER_ID_ASC",
  OaUserIdDesc = "OA_USER_ID_DESC",
  PetitionerIdAsc = "PETITIONER_ID_ASC",
  PetitionerIdDesc = "PETITIONER_ID_DESC",
  PetitionerRepIdAsc = "PETITIONER_REP_ID_ASC",
  PetitionerRepIdDesc = "PETITIONER_REP_ID_DESC",
  PetitionShippedDateAsc = "PETITION_SHIPPED_DATE_ASC",
  PetitionShippedDateDesc = "PETITION_SHIPPED_DATE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PriorityAsc = "PRIORITY_ASC",
  PriorityDesc = "PRIORITY_DESC",
  ProjectManagementStatusAsc = "PROJECT_MANAGEMENT_STATUS_ASC",
  ProjectManagementStatusDesc = "PROJECT_MANAGEMENT_STATUS_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TargetSubmissionDateAsc = "TARGET_SUBMISSION_DATE_ASC",
  TargetSubmissionDateDesc = "TARGET_SUBMISSION_DATE_DESC",
  TrackingNumberAsc = "TRACKING_NUMBER_ASC",
  TrackingNumberDesc = "TRACKING_NUMBER_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UscisAddressAsc = "USCIS_ADDRESS_ASC",
  UscisAddressDesc = "USCIS_ADDRESS_DESC",
  UscisReceiptNumberAsc = "USCIS_RECEIPT_NUMBER_ASC",
  UscisReceiptNumberDesc = "USCIS_RECEIPT_NUMBER_DESC",
  UscisStatusAsc = "USCIS_STATUS_ASC",
  UscisStatusDesc = "USCIS_STATUS_DESC",
  VisaClassAsc = "VISA_CLASS_ASC",
  VisaClassDesc = "VISA_CLASS_DESC",
}

/** All input for the `populateNotes` mutation. */
export type PopulateNotesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `populateNotes` mutation. */
export type PopulateNotesPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  /** Reads a single `AdditionalEntity` using its globally unique `ID`. */
  additionalEntity: Maybe<AdditionalEntity>;
  additionalEntityById: Maybe<AdditionalEntity>;
  /** Reads a single `Admin` using its globally unique `ID`. */
  admin: Maybe<Admin>;
  adminByUserId: Maybe<Admin>;
  /** Reads and enables pagination through a set of `AdditionalEntity`. */
  allAdditionalEntities: Maybe<AdditionalEntitiesConnection>;
  /** Reads and enables pagination through a set of `Admin`. */
  allAdmins: Maybe<AdminsConnection>;
  /** Reads and enables pagination through a set of `AuditLog`. */
  allAuditLogs: Maybe<AuditLogsConnection>;
  /** Reads and enables pagination through a set of `CaseStrategy`. */
  allCaseStrategies: Maybe<CaseStrategiesConnection>;
  /** Reads and enables pagination through a set of `CaseType`. */
  allCaseTypes: Maybe<CaseTypesConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  allCompanies: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `CompanyDocument`. */
  allCompanyDocuments: Maybe<CompanyDocumentsConnection>;
  /** Reads and enables pagination through a set of `CompanyMember`. */
  allCompanyMembers: Maybe<CompanyMembersConnection>;
  /** Reads and enables pagination through a set of `CompanyOffice`. */
  allCompanyOffices: Maybe<CompanyOfficesConnection>;
  /** Reads and enables pagination through a set of `CompiledPetition`. */
  allCompiledPetitions: Maybe<CompiledPetitionsConnection>;
  /** Reads and enables pagination through a set of `CustomerCommsLog`. */
  allCustomerCommsLogs: Maybe<CustomerCommsLogsConnection>;
  /** Reads and enables pagination through a set of `EntityFile`. */
  allEntityFiles: Maybe<EntityFilesConnection>;
  /** Reads and enables pagination through a set of `EntityRelation`. */
  allEntityRelations: Maybe<EntityRelationsConnection>;
  /** Reads and enables pagination through a set of `EntitySchema`. */
  allEntitySchemas: Maybe<EntitySchemasConnection>;
  /** Reads and enables pagination through a set of `ExhibitFile`. */
  allExhibitFiles: Maybe<ExhibitFilesConnection>;
  /** Reads and enables pagination through a set of `ExhibitType`. */
  allExhibitTypes: Maybe<ExhibitTypesConnection>;
  /** Reads and enables pagination through a set of `Exhibit`. */
  allExhibits: Maybe<ExhibitsConnection>;
  /** Reads and enables pagination through a set of `File`. */
  allFiles: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `FlywaySchemaHistory`. */
  allFlywaySchemaHistories: Maybe<FlywaySchemaHistoriesConnection>;
  /** Reads and enables pagination through a set of `I94Entry`. */
  allI94Entries: Maybe<I94EntriesConnection>;
  /** Reads and enables pagination through a set of `ImmigrationDocument`. */
  allImmigrationDocuments: Maybe<ImmigrationDocumentsConnection>;
  /** Reads and enables pagination through a set of `IndividualVisaHistory`. */
  allIndividualVisaHistories: Maybe<IndividualVisaHistoriesConnection>;
  /** Reads and enables pagination through a set of `ModuleType`. */
  allModuleTypes: Maybe<ModuleTypesConnection>;
  /** Reads and enables pagination through a set of `Module`. */
  allModules: Maybe<ModulesConnection>;
  /** Reads and enables pagination through a set of `Note`. */
  allNotes: Maybe<NotesConnection>;
  /** Reads and enables pagination through a set of `OnboardingExhibitType`. */
  allOnboardingExhibitTypes: Maybe<OnboardingExhibitTypesConnection>;
  /** Reads and enables pagination through a set of `OnboardingModuleType`. */
  allOnboardingModuleTypes: Maybe<OnboardingModuleTypesConnection>;
  /** Reads and enables pagination through a set of `Onboarding`. */
  allOnboardings: Maybe<OnboardingsConnection>;
  /** Reads and enables pagination through a set of `PetitionForm`. */
  allPetitionForms: Maybe<PetitionFormsConnection>;
  /** Reads and enables pagination through a set of `PetitionLetterSignatory`. */
  allPetitionLetterSignatories: Maybe<PetitionLetterSignatoriesConnection>;
  /** Reads and enables pagination through a set of `PetitionLetter`. */
  allPetitionLetters: Maybe<PetitionLettersConnection>;
  /** Reads and enables pagination through a set of `Petition`. */
  allPetitions: Maybe<PetitionsConnection>;
  /** Reads and enables pagination through a set of `ReusableExhibitEntity`. */
  allReusableExhibitEntities: Maybe<ReusableExhibitEntitiesConnection>;
  /** Reads and enables pagination through a set of `ReusableExhibitFile`. */
  allReusableExhibitFiles: Maybe<ReusableExhibitFilesConnection>;
  /** Reads and enables pagination through a set of `ReusableExhibit`. */
  allReusableExhibits: Maybe<ReusableExhibitsConnection>;
  /** Reads and enables pagination through a set of `TaskQueue`. */
  allTaskQueues: Maybe<TaskQueuesConnection>;
  /** Reads and enables pagination through a set of `UserDatum`. */
  allUserData: Maybe<UserDataConnection>;
  /** Reads and enables pagination through a set of `UserDocument`. */
  allUserDocuments: Maybe<UserDocumentsConnection>;
  /** Reads and enables pagination through a set of `UserEmployment`. */
  allUserEmployments: Maybe<UserEmploymentsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `VisaType`. */
  allVisaTypes: Maybe<VisaTypesConnection>;
  /** Reads a single `AuditLog` using its globally unique `ID`. */
  auditLog: Maybe<AuditLog>;
  auditLogById: Maybe<AuditLog>;
  /** Reads a single `CaseStrategy` using its globally unique `ID`. */
  caseStrategy: Maybe<CaseStrategy>;
  caseStrategyById: Maybe<CaseStrategy>;
  /** Reads a single `CaseType` using its globally unique `ID`. */
  caseType: Maybe<CaseType>;
  caseTypeById: Maybe<CaseType>;
  /** Reads a single `Company` using its globally unique `ID`. */
  company: Maybe<Company>;
  companyByAtRecordId: Maybe<Company>;
  companyById: Maybe<Company>;
  /** Reads a single `CompanyDocument` using its globally unique `ID`. */
  companyDocument: Maybe<CompanyDocument>;
  companyDocumentByCompanyIdAndFileId: Maybe<CompanyDocument>;
  /** Reads a single `CompanyMember` using its globally unique `ID`. */
  companyMember: Maybe<CompanyMember>;
  companyMemberByCompanyIdAndUserId: Maybe<CompanyMember>;
  /** Reads a single `CompanyOffice` using its globally unique `ID`. */
  companyOffice: Maybe<CompanyOffice>;
  companyOfficeById: Maybe<CompanyOffice>;
  /** Reads a single `CompiledPetition` using its globally unique `ID`. */
  compiledPetition: Maybe<CompiledPetition>;
  compiledPetitionByJobId: Maybe<CompiledPetition>;
  currentUserId: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `CustomerCommsLog` using its globally unique `ID`. */
  customerCommsLog: Maybe<CustomerCommsLog>;
  customerCommsLogByLogId: Maybe<CustomerCommsLog>;
  customerCommsLogByRecordIdAndCommSource: Maybe<CustomerCommsLog>;
  entityRelationByParentAndParentTypeAndEntityId: Maybe<EntityRelation>;
  /** Reads a single `EntitySchema` using its globally unique `ID`. */
  entitySchema: Maybe<EntitySchema>;
  entitySchemaByEntityType: Maybe<EntitySchema>;
  /** Reads a single `Exhibit` using its globally unique `ID`. */
  exhibit: Maybe<Exhibit>;
  exhibitById: Maybe<Exhibit>;
  /** Reads a single `ExhibitFile` using its globally unique `ID`. */
  exhibitFile: Maybe<ExhibitFile>;
  exhibitFileByExhibitIdAndFileId: Maybe<ExhibitFile>;
  /** Reads a single `ExhibitType` using its globally unique `ID`. */
  exhibitType: Maybe<ExhibitType>;
  exhibitTypeById: Maybe<ExhibitType>;
  /** Reads a single `File` using its globally unique `ID`. */
  file: Maybe<File>;
  fileById: Maybe<File>;
  /** Reads a single `FlywaySchemaHistory` using its globally unique `ID`. */
  flywaySchemaHistory: Maybe<FlywaySchemaHistory>;
  flywaySchemaHistoryByInstalledRank: Maybe<FlywaySchemaHistory>;
  getLoggedInUser: Maybe<User>;
  /** Reads a single `I94Entry` using its globally unique `ID`. */
  i94Entry: Maybe<I94Entry>;
  i94EntryByI94Number: Maybe<I94Entry>;
  /** Reads a single `ImmigrationDocument` using its globally unique `ID`. */
  immigrationDocument: Maybe<ImmigrationDocument>;
  immigrationDocumentByOwnerIdAndFileId: Maybe<ImmigrationDocument>;
  /** Reads a single `IndividualVisaHistory` using its globally unique `ID`. */
  individualVisaHistory: Maybe<IndividualVisaHistory>;
  individualVisaHistoryById: Maybe<IndividualVisaHistory>;
  individualVisaHistoryByUserIdAndVisaNumber: Maybe<IndividualVisaHistory>;
  isAdmin: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `Module` using its globally unique `ID`. */
  module: Maybe<Module>;
  moduleById: Maybe<Module>;
  /** Reads a single `ModuleType` using its globally unique `ID`. */
  moduleType: Maybe<ModuleType>;
  moduleTypeById: Maybe<ModuleType>;
  /** Fetches an object given its globally unique `ID`. */
  node: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Note` using its globally unique `ID`. */
  note: Maybe<Note>;
  noteById: Maybe<Note>;
  /** Reads a single `Onboarding` using its globally unique `ID`. */
  onboarding: Maybe<Onboarding>;
  onboardingById: Maybe<Onboarding>;
  /** Reads a single `OnboardingExhibitType` using its globally unique `ID`. */
  onboardingExhibitType: Maybe<OnboardingExhibitType>;
  onboardingExhibitTypeByExhibitType: Maybe<OnboardingExhibitType>;
  /** Reads a single `OnboardingModuleType` using its globally unique `ID`. */
  onboardingModuleType: Maybe<OnboardingModuleType>;
  onboardingModuleTypeByModuleType: Maybe<OnboardingModuleType>;
  /** Reads a single `Petition` using its globally unique `ID`. */
  petition: Maybe<Petition>;
  petitionById: Maybe<Petition>;
  /** Reads a single `PetitionForm` using its globally unique `ID`. */
  petitionForm: Maybe<PetitionForm>;
  petitionFormById: Maybe<PetitionForm>;
  /** Reads a single `PetitionLetter` using its globally unique `ID`. */
  petitionLetter: Maybe<PetitionLetter>;
  petitionLetterById: Maybe<PetitionLetter>;
  /** Reads a single `PetitionLetterSignatory` using its globally unique `ID`. */
  petitionLetterSignatory: Maybe<PetitionLetterSignatory>;
  petitionLetterSignatoryByLetterId: Maybe<PetitionLetterSignatory>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `ReusableExhibit` using its globally unique `ID`. */
  reusableExhibit: Maybe<ReusableExhibit>;
  reusableExhibitById: Maybe<ReusableExhibit>;
  /** Reads a single `ReusableExhibitEntity` using its globally unique `ID`. */
  reusableExhibitEntity: Maybe<ReusableExhibitEntity>;
  reusableExhibitEntityByReusableExhibitIdAndEntityId: Maybe<ReusableExhibitEntity>;
  /** Reads a single `ReusableExhibitFile` using its globally unique `ID`. */
  reusableExhibitFile: Maybe<ReusableExhibitFile>;
  reusableExhibitFileByReusableExhibitIdAndFileId: Maybe<ReusableExhibitFile>;
  /** Reads a single `TaskQueue` using its globally unique `ID`. */
  taskQueue: Maybe<TaskQueue>;
  taskQueueById: Maybe<TaskQueue>;
  taskQueueByIdempotencyKey: Maybe<TaskQueue>;
  /** Reads a single `User` using its globally unique `ID`. */
  user: Maybe<User>;
  userByAtRecordId: Maybe<User>;
  userById: Maybe<User>;
  /** Reads a single `UserDatum` using its globally unique `ID`. */
  userDatum: Maybe<UserDatum>;
  userDatumById: Maybe<UserDatum>;
  /** Reads a single `UserDocument` using its globally unique `ID`. */
  userDocument: Maybe<UserDocument>;
  userDocumentByUserIdAndFileId: Maybe<UserDocument>;
  /** Reads a single `UserEmployment` using its globally unique `ID`. */
  userEmployment: Maybe<UserEmployment>;
  userEmploymentById: Maybe<UserEmployment>;
  userEmploymentByUserIdAndCompanyId: Maybe<UserEmployment>;
  /** Reads a single `VisaType` using its globally unique `ID`. */
  visaType: Maybe<VisaType>;
  visaTypeByVisaTypeCode: Maybe<VisaType>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAdditionalEntityArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAdditionalEntityByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAdminArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAdminByUserIdArgs = {
  userId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAdditionalEntitiesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AdditionalEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAdminsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AdminCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdminsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAuditLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AuditLogCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCaseStrategiesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CaseStrategyCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCaseTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CaseTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CaseTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCompaniesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCompanyDocumentsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCompanyMembersArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyMemberCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCompanyOfficesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyOfficeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyOfficesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCompiledPetitionsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompiledPetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCustomerCommsLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CustomerCommsLogCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEntityFilesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntityFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntityFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEntityRelationsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntityRelationCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntityRelationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEntitySchemasArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<EntitySchemaCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EntitySchemasOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExhibitFilesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExhibitTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExhibitsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFilesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<FileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFlywaySchemaHistoriesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<FlywaySchemaHistoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllI94EntriesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<I94EntryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllImmigrationDocumentsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ImmigrationDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllIndividualVisaHistoriesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<IndividualVisaHistoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllModuleTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ModuleTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModuleTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllModulesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ModuleCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllNotesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<NoteCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOnboardingExhibitTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<OnboardingExhibitTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnboardingExhibitTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOnboardingModuleTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<OnboardingModuleTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnboardingModuleTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOnboardingsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<OnboardingCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnboardingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPetitionFormsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionFormCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPetitionLetterSignatoriesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterSignatoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPetitionLettersArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPetitionsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllReusableExhibitEntitiesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllReusableExhibitFilesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllReusableExhibitsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTaskQueuesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<TaskQueueCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskQueuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserDataArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDatumCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserDocumentsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserEmploymentsArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserEmploymentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllVisaTypesArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<VisaTypeCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisaTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAuditLogArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuditLogByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCaseStrategyArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCaseStrategyByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCaseTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCaseTypeByIdArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyByAtRecordIdArgs = {
  atRecordId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyDocumentArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyDocumentByCompanyIdAndFileIdArgs = {
  companyId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyMemberArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyMemberByCompanyIdAndUserIdArgs = {
  companyId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyOfficeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompanyOfficeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompiledPetitionArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCompiledPetitionByJobIdArgs = {
  jobId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCustomerCommsLogArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCustomerCommsLogByLogIdArgs = {
  logId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCustomerCommsLogByRecordIdAndCommSourceArgs = {
  commSource: Scalars["String"]["input"];
  recordId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEntityRelationByParentAndParentTypeAndEntityIdArgs = {
  entityId: Scalars["BigInt"]["input"];
  parent: Scalars["BigInt"]["input"];
  parentType: ComponentTypes;
};

/** The root query type which gives access points into the data universe. */
export type QueryEntitySchemaArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEntitySchemaByEntityTypeArgs = {
  entityType: EntityTypes;
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitFileArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitFileByExhibitIdAndFileIdArgs = {
  exhibitId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExhibitTypeByIdArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFlywaySchemaHistoryArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFlywaySchemaHistoryByInstalledRankArgs = {
  installedRank: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryI94EntryArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryI94EntryByI94NumberArgs = {
  i94Number: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryImmigrationDocumentArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryImmigrationDocumentByOwnerIdAndFileIdArgs = {
  fileId: Scalars["BigInt"]["input"];
  ownerId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIndividualVisaHistoryArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIndividualVisaHistoryByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIndividualVisaHistoryByUserIdAndVisaNumberArgs = {
  userId: Scalars["BigInt"]["input"];
  visaNumber: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModuleArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModuleByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModuleTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModuleTypeByIdArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNoteArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNoteByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingExhibitTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingExhibitTypeByExhibitTypeArgs = {
  exhibitType: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingModuleTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOnboardingModuleTypeByModuleTypeArgs = {
  moduleType: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionFormArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionFormByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionLetterArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionLetterByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionLetterSignatoryArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPetitionLetterSignatoryByLetterIdArgs = {
  letterId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitEntityArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitEntityByReusableExhibitIdAndEntityIdArgs = {
  entityId: Scalars["BigInt"]["input"];
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitFileArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryReusableExhibitFileByReusableExhibitIdAndFileIdArgs = {
  fileId: Scalars["BigInt"]["input"];
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskQueueArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskQueueByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskQueueByIdempotencyKeyArgs = {
  idempotencyKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByAtRecordIdArgs = {
  atRecordId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDatumArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDatumByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDocumentArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDocumentByUserIdAndFileIdArgs = {
  fileId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserEmploymentArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserEmploymentByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserEmploymentByUserIdAndCompanyIdArgs = {
  companyId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryVisaTypeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryVisaTypeByVisaTypeCodeArgs = {
  visaTypeCode: Scalars["String"]["input"];
};

export type ReusableExhibit = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  exhibitType: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExhibitType` that is related to this `ReusableExhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  id: Scalars["BigInt"]["output"];
  moduleType: Scalars["String"]["output"];
  /** Reads a single `ModuleType` that is related to this `ReusableExhibit`. */
  moduleTypeByModuleType: Maybe<ModuleType>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Scalars["Int"]["output"];
  ownedBy: ExhibitOwnerType;
  parentExhibitId: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibit`. */
  reusableExhibitByParentExhibitId: Maybe<ReusableExhibit>;
  /** Reads and enables pagination through a set of `ReusableExhibitEntity`. */
  reusableExhibitEntitiesByReusableExhibitId: ReusableExhibitEntitiesConnection;
  /** Reads and enables pagination through a set of `ReusableExhibitFile`. */
  reusableExhibitFilesByReusableExhibitId: ReusableExhibitFilesConnection;
  /** Reads and enables pagination through a set of `ReusableExhibit`. */
  reusableExhibitsByParentExhibitId: ReusableExhibitsConnection;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  visible: Scalars["Boolean"]["output"];
};

export type ReusableExhibitReusableExhibitEntitiesByReusableExhibitIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

export type ReusableExhibitReusableExhibitFilesByReusableExhibitIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitFileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

export type ReusableExhibitReusableExhibitsByParentExhibitIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ReusableExhibitCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/**
 * A condition to be used against `ReusableExhibit` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReusableExhibitCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `exhibitType` field. */
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `moduleType` field. */
  moduleType: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `ownedBy` field. */
  ownedBy: InputMaybe<ExhibitOwnerType>;
  /** Checks for equality with the object’s `parentExhibitId` field. */
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `visible` field. */
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `ReusableExhibitEntity` values. */
export type ReusableExhibitEntitiesConnection = {
  /** A list of edges which contains the `ReusableExhibitEntity` and cursor to aid in pagination. */
  edges: Array<ReusableExhibitEntitiesEdge>;
  /** A list of `ReusableExhibitEntity` objects. */
  nodes: Array<Maybe<ReusableExhibitEntity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReusableExhibitEntity` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ReusableExhibitEntity` edge in the connection. */
export type ReusableExhibitEntitiesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReusableExhibitEntity` at the end of the edge. */
  node: Maybe<ReusableExhibitEntity>;
};

/** Methods to use when ordering `ReusableExhibitEntity`. */
export enum ReusableExhibitEntitiesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReusableExhibitIdAsc = "REUSABLE_EXHIBIT_ID_ASC",
  ReusableExhibitIdDesc = "REUSABLE_EXHIBIT_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type ReusableExhibitEntity = Node & {
  /** Reads a single `AdditionalEntity` that is related to this `ReusableExhibitEntity`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  entityId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitEntity`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  reusableExhibitId: Scalars["BigInt"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `ReusableExhibitEntity` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ReusableExhibitEntityCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `reusableExhibitId` field. */
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ReusableExhibitEntity` */
export type ReusableExhibitEntityInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  reusableExhibitId: Scalars["BigInt"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ReusableExhibitEntity`. Fields that are set will be updated. */
export type ReusableExhibitEntityPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  entityId: InputMaybe<Scalars["BigInt"]["input"]>;
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ReusableExhibitFile = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `File` that is related to this `ReusableExhibitFile`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  orderIndex: Scalars["Int"]["output"];
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitFile`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  reusableExhibitId: Scalars["BigInt"]["output"];
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
};

/**
 * A condition to be used against `ReusableExhibitFile` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ReusableExhibitFileCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `reusableExhibitId` field. */
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ReusableExhibitFile` */
export type ReusableExhibitFileInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  reusableExhibitId: Scalars["BigInt"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `ReusableExhibitFile`. Fields that are set will be updated. */
export type ReusableExhibitFilePatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An input for mutations affecting `ReusableExhibitFileUpdateInputRecord` */
export type ReusableExhibitFileUpdateInputRecordInput = {
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `ReusableExhibitFile` values. */
export type ReusableExhibitFilesConnection = {
  /** A list of edges which contains the `ReusableExhibitFile` and cursor to aid in pagination. */
  edges: Array<ReusableExhibitFilesEdge>;
  /** A list of `ReusableExhibitFile` objects. */
  nodes: Array<Maybe<ReusableExhibitFile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReusableExhibitFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ReusableExhibitFile` edge in the connection. */
export type ReusableExhibitFilesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReusableExhibitFile` at the end of the edge. */
  node: Maybe<ReusableExhibitFile>;
};

/** Methods to use when ordering `ReusableExhibitFile`. */
export enum ReusableExhibitFilesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReusableExhibitIdAsc = "REUSABLE_EXHIBIT_ID_ASC",
  ReusableExhibitIdDesc = "REUSABLE_EXHIBIT_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** An input for mutations affecting `ReusableExhibit` */
export type ReusableExhibitInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  moduleType: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownedBy: InputMaybe<ExhibitOwnerType>;
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `ReusableExhibitOrderUpdateInputRecord` */
export type ReusableExhibitOrderUpdateInputRecordInput = {
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  reusableExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Represents an update to a `ReusableExhibit`. Fields that are set will be updated. */
export type ReusableExhibitPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: InputMaybe<Scalars["String"]["input"]>;
  moduleType: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  orderIndex: InputMaybe<Scalars["Int"]["input"]>;
  ownedBy: InputMaybe<ExhibitOwnerType>;
  parentExhibitId: InputMaybe<Scalars["BigInt"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  visible: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `ReusableExhibit` values. */
export type ReusableExhibitsConnection = {
  /** A list of edges which contains the `ReusableExhibit` and cursor to aid in pagination. */
  edges: Array<ReusableExhibitsEdge>;
  /** A list of `ReusableExhibit` objects. */
  nodes: Array<Maybe<ReusableExhibit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReusableExhibit` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ReusableExhibit` edge in the connection. */
export type ReusableExhibitsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReusableExhibit` at the end of the edge. */
  node: Maybe<ReusableExhibit>;
};

/** Methods to use when ordering `ReusableExhibit`. */
export enum ReusableExhibitsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  ExhibitTypeAsc = "EXHIBIT_TYPE_ASC",
  ExhibitTypeDesc = "EXHIBIT_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ModuleTypeAsc = "MODULE_TYPE_ASC",
  ModuleTypeDesc = "MODULE_TYPE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrderIndexAsc = "ORDER_INDEX_ASC",
  OrderIndexDesc = "ORDER_INDEX_DESC",
  OwnedByAsc = "OWNED_BY_ASC",
  OwnedByDesc = "OWNED_BY_DESC",
  ParentExhibitIdAsc = "PARENT_EXHIBIT_ID_ASC",
  ParentExhibitIdDesc = "PARENT_EXHIBIT_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VisibleAsc = "VISIBLE_ASC",
  VisibleDesc = "VISIBLE_DESC",
}

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  companyDocsUpdated: Maybe<EntitySubscriptionPayload>;
  companyMembershipsUpdated: Maybe<EntitySubscriptionPayload>;
  exhibitFilesBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  exhibitFilesUpdated: Maybe<EntitySubscriptionPayload>;
  exhibitsBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  exhibitsUpdated: Maybe<EntitySubscriptionPayload>;
  formsBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  formsUpdated: Maybe<EntitySubscriptionPayload>;
  immigrationDocsUpdated: Maybe<EntitySubscriptionPayload>;
  lettersBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  lettersUpdated: Maybe<EntitySubscriptionPayload>;
  moduleBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  moduleUpdated: Maybe<EntitySubscriptionPayload>;
  petitionBuilderCompanies: Maybe<EntitySubscriptionPayload>;
  petitionBuilderPetitionUpdated: Maybe<EntitySubscriptionPayload>;
  petitionBuilderPetitions: Maybe<EntitySubscriptionPayload>;
  petitionBuilderUsers: Maybe<EntitySubscriptionPayload>;
  petitionUpdated: Maybe<EntitySubscriptionPayload>;
  subexhibitsBuilderUpdated: Maybe<EntitySubscriptionPayload>;
  /**
   * Triggered when the current user's data changes:
   *
   * - direct modifications to the user
   * - when their organization membership changes
   */
  testSubscription: Maybe<EntitySubscriptionPayload>;
  userUpdated: Maybe<EntitySubscriptionPayload>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionCompanyDocsUpdatedArgs = {
  wsId: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionCompanyMembershipsUpdatedArgs = {
  wsId: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionExhibitFilesBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionExhibitFilesUpdatedArgs = {
  id: Scalars["String"]["input"];
  wsId: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionExhibitsBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionExhibitsUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionFormsBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionFormsUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionImmigrationDocsUpdatedArgs = {
  wsId: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionLettersBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionLettersUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionModuleBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionModuleUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionPetitionBuilderPetitionUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionPetitionUpdatedArgs = {
  wsId: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionSubexhibitsBuilderUpdatedArgs = {
  id: Scalars["String"]["input"];
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionUserUpdatedArgs = {
  wsId: Scalars["String"]["input"];
};

export type TaskQueue = Node & {
  details: Maybe<Scalars["String"]["output"]>;
  endTime: Maybe<Scalars["Datetime"]["output"]>;
  errorMessage: Maybe<Scalars["String"]["output"]>;
  expirationTime: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["Int"]["output"];
  idempotencyKey: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  priority: Maybe<Scalars["Int"]["output"]>;
  recordId: Maybe<Scalars["BigInt"]["output"]>;
  retryCount: Maybe<Scalars["Int"]["output"]>;
  startTime: Scalars["Datetime"]["output"];
  status: TaskStatus;
  taskName: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
  vendor: Scalars["String"]["output"];
  workerType: Scalars["String"]["output"];
};

/**
 * A condition to be used against `TaskQueue` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaskQueueCondition = {
  /** Checks for equality with the object’s `details` field. */
  details: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `expirationTime` field. */
  expirationTime: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `idempotencyKey` field. */
  idempotencyKey: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `recordId` field. */
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `retryCount` field. */
  retryCount: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `taskName` field. */
  taskName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `vendor` field. */
  vendor: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `workerType` field. */
  workerType: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `TaskQueue` */
export type TaskQueueInput = {
  details: InputMaybe<Scalars["String"]["input"]>;
  endTime: InputMaybe<Scalars["Datetime"]["input"]>;
  errorMessage: InputMaybe<Scalars["String"]["input"]>;
  expirationTime: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  idempotencyKey: Scalars["String"]["input"];
  priority: InputMaybe<Scalars["Int"]["input"]>;
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  retryCount: InputMaybe<Scalars["Int"]["input"]>;
  startTime: InputMaybe<Scalars["Datetime"]["input"]>;
  status: TaskStatus;
  taskName: Scalars["String"]["input"];
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  vendor: Scalars["String"]["input"];
  workerType: Scalars["String"]["input"];
};

/** Represents an update to a `TaskQueue`. Fields that are set will be updated. */
export type TaskQueuePatch = {
  details: InputMaybe<Scalars["String"]["input"]>;
  endTime: InputMaybe<Scalars["Datetime"]["input"]>;
  errorMessage: InputMaybe<Scalars["String"]["input"]>;
  expirationTime: InputMaybe<Scalars["Datetime"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  idempotencyKey: InputMaybe<Scalars["String"]["input"]>;
  priority: InputMaybe<Scalars["Int"]["input"]>;
  recordId: InputMaybe<Scalars["BigInt"]["input"]>;
  retryCount: InputMaybe<Scalars["Int"]["input"]>;
  startTime: InputMaybe<Scalars["Datetime"]["input"]>;
  status: InputMaybe<TaskStatus>;
  taskName: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  vendor: InputMaybe<Scalars["String"]["input"]>;
  workerType: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `TaskQueue` values. */
export type TaskQueuesConnection = {
  /** A list of edges which contains the `TaskQueue` and cursor to aid in pagination. */
  edges: Array<TaskQueuesEdge>;
  /** A list of `TaskQueue` objects. */
  nodes: Array<Maybe<TaskQueue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskQueue` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TaskQueue` edge in the connection. */
export type TaskQueuesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TaskQueue` at the end of the edge. */
  node: Maybe<TaskQueue>;
};

/** Methods to use when ordering `TaskQueue`. */
export enum TaskQueuesOrderBy {
  DetailsAsc = "DETAILS_ASC",
  DetailsDesc = "DETAILS_DESC",
  EndTimeAsc = "END_TIME_ASC",
  EndTimeDesc = "END_TIME_DESC",
  ErrorMessageAsc = "ERROR_MESSAGE_ASC",
  ErrorMessageDesc = "ERROR_MESSAGE_DESC",
  ExpirationTimeAsc = "EXPIRATION_TIME_ASC",
  ExpirationTimeDesc = "EXPIRATION_TIME_DESC",
  IdempotencyKeyAsc = "IDEMPOTENCY_KEY_ASC",
  IdempotencyKeyDesc = "IDEMPOTENCY_KEY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PriorityAsc = "PRIORITY_ASC",
  PriorityDesc = "PRIORITY_DESC",
  RecordIdAsc = "RECORD_ID_ASC",
  RecordIdDesc = "RECORD_ID_DESC",
  RetryCountAsc = "RETRY_COUNT_ASC",
  RetryCountDesc = "RETRY_COUNT_DESC",
  StartTimeAsc = "START_TIME_ASC",
  StartTimeDesc = "START_TIME_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TaskNameAsc = "TASK_NAME_ASC",
  TaskNameDesc = "TASK_NAME_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VendorAsc = "VENDOR_ASC",
  VendorDesc = "VENDOR_DESC",
  WorkerTypeAsc = "WORKER_TYPE_ASC",
  WorkerTypeDesc = "WORKER_TYPE_DESC",
}

export enum TaskStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Pending = "PENDING",
  Running = "RUNNING",
}

export enum UnitTypeAddressEnum {
  Apt = "APT",
  Flr = "FLR",
  Ste = "STE",
}

/** All input for the `updateAdditionalEntityById` mutation. */
export type UpdateAdditionalEntityByIdInput = {
  /** An object where the defined keys will be set on the `AdditionalEntity` being updated. */
  additionalEntityPatch: AdditionalEntityPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateAdditionalEntity` mutation. */
export type UpdateAdditionalEntityInput = {
  /** An object where the defined keys will be set on the `AdditionalEntity` being updated. */
  additionalEntityPatch: AdditionalEntityPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AdditionalEntity` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `AdditionalEntity` mutation. */
export type UpdateAdditionalEntityPayload = {
  /** The `AdditionalEntity` that was updated by this mutation. */
  additionalEntity: Maybe<AdditionalEntity>;
  /** An edge for our `AdditionalEntity`. May be used by Relay 1. */
  additionalEntityEdge: Maybe<AdditionalEntitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `AdditionalEntity`. */
  companyByCompanyId: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AdditionalEntity`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `AdditionalEntity` mutation. */
export type UpdateAdditionalEntityPayloadAdditionalEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

/** All input for the `updateAdminByUserId` mutation. */
export type UpdateAdminByUserIdInput = {
  /** An object where the defined keys will be set on the `Admin` being updated. */
  adminPatch: AdminPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `updateAdmin` mutation. */
export type UpdateAdminInput = {
  /** An object where the defined keys will be set on the `Admin` being updated. */
  adminPatch: AdminPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Admin` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `Admin` mutation. */
export type UpdateAdminPayload = {
  /** The `Admin` that was updated by this mutation. */
  admin: Maybe<Admin>;
  /** An edge for our `Admin`. May be used by Relay 1. */
  adminEdge: Maybe<AdminsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Admin`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `Admin` mutation. */
export type UpdateAdminPayloadAdminEdgeArgs = {
  orderBy?: InputMaybe<Array<AdminsOrderBy>>;
};

/** All input for the `updateAuditLogById` mutation. */
export type UpdateAuditLogByIdInput = {
  /** An object where the defined keys will be set on the `AuditLog` being updated. */
  auditLogPatch: AuditLogPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateAuditLog` mutation. */
export type UpdateAuditLogInput = {
  /** An object where the defined keys will be set on the `AuditLog` being updated. */
  auditLogPatch: AuditLogPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditLog` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `AuditLog` mutation. */
export type UpdateAuditLogPayload = {
  /** The `AuditLog` that was updated by this mutation. */
  auditLog: Maybe<AuditLog>;
  /** An edge for our `AuditLog`. May be used by Relay 1. */
  auditLogEdge: Maybe<AuditLogsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AuditLog` mutation. */
export type UpdateAuditLogPayloadAuditLogEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditLogsOrderBy>>;
};

/** All input for the `updateCaseStrategyById` mutation. */
export type UpdateCaseStrategyByIdInput = {
  /** An object where the defined keys will be set on the `CaseStrategy` being updated. */
  caseStrategyPatch: CaseStrategyPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateCaseStrategy` mutation. */
export type UpdateCaseStrategyInput = {
  /** An object where the defined keys will be set on the `CaseStrategy` being updated. */
  caseStrategyPatch: CaseStrategyPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CaseStrategy` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CaseStrategy` mutation. */
export type UpdateCaseStrategyPayload = {
  /** The `CaseStrategy` that was updated by this mutation. */
  caseStrategy: Maybe<CaseStrategy>;
  /** An edge for our `CaseStrategy`. May be used by Relay 1. */
  caseStrategyEdge: Maybe<CaseStrategiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Petition` that is related to this `CaseStrategy`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CaseStrategy`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `CaseStrategy` mutation. */
export type UpdateCaseStrategyPayloadCaseStrategyEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

/** All input for the `updateCaseTypeById` mutation. */
export type UpdateCaseTypeByIdInput = {
  /** An object where the defined keys will be set on the `CaseType` being updated. */
  caseTypePatch: CaseTypePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

/** All input for the `updateCaseType` mutation. */
export type UpdateCaseTypeInput = {
  /** An object where the defined keys will be set on the `CaseType` being updated. */
  caseTypePatch: CaseTypePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `CaseType` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CaseType` mutation. */
export type UpdateCaseTypePayload = {
  /** The `CaseType` that was updated by this mutation. */
  caseType: Maybe<CaseType>;
  /** An edge for our `CaseType`. May be used by Relay 1. */
  caseTypeEdge: Maybe<CaseTypesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `CaseType` mutation. */
export type UpdateCaseTypePayloadCaseTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<CaseTypesOrderBy>>;
};

/** All input for the `updateCompanyByAtRecordId` mutation. */
export type UpdateCompanyByAtRecordIdInput = {
  atRecordId: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  companyPatch: CompanyPatch;
};

/** All input for the `updateCompanyById` mutation. */
export type UpdateCompanyByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  companyPatch: CompanyPatch;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateCompanyDocumentByCompanyIdAndFileId` mutation. */
export type UpdateCompanyDocumentByCompanyIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompanyDocument` being updated. */
  companyDocumentPatch: CompanyDocumentPatch;
  companyId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** All input for the `updateCompanyDocument` mutation. */
export type UpdateCompanyDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompanyDocument` being updated. */
  companyDocumentPatch: CompanyDocumentPatch;
  /** The globally unique `ID` which will identify a single `CompanyDocument` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CompanyDocument` mutation. */
export type UpdateCompanyDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyDocument`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyDocument` that was updated by this mutation. */
  companyDocument: Maybe<CompanyDocument>;
  /** An edge for our `CompanyDocument`. May be used by Relay 1. */
  companyDocumentEdge: Maybe<CompanyDocumentsEdge>;
  /** Reads a single `File` that is related to this `CompanyDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `CompanyDocument` mutation. */
export type UpdateCompanyDocumentPayloadCompanyDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyDocumentsOrderBy>>;
};

/** All input for the `updateCompany` mutation. */
export type UpdateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  companyPatch: CompanyPatch;
  /** The globally unique `ID` which will identify a single `Company` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** All input for the `updateCompanyMemberByCompanyIdAndUserId` mutation. */
export type UpdateCompanyMemberByCompanyIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `CompanyMember` being updated. */
  companyMemberPatch: CompanyMemberPatch;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `updateCompanyMember` mutation. */
export type UpdateCompanyMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompanyMember` being updated. */
  companyMemberPatch: CompanyMemberPatch;
  /** The globally unique `ID` which will identify a single `CompanyMember` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CompanyMember` mutation. */
export type UpdateCompanyMemberPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyMember`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyMember` that was updated by this mutation. */
  companyMember: Maybe<CompanyMember>;
  /** An edge for our `CompanyMember`. May be used by Relay 1. */
  companyMemberEdge: Maybe<CompanyMembersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompanyMember`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `CompanyMember` mutation. */
export type UpdateCompanyMemberPayloadCompanyMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

/** All input for the `updateCompanyOfficeById` mutation. */
export type UpdateCompanyOfficeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompanyOffice` being updated. */
  companyOfficePatch: CompanyOfficePatch;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateCompanyOffice` mutation. */
export type UpdateCompanyOfficeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompanyOffice` being updated. */
  companyOfficePatch: CompanyOfficePatch;
  /** The globally unique `ID` which will identify a single `CompanyOffice` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CompanyOffice` mutation. */
export type UpdateCompanyOfficePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CompanyOffice`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CompanyOffice` that was updated by this mutation. */
  companyOffice: Maybe<CompanyOffice>;
  /** An edge for our `CompanyOffice`. May be used by Relay 1. */
  companyOfficeEdge: Maybe<CompanyOfficesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `CompanyOffice` mutation. */
export type UpdateCompanyOfficePayloadCompanyOfficeEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyOfficesOrderBy>>;
};

/** The output of our update `Company` mutation. */
export type UpdateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Company` that was updated by this mutation. */
  company: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge: Maybe<CompaniesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Company` mutation. */
export type UpdateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the `updateCompiledPetitionByJobId` mutation. */
export type UpdateCompiledPetitionByJobIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompiledPetition` being updated. */
  compiledPetitionPatch: CompiledPetitionPatch;
  jobId: Scalars["BigInt"]["input"];
};

/** All input for the `updateCompiledPetition` mutation. */
export type UpdateCompiledPetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CompiledPetition` being updated. */
  compiledPetitionPatch: CompiledPetitionPatch;
  /** The globally unique `ID` which will identify a single `CompiledPetition` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CompiledPetition` mutation. */
export type UpdateCompiledPetitionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `CompiledPetition` that was updated by this mutation. */
  compiledPetition: Maybe<CompiledPetition>;
  /** An edge for our `CompiledPetition`. May be used by Relay 1. */
  compiledPetitionEdge: Maybe<CompiledPetitionsEdge>;
  /** Reads a single `File` that is related to this `CompiledPetition`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `CompiledPetition`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `CompiledPetition`. */
  userByCreatorId: Maybe<User>;
};

/** The output of our update `CompiledPetition` mutation. */
export type UpdateCompiledPetitionPayloadCompiledPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

/** All input for the `updateCustomerCommsLogByLogId` mutation. */
export type UpdateCustomerCommsLogByLogIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CustomerCommsLog` being updated. */
  customerCommsLogPatch: CustomerCommsLogPatch;
  logId: Scalars["BigInt"]["input"];
};

/** All input for the `updateCustomerCommsLogByRecordIdAndCommSource` mutation. */
export type UpdateCustomerCommsLogByRecordIdAndCommSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  commSource: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `CustomerCommsLog` being updated. */
  customerCommsLogPatch: CustomerCommsLogPatch;
  recordId: Scalars["String"]["input"];
};

/** All input for the `updateCustomerCommsLog` mutation. */
export type UpdateCustomerCommsLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `CustomerCommsLog` being updated. */
  customerCommsLogPatch: CustomerCommsLogPatch;
  /** The globally unique `ID` which will identify a single `CustomerCommsLog` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `CustomerCommsLog` mutation. */
export type UpdateCustomerCommsLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `CustomerCommsLog`. */
  companyByCompanyId: Maybe<Company>;
  /** The `CustomerCommsLog` that was updated by this mutation. */
  customerCommsLog: Maybe<CustomerCommsLog>;
  /** An edge for our `CustomerCommsLog`. May be used by Relay 1. */
  customerCommsLogEdge: Maybe<CustomerCommsLogsEdge>;
  /** Reads a single `Petition` that is related to this `CustomerCommsLog`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `CustomerCommsLog`. */
  userByCustomerUserId: Maybe<User>;
};

/** The output of our update `CustomerCommsLog` mutation. */
export type UpdateCustomerCommsLogPayloadCustomerCommsLogEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

/** All input for the `updateEntityRelationByParentAndParentTypeAndEntityId` mutation. */
export type UpdateEntityRelationByParentAndParentTypeAndEntityIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `EntityRelation` being updated. */
  entityRelationPatch: EntityRelationPatch;
  parent: Scalars["BigInt"]["input"];
  parentType: ComponentTypes;
};

/** The output of our update `EntityRelation` mutation. */
export type UpdateEntityRelationPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `EntityRelation`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EntityRelation` that was updated by this mutation. */
  entityRelation: Maybe<EntityRelation>;
  /** An edge for our `EntityRelation`. May be used by Relay 1. */
  entityRelationEdge: Maybe<EntityRelationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `EntityRelation` mutation. */
export type UpdateEntityRelationPayloadEntityRelationEdgeArgs = {
  orderBy?: InputMaybe<Array<EntityRelationsOrderBy>>;
};

/** All input for the `updateEntitySchemaByEntityType` mutation. */
export type UpdateEntitySchemaByEntityTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `EntitySchema` being updated. */
  entitySchemaPatch: EntitySchemaPatch;
  entityType: EntityTypes;
};

/** All input for the `updateEntitySchema` mutation. */
export type UpdateEntitySchemaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `EntitySchema` being updated. */
  entitySchemaPatch: EntitySchemaPatch;
  /** The globally unique `ID` which will identify a single `EntitySchema` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `EntitySchema` mutation. */
export type UpdateEntitySchemaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EntitySchema` that was updated by this mutation. */
  entitySchema: Maybe<EntitySchema>;
  /** An edge for our `EntitySchema`. May be used by Relay 1. */
  entitySchemaEdge: Maybe<EntitySchemasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `EntitySchema` mutation. */
export type UpdateEntitySchemaPayloadEntitySchemaEdgeArgs = {
  orderBy?: InputMaybe<Array<EntitySchemasOrderBy>>;
};

/** All input for the `updateExhibitById` mutation. */
export type UpdateExhibitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Exhibit` being updated. */
  exhibitPatch: ExhibitPatch;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateExhibitFileByExhibitIdAndFileId` mutation. */
export type UpdateExhibitFileByExhibitIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExhibitFile` being updated. */
  exhibitFilePatch: ExhibitFilePatch;
  exhibitId: Scalars["BigInt"]["input"];
  fileId: Scalars["BigInt"]["input"];
};

/** All input for the `updateExhibitFile` mutation. */
export type UpdateExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExhibitFile` being updated. */
  exhibitFilePatch: ExhibitFilePatch;
  /** The globally unique `ID` which will identify a single `ExhibitFile` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `ExhibitFile` mutation. */
export type UpdateExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Exhibit` that is related to this `ExhibitFile`. */
  exhibitByExhibitId: Maybe<Exhibit>;
  /** The `ExhibitFile` that was updated by this mutation. */
  exhibitFile: Maybe<ExhibitFile>;
  /** An edge for our `ExhibitFile`. May be used by Relay 1. */
  exhibitFileEdge: Maybe<ExhibitFilesEdge>;
  /** Reads a single `File` that is related to this `ExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ExhibitFile` mutation. */
export type UpdateExhibitFilePayloadExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitFilesOrderBy>>;
};

/** All input for the `updateExhibit` mutation. */
export type UpdateExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Exhibit` being updated. */
  exhibitPatch: ExhibitPatch;
  /** The globally unique `ID` which will identify a single `Exhibit` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `Exhibit` mutation. */
export type UpdateExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Exhibit` that was updated by this mutation. */
  exhibit: Maybe<Exhibit>;
  /** Reads a single `Exhibit` that is related to this `Exhibit`. */
  exhibitByParentExhibitId: Maybe<Exhibit>;
  /** An edge for our `Exhibit`. May be used by Relay 1. */
  exhibitEdge: Maybe<ExhibitsEdge>;
  /** Reads a single `ExhibitType` that is related to this `Exhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `Module` that is related to this `Exhibit`. */
  moduleByModuleId: Maybe<Module>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Exhibit` mutation. */
export type UpdateExhibitPayloadExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitsOrderBy>>;
};

/** All input for the `updateExhibitTypeById` mutation. */
export type UpdateExhibitTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExhibitType` being updated. */
  exhibitTypePatch: ExhibitTypePatch;
  id: Scalars["String"]["input"];
};

/** All input for the `updateExhibitType` mutation. */
export type UpdateExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExhibitType` being updated. */
  exhibitTypePatch: ExhibitTypePatch;
  /** The globally unique `ID` which will identify a single `ExhibitType` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `ExhibitType` mutation. */
export type UpdateExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ExhibitType` that was updated by this mutation. */
  exhibitType: Maybe<ExhibitType>;
  /** An edge for our `ExhibitType`. May be used by Relay 1. */
  exhibitTypeEdge: Maybe<ExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ExhibitType` mutation. */
export type UpdateExhibitTypePayloadExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExhibitTypesOrderBy>>;
};

/** All input for the `updateFileById` mutation. */
export type UpdateFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `File` being updated. */
  filePatch: FilePatch;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `File` being updated. */
  filePatch: FilePatch;
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `File` that was updated by this mutation. */
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateFlywaySchemaHistoryByInstalledRank` mutation. */
export type UpdateFlywaySchemaHistoryByInstalledRankInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FlywaySchemaHistory` being updated. */
  flywaySchemaHistoryPatch: FlywaySchemaHistoryPatch;
  installedRank: Scalars["Int"]["input"];
};

/** All input for the `updateFlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FlywaySchemaHistory` being updated. */
  flywaySchemaHistoryPatch: FlywaySchemaHistoryPatch;
  /** The globally unique `ID` which will identify a single `FlywaySchemaHistory` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `FlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `FlywaySchemaHistory` that was updated by this mutation. */
  flywaySchemaHistory: Maybe<FlywaySchemaHistory>;
  /** An edge for our `FlywaySchemaHistory`. May be used by Relay 1. */
  flywaySchemaHistoryEdge: Maybe<FlywaySchemaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `FlywaySchemaHistory` mutation. */
export type UpdateFlywaySchemaHistoryPayloadFlywaySchemaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<FlywaySchemaHistoriesOrderBy>>;
};

/** All input for the `updateI94EntryByI94Number` mutation. */
export type UpdateI94EntryByI94NumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `I94Entry` being updated. */
  i94EntryPatch: I94EntryPatch;
  i94Number: Scalars["String"]["input"];
};

/** All input for the `updateI94Entry` mutation. */
export type UpdateI94EntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `I94Entry` being updated. */
  i94EntryPatch: I94EntryPatch;
  /** The globally unique `ID` which will identify a single `I94Entry` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `I94Entry` mutation. */
export type UpdateI94EntryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `I94Entry`. */
  fileByFileId: Maybe<File>;
  /** The `I94Entry` that was updated by this mutation. */
  i94Entry: Maybe<I94Entry>;
  /** An edge for our `I94Entry`. May be used by Relay 1. */
  i94EntryEdge: Maybe<I94EntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `I94Entry`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `I94Entry` mutation. */
export type UpdateI94EntryPayloadI94EntryEdgeArgs = {
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

/** All input for the `updateImmigrationDocumentByOwnerIdAndFileId` mutation. */
export type UpdateImmigrationDocumentByOwnerIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `ImmigrationDocument` being updated. */
  immigrationDocumentPatch: ImmigrationDocumentPatch;
  ownerId: Scalars["BigInt"]["input"];
};

/** All input for the `updateImmigrationDocument` mutation. */
export type UpdateImmigrationDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ImmigrationDocument` being updated. */
  immigrationDocumentPatch: ImmigrationDocumentPatch;
  /** The globally unique `ID` which will identify a single `ImmigrationDocument` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `ImmigrationDocument` mutation. */
export type UpdateImmigrationDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `ImmigrationDocument`. */
  fileByFileId: Maybe<File>;
  /** The `ImmigrationDocument` that was updated by this mutation. */
  immigrationDocument: Maybe<ImmigrationDocument>;
  /** An edge for our `ImmigrationDocument`. May be used by Relay 1. */
  immigrationDocumentEdge: Maybe<ImmigrationDocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ImmigrationDocument`. */
  userByOwnerId: Maybe<User>;
};

/** The output of our update `ImmigrationDocument` mutation. */
export type UpdateImmigrationDocumentPayloadImmigrationDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

/** All input for the `updateIndividualVisaHistoryById` mutation. */
export type UpdateIndividualVisaHistoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `IndividualVisaHistory` being updated. */
  individualVisaHistoryPatch: IndividualVisaHistoryPatch;
};

/** All input for the `updateIndividualVisaHistoryByUserIdAndVisaNumber` mutation. */
export type UpdateIndividualVisaHistoryByUserIdAndVisaNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `IndividualVisaHistory` being updated. */
  individualVisaHistoryPatch: IndividualVisaHistoryPatch;
  userId: Scalars["BigInt"]["input"];
  visaNumber: Scalars["String"]["input"];
};

/** All input for the `updateIndividualVisaHistory` mutation. */
export type UpdateIndividualVisaHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `IndividualVisaHistory` being updated. */
  individualVisaHistoryPatch: IndividualVisaHistoryPatch;
  /** The globally unique `ID` which will identify a single `IndividualVisaHistory` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `IndividualVisaHistory` mutation. */
export type UpdateIndividualVisaHistoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `IndividualVisaHistory`. */
  fileByFileId: Maybe<File>;
  /** The `IndividualVisaHistory` that was updated by this mutation. */
  individualVisaHistory: Maybe<IndividualVisaHistory>;
  /** An edge for our `IndividualVisaHistory`. May be used by Relay 1. */
  individualVisaHistoryEdge: Maybe<IndividualVisaHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `IndividualVisaHistory`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `IndividualVisaHistory` mutation. */
export type UpdateIndividualVisaHistoryPayloadIndividualVisaHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

/** All input for the `updateModuleById` mutation. */
export type UpdateModuleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `Module` being updated. */
  modulePatch: ModulePatch;
};

/** All input for the `updateModule` mutation. */
export type UpdateModuleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Module` being updated. */
  modulePatch: ModulePatch;
  /** The globally unique `ID` which will identify a single `Module` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `Module` mutation. */
export type UpdateModulePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Module` that was updated by this mutation. */
  module: Maybe<Module>;
  /** An edge for our `Module`. May be used by Relay 1. */
  moduleEdge: Maybe<ModulesEdge>;
  /** Reads a single `ModuleType` that is related to this `Module`. */
  moduleTypeByType: Maybe<ModuleType>;
  /** Reads a single `Petition` that is related to this `Module`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Module` mutation. */
export type UpdateModulePayloadModuleEdgeArgs = {
  orderBy?: InputMaybe<Array<ModulesOrderBy>>;
};

/** All input for the `updateModuleTypeById` mutation. */
export type UpdateModuleTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `ModuleType` being updated. */
  moduleTypePatch: ModuleTypePatch;
};

/** All input for the `updateModuleType` mutation. */
export type UpdateModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ModuleType` being updated. */
  moduleTypePatch: ModuleTypePatch;
  /** The globally unique `ID` which will identify a single `ModuleType` to be updated. */
  nodeId: Scalars["ID"]["input"];
};

/** The output of our update `ModuleType` mutation. */
export type UpdateModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ModuleType` that was updated by this mutation. */
  moduleType: Maybe<ModuleType>;
  /** An edge for our `ModuleType`. May be used by Relay 1. */
  moduleTypeEdge: Maybe<ModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ModuleType` mutation. */
export type UpdateModuleTypePayloadModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<ModuleTypesOrderBy>>;
};

/** All input for the `updateMultipleExhibitFiles` mutation. */
export type UpdateMultipleExhibitFilesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<Array<InputMaybe<ExhibitFileUpdateInputRecordInput>>>;
};

/** The output of our `updateMultipleExhibitFiles` mutation. */
export type UpdateMultipleExhibitFilesPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleExhibitFilesRecord>>>;
};

/** The return type of our `updateMultipleExhibitFiles` mutation. */
export type UpdateMultipleExhibitFilesRecord = {
  exhibitId: Maybe<Scalars["BigInt"]["output"]>;
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  orderIndex: Maybe<Scalars["Int"]["output"]>;
};

/** All input for the `updateMultipleExhibitsOrder` mutation. */
export type UpdateMultipleExhibitsOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<Array<InputMaybe<ExhibitOrderUpdateInputRecordInput>>>;
};

/** The output of our `updateMultipleExhibitsOrder` mutation. */
export type UpdateMultipleExhibitsOrderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleExhibitsOrderRecord>>>;
};

/** The return type of our `updateMultipleExhibitsOrder` mutation. */
export type UpdateMultipleExhibitsOrderRecord = {
  exhibitId: Maybe<Scalars["BigInt"]["output"]>;
  orderIndex: Maybe<Scalars["Int"]["output"]>;
};

/** All input for the `updateMultipleImmigrationDocs` mutation. */
export type UpdateMultipleImmigrationDocsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<
    Array<InputMaybe<ImmigrationDocsUpdateInputRecordInput>>
  >;
};

/** The output of our `updateMultipleImmigrationDocs` mutation. */
export type UpdateMultipleImmigrationDocsPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleImmigrationDocsRecord>>>;
};

/** The return type of our `updateMultipleImmigrationDocs` mutation. */
export type UpdateMultipleImmigrationDocsRecord = {
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  orderIndex: Maybe<Scalars["Int"]["output"]>;
  ownerId: Maybe<Scalars["BigInt"]["output"]>;
};

/** All input for the `updateMultipleModuleOrder` mutation. */
export type UpdateMultipleModuleOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<Array<InputMaybe<ModuleOrderUpdateInputRecordInput>>>;
};

/** The output of our `updateMultipleModuleOrder` mutation. */
export type UpdateMultipleModuleOrderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleModuleOrderRecord>>>;
};

/** The return type of our `updateMultipleModuleOrder` mutation. */
export type UpdateMultipleModuleOrderRecord = {
  moduleId: Maybe<Scalars["BigInt"]["output"]>;
  orderIndex: Maybe<Scalars["Int"]["output"]>;
};

/** All input for the `updateMultipleReusableExhibitFiles` mutation. */
export type UpdateMultipleReusableExhibitFilesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<
    Array<InputMaybe<ReusableExhibitFileUpdateInputRecordInput>>
  >;
};

/** The output of our `updateMultipleReusableExhibitFiles` mutation. */
export type UpdateMultipleReusableExhibitFilesPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleReusableExhibitFilesRecord>>>;
};

/** The return type of our `updateMultipleReusableExhibitFiles` mutation. */
export type UpdateMultipleReusableExhibitFilesRecord = {
  fileId: Maybe<Scalars["BigInt"]["output"]>;
  orderIndex: Maybe<Scalars["Int"]["output"]>;
  reusableExhibitId: Maybe<Scalars["BigInt"]["output"]>;
};

/** All input for the `updateMultipleReusableExhibitsOrder` mutation. */
export type UpdateMultipleReusableExhibitsOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  inputData: InputMaybe<
    Array<InputMaybe<ReusableExhibitOrderUpdateInputRecordInput>>
  >;
};

/** The output of our `updateMultipleReusableExhibitsOrder` mutation. */
export type UpdateMultipleReusableExhibitsOrderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  results: Maybe<Array<Maybe<UpdateMultipleReusableExhibitsOrderRecord>>>;
};

/** The return type of our `updateMultipleReusableExhibitsOrder` mutation. */
export type UpdateMultipleReusableExhibitsOrderRecord = {
  orderIndex: Maybe<Scalars["Int"]["output"]>;
  reusableExhibitId: Maybe<Scalars["BigInt"]["output"]>;
};

/** All input for the `updateNoteById` mutation. */
export type UpdateNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `Note` being updated. */
  notePatch: NotePatch;
};

/** All input for the `updateNote` mutation. */
export type UpdateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Note` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `Note` being updated. */
  notePatch: NotePatch;
};

/** The output of our update `Note` mutation. */
export type UpdateNotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Note` that was updated by this mutation. */
  note: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge: Maybe<NotesEdge>;
  /** Reads a single `Petition` that is related to this `Note`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  userBySenderId: Maybe<User>;
  /** Reads a single `User` that is related to this `Note`. */
  userByUserId: Maybe<User>;
};

/** The output of our update `Note` mutation. */
export type UpdateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `updateOnboardingById` mutation. */
export type UpdateOnboardingByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `Onboarding` being updated. */
  onboardingPatch: OnboardingPatch;
};

/** All input for the `updateOnboardingExhibitTypeByExhibitType` mutation. */
export type UpdateOnboardingExhibitTypeByExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  exhibitType: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `OnboardingExhibitType` being updated. */
  onboardingExhibitTypePatch: OnboardingExhibitTypePatch;
};

/** All input for the `updateOnboardingExhibitType` mutation. */
export type UpdateOnboardingExhibitTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `OnboardingExhibitType` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `OnboardingExhibitType` being updated. */
  onboardingExhibitTypePatch: OnboardingExhibitTypePatch;
};

/** The output of our update `OnboardingExhibitType` mutation. */
export type UpdateOnboardingExhibitTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `OnboardingExhibitType` that was updated by this mutation. */
  onboardingExhibitType: Maybe<OnboardingExhibitType>;
  /** An edge for our `OnboardingExhibitType`. May be used by Relay 1. */
  onboardingExhibitTypeEdge: Maybe<OnboardingExhibitTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `OnboardingExhibitType` mutation. */
export type UpdateOnboardingExhibitTypePayloadOnboardingExhibitTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingExhibitTypesOrderBy>>;
};

/** All input for the `updateOnboarding` mutation. */
export type UpdateOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Onboarding` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `Onboarding` being updated. */
  onboardingPatch: OnboardingPatch;
};

/** All input for the `updateOnboardingModuleTypeByModuleType` mutation. */
export type UpdateOnboardingModuleTypeByModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  moduleType: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `OnboardingModuleType` being updated. */
  onboardingModuleTypePatch: OnboardingModuleTypePatch;
};

/** All input for the `updateOnboardingModuleType` mutation. */
export type UpdateOnboardingModuleTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `OnboardingModuleType` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `OnboardingModuleType` being updated. */
  onboardingModuleTypePatch: OnboardingModuleTypePatch;
};

/** The output of our update `OnboardingModuleType` mutation. */
export type UpdateOnboardingModuleTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `OnboardingModuleType` that was updated by this mutation. */
  onboardingModuleType: Maybe<OnboardingModuleType>;
  /** An edge for our `OnboardingModuleType`. May be used by Relay 1. */
  onboardingModuleTypeEdge: Maybe<OnboardingModuleTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `OnboardingModuleType` mutation. */
export type UpdateOnboardingModuleTypePayloadOnboardingModuleTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingModuleTypesOrderBy>>;
};

/** The output of our update `Onboarding` mutation. */
export type UpdateOnboardingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Onboarding` that was updated by this mutation. */
  onboarding: Maybe<Onboarding>;
  /** An edge for our `Onboarding`. May be used by Relay 1. */
  onboardingEdge: Maybe<OnboardingsEdge>;
  /** Reads a single `Petition` that is related to this `Onboarding`. */
  petitionByPetitionId: Maybe<Petition>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Onboarding` mutation. */
export type UpdateOnboardingPayloadOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<OnboardingsOrderBy>>;
};

/** All input for the `updatePetitionById` mutation. */
export type UpdatePetitionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `Petition` being updated. */
  petitionPatch: PetitionPatch;
};

/** All input for the `updatePetitionFormById` mutation. */
export type UpdatePetitionFormByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `PetitionForm` being updated. */
  petitionFormPatch: PetitionFormPatch;
};

/** All input for the `updatePetitionForm` mutation. */
export type UpdatePetitionFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionForm` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `PetitionForm` being updated. */
  petitionFormPatch: PetitionFormPatch;
};

/** The output of our update `PetitionForm` mutation. */
export type UpdatePetitionFormPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionForm`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionForm`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionForm` that was updated by this mutation. */
  petitionForm: Maybe<PetitionForm>;
  /** An edge for our `PetitionForm`. May be used by Relay 1. */
  petitionFormEdge: Maybe<PetitionFormsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `PetitionForm` mutation. */
export type UpdatePetitionFormPayloadPetitionFormEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionFormsOrderBy>>;
};

/** All input for the `updatePetition` mutation. */
export type UpdatePetitionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Petition` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `Petition` being updated. */
  petitionPatch: PetitionPatch;
};

/** All input for the `updatePetitionLetterById` mutation. */
export type UpdatePetitionLetterByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `PetitionLetter` being updated. */
  petitionLetterPatch: PetitionLetterPatch;
};

/** All input for the `updatePetitionLetter` mutation. */
export type UpdatePetitionLetterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionLetter` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `PetitionLetter` being updated. */
  petitionLetterPatch: PetitionLetterPatch;
};

/** The output of our update `PetitionLetter` mutation. */
export type UpdatePetitionLetterPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `PetitionLetter`. */
  fileByFinalFileId: Maybe<File>;
  /** Reads a single `Petition` that is related to this `PetitionLetter`. */
  petitionByPetitionId: Maybe<Petition>;
  /** The `PetitionLetter` that was updated by this mutation. */
  petitionLetter: Maybe<PetitionLetter>;
  /** An edge for our `PetitionLetter`. May be used by Relay 1. */
  petitionLetterEdge: Maybe<PetitionLettersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `PetitionLetter` mutation. */
export type UpdatePetitionLetterPayloadPetitionLetterEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionLettersOrderBy>>;
};

/** All input for the `updatePetitionLetterSignatoryByLetterId` mutation. */
export type UpdatePetitionLetterSignatoryByLetterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  letterId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `PetitionLetterSignatory` being updated. */
  petitionLetterSignatoryPatch: PetitionLetterSignatoryPatch;
};

/** All input for the `updatePetitionLetterSignatory` mutation. */
export type UpdatePetitionLetterSignatoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `PetitionLetterSignatory` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `PetitionLetterSignatory` being updated. */
  petitionLetterSignatoryPatch: PetitionLetterSignatoryPatch;
};

/** The output of our update `PetitionLetterSignatory` mutation. */
export type UpdatePetitionLetterSignatoryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `PetitionLetter` that is related to this `PetitionLetterSignatory`. */
  petitionLetterByLetterId: Maybe<PetitionLetter>;
  /** The `PetitionLetterSignatory` that was updated by this mutation. */
  petitionLetterSignatory: Maybe<PetitionLetterSignatory>;
  /** An edge for our `PetitionLetterSignatory`. May be used by Relay 1. */
  petitionLetterSignatoryEdge: Maybe<PetitionLetterSignatoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `PetitionLetterSignatory`. */
  userBySignatoryId: Maybe<User>;
};

/** The output of our update `PetitionLetterSignatory` mutation. */
export type UpdatePetitionLetterSignatoryPayloadPetitionLetterSignatoryEdgeArgs =
  {
    orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
  };

/** The output of our update `Petition` mutation. */
export type UpdatePetitionPayload = {
  /** Reads a single `CaseType` that is related to this `Petition`. */
  caseTypeByCaseType: Maybe<CaseType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `Petition`. */
  companyByPetitionerId: Maybe<Company>;
  /** The `Petition` that was updated by this mutation. */
  petition: Maybe<Petition>;
  /** An edge for our `Petition`. May be used by Relay 1. */
  petitionEdge: Maybe<PetitionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByAttorneyUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByBeneficiaryId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByCsmUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByOaUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Petition`. */
  userByPetitionerRepId: Maybe<User>;
};

/** The output of our update `Petition` mutation. */
export type UpdatePetitionPayloadPetitionEdgeArgs = {
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

/** All input for the `updateReusableExhibitById` mutation. */
export type UpdateReusableExhibitByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibit` being updated. */
  reusableExhibitPatch: ReusableExhibitPatch;
};

/** All input for the `updateReusableExhibitEntityByReusableExhibitIdAndEntityId` mutation. */
export type UpdateReusableExhibitEntityByReusableExhibitIdAndEntityIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibitEntity` being updated. */
  reusableExhibitEntityPatch: ReusableExhibitEntityPatch;
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** All input for the `updateReusableExhibitEntity` mutation. */
export type UpdateReusableExhibitEntityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibitEntity` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibitEntity` being updated. */
  reusableExhibitEntityPatch: ReusableExhibitEntityPatch;
};

/** The output of our update `ReusableExhibitEntity` mutation. */
export type UpdateReusableExhibitEntityPayload = {
  /** Reads a single `AdditionalEntity` that is related to this `ReusableExhibitEntity`. */
  additionalEntityByEntityId: Maybe<AdditionalEntity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitEntity`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitEntity` that was updated by this mutation. */
  reusableExhibitEntity: Maybe<ReusableExhibitEntity>;
  /** An edge for our `ReusableExhibitEntity`. May be used by Relay 1. */
  reusableExhibitEntityEdge: Maybe<ReusableExhibitEntitiesEdge>;
};

/** The output of our update `ReusableExhibitEntity` mutation. */
export type UpdateReusableExhibitEntityPayloadReusableExhibitEntityEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitEntitiesOrderBy>>;
};

/** All input for the `updateReusableExhibitFileByReusableExhibitIdAndFileId` mutation. */
export type UpdateReusableExhibitFileByReusableExhibitIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibitFile` being updated. */
  reusableExhibitFilePatch: ReusableExhibitFilePatch;
  reusableExhibitId: Scalars["BigInt"]["input"];
};

/** All input for the `updateReusableExhibitFile` mutation. */
export type UpdateReusableExhibitFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibitFile` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibitFile` being updated. */
  reusableExhibitFilePatch: ReusableExhibitFilePatch;
};

/** The output of our update `ReusableExhibitFile` mutation. */
export type UpdateReusableExhibitFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `ReusableExhibitFile`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibitFile`. */
  reusableExhibitByReusableExhibitId: Maybe<ReusableExhibit>;
  /** The `ReusableExhibitFile` that was updated by this mutation. */
  reusableExhibitFile: Maybe<ReusableExhibitFile>;
  /** An edge for our `ReusableExhibitFile`. May be used by Relay 1. */
  reusableExhibitFileEdge: Maybe<ReusableExhibitFilesEdge>;
};

/** The output of our update `ReusableExhibitFile` mutation. */
export type UpdateReusableExhibitFilePayloadReusableExhibitFileEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitFilesOrderBy>>;
};

/** All input for the `updateReusableExhibit` mutation. */
export type UpdateReusableExhibitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReusableExhibit` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `ReusableExhibit` being updated. */
  reusableExhibitPatch: ReusableExhibitPatch;
};

/** The output of our update `ReusableExhibit` mutation. */
export type UpdateReusableExhibitPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExhibitType` that is related to this `ReusableExhibit`. */
  exhibitTypeByExhibitType: Maybe<ExhibitType>;
  /** Reads a single `ModuleType` that is related to this `ReusableExhibit`. */
  moduleTypeByModuleType: Maybe<ModuleType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ReusableExhibit` that was updated by this mutation. */
  reusableExhibit: Maybe<ReusableExhibit>;
  /** Reads a single `ReusableExhibit` that is related to this `ReusableExhibit`. */
  reusableExhibitByParentExhibitId: Maybe<ReusableExhibit>;
  /** An edge for our `ReusableExhibit`. May be used by Relay 1. */
  reusableExhibitEdge: Maybe<ReusableExhibitsEdge>;
};

/** The output of our update `ReusableExhibit` mutation. */
export type UpdateReusableExhibitPayloadReusableExhibitEdgeArgs = {
  orderBy?: InputMaybe<Array<ReusableExhibitsOrderBy>>;
};

/** All input for the `updateTaskQueueById` mutation. */
export type UpdateTaskQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TaskQueue` being updated. */
  taskQueuePatch: TaskQueuePatch;
};

/** All input for the `updateTaskQueueByIdempotencyKey` mutation. */
export type UpdateTaskQueueByIdempotencyKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  idempotencyKey: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `TaskQueue` being updated. */
  taskQueuePatch: TaskQueuePatch;
};

/** All input for the `updateTaskQueue` mutation. */
export type UpdateTaskQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `TaskQueue` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `TaskQueue` being updated. */
  taskQueuePatch: TaskQueuePatch;
};

/** The output of our update `TaskQueue` mutation. */
export type UpdateTaskQueuePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskQueue` that was updated by this mutation. */
  taskQueue: Maybe<TaskQueue>;
  /** An edge for our `TaskQueue`. May be used by Relay 1. */
  taskQueueEdge: Maybe<TaskQueuesEdge>;
};

/** The output of our update `TaskQueue` mutation. */
export type UpdateTaskQueuePayloadTaskQueueEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskQueuesOrderBy>>;
};

/** All input for the `updateUserByAtRecordId` mutation. */
export type UpdateUserByAtRecordIdInput = {
  atRecordId: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** All input for the `updateUserById` mutation. */
export type UpdateUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** All input for the `updateUserDatumById` mutation. */
export type UpdateUserDatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `UserDatum` being updated. */
  userDatumPatch: UserDatumPatch;
};

/** All input for the `updateUserDatum` mutation. */
export type UpdateUserDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserDatum` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `UserDatum` being updated. */
  userDatumPatch: UserDatumPatch;
};

/** The output of our update `UserDatum` mutation. */
export type UpdateUserDatumPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `CompanyOffice` that is related to this `UserDatum`. */
  companyOfficeByOfficeId: Maybe<CompanyOffice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDatum`. */
  userById: Maybe<User>;
  /** The `UserDatum` that was updated by this mutation. */
  userDatum: Maybe<UserDatum>;
  /** An edge for our `UserDatum`. May be used by Relay 1. */
  userDatumEdge: Maybe<UserDataEdge>;
};

/** The output of our update `UserDatum` mutation. */
export type UpdateUserDatumPayloadUserDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

/** All input for the `updateUserDocumentByUserIdAndFileId` mutation. */
export type UpdateUserDocumentByUserIdAndFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `UserDocument` being updated. */
  userDocumentPatch: UserDocumentPatch;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `updateUserDocument` mutation. */
export type UpdateUserDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserDocument` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `UserDocument` being updated. */
  userDocumentPatch: UserDocumentPatch;
};

/** The output of our update `UserDocument` mutation. */
export type UpdateUserDocumentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `UserDocument`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserDocument`. */
  userByUserId: Maybe<User>;
  /** The `UserDocument` that was updated by this mutation. */
  userDocument: Maybe<UserDocument>;
  /** An edge for our `UserDocument`. May be used by Relay 1. */
  userDocumentEdge: Maybe<UserDocumentsEdge>;
};

/** The output of our update `UserDocument` mutation. */
export type UpdateUserDocumentPayloadUserDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

/** All input for the `updateUserEmploymentById` mutation. */
export type UpdateUserEmploymentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `UserEmployment` being updated. */
  userEmploymentPatch: UserEmploymentPatch;
};

/** All input for the `updateUserEmploymentByUserIdAndCompanyId` mutation. */
export type UpdateUserEmploymentByUserIdAndCompanyIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `UserEmployment` being updated. */
  userEmploymentPatch: UserEmploymentPatch;
  userId: Scalars["BigInt"]["input"];
};

/** All input for the `updateUserEmployment` mutation. */
export type UpdateUserEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `UserEmployment` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `UserEmployment` being updated. */
  userEmploymentPatch: UserEmploymentPatch;
};

/** The output of our update `UserEmployment` mutation. */
export type UpdateUserEmploymentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `UserEmployment`. */
  companyByCompanyId: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmployment`. */
  userByUserId: Maybe<User>;
  /** The `UserEmployment` that was updated by this mutation. */
  userEmployment: Maybe<UserEmployment>;
  /** An edge for our `UserEmployment`. May be used by Relay 1. */
  userEmploymentEdge: Maybe<UserEmploymentsEdge>;
};

/** The output of our update `UserEmployment` mutation. */
export type UpdateUserEmploymentPayloadUserEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `User` being updated. */
  userPatch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateVisaTypeByVisaTypeCode` mutation. */
export type UpdateVisaTypeByVisaTypeCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  visaTypeCode: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `VisaType` being updated. */
  visaTypePatch: VisaTypePatch;
};

/** All input for the `updateVisaType` mutation. */
export type UpdateVisaTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `VisaType` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `VisaType` being updated. */
  visaTypePatch: VisaTypePatch;
};

/** The output of our update `VisaType` mutation. */
export type UpdateVisaTypePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `VisaType` that was updated by this mutation. */
  visaType: Maybe<VisaType>;
  /** An edge for our `VisaType`. May be used by Relay 1. */
  visaTypeEdge: Maybe<VisaTypesEdge>;
};

/** The output of our update `VisaType` mutation. */
export type UpdateVisaTypePayloadVisaTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<VisaTypesOrderBy>>;
};

export type User = Node & {
  /** Reads and enables pagination through a set of `AdditionalEntity`. */
  additionalEntitiesByUserId: AdditionalEntitiesConnection;
  /** Reads a single `Admin` that is related to this `User`. */
  adminByUserId: Maybe<Admin>;
  /**
   * Reads and enables pagination through a set of `Admin`.
   * @deprecated Please use adminByUserId instead
   */
  adminsByUserId: AdminsConnection;
  archetype: Maybe<Scalars["String"]["output"]>;
  atRecordId: Maybe<Scalars["String"]["output"]>;
  auth0Sub: Maybe<Scalars["String"]["output"]>;
  bio: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `CaseStrategy`. */
  caseStrategiesByUserId: CaseStrategiesConnection;
  /** Reads and enables pagination through a set of `CompanyMember`. */
  companyMembersByUserId: CompanyMembersConnection;
  /** Reads and enables pagination through a set of `CompiledPetition`. */
  compiledPetitionsByAttorneyUserId: CompiledPetitionsConnection;
  /** Reads and enables pagination through a set of `CompiledPetition`. */
  compiledPetitionsByCreatorId: CompiledPetitionsConnection;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `CustomerCommsLog`. */
  customerCommsLogsByCustomerUserId: CustomerCommsLogsConnection;
  email: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `File`. */
  filesByOwnerId: FilesConnection;
  fullName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `I94Entry`. */
  i94EntriesByUserId: I94EntriesConnection;
  id: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `ImmigrationDocument`. */
  immigrationDocumentsByOwnerId: ImmigrationDocumentsConnection;
  /** Reads and enables pagination through a set of `IndividualVisaHistory`. */
  individualVisaHistoriesByUserId: IndividualVisaHistoriesConnection;
  isAttorney: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `Note`. */
  notesBySenderId: NotesConnection;
  /** Reads and enables pagination through a set of `Note`. */
  notesByUserId: NotesConnection;
  /** Reads and enables pagination through a set of `PetitionLetterSignatory`. */
  petitionLetterSignatoriesBySignatoryId: PetitionLetterSignatoriesConnection;
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByAttorneyUserId: PetitionsConnection;
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByBeneficiaryId: PetitionsConnection;
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByCsmUserId: PetitionsConnection;
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByOaUserId: PetitionsConnection;
  /** Reads and enables pagination through a set of `Petition`. */
  petitionsByPetitionerRepId: PetitionsConnection;
  portalOnboarded: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /**
   * Reads and enables pagination through a set of `UserDatum`.
   * @deprecated Please use userDatumById instead
   */
  userDataById: UserDataConnection;
  /** Reads a single `UserDatum` that is related to this `User`. */
  userDatumById: Maybe<UserDatum>;
  /** Reads and enables pagination through a set of `UserDocument`. */
  userDocumentsByUserId: UserDocumentsConnection;
  /** Reads and enables pagination through a set of `UserEmployment`. */
  userEmploymentsByUserId: UserEmploymentsConnection;
};

export type UserAdditionalEntitiesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AdditionalEntityCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdditionalEntitiesOrderBy>>;
};

export type UserAdminsByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<AdminCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdminsOrderBy>>;
};

export type UserCaseStrategiesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CaseStrategyCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CaseStrategiesOrderBy>>;
};

export type UserCompanyMembersByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompanyMemberCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompanyMembersOrderBy>>;
};

export type UserCompiledPetitionsByAttorneyUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompiledPetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

export type UserCompiledPetitionsByCreatorIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CompiledPetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompiledPetitionsOrderBy>>;
};

export type UserCustomerCommsLogsByCustomerUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<CustomerCommsLogCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CustomerCommsLogsOrderBy>>;
};

export type UserFilesByOwnerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<FileCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type UserI94EntriesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<I94EntryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<I94EntriesOrderBy>>;
};

export type UserImmigrationDocumentsByOwnerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<ImmigrationDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ImmigrationDocumentsOrderBy>>;
};

export type UserIndividualVisaHistoriesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<IndividualVisaHistoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<IndividualVisaHistoriesOrderBy>>;
};

export type UserNotesBySenderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<NoteCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

export type UserNotesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<NoteCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

export type UserPetitionLetterSignatoriesBySignatoryIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionLetterSignatoryCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionLetterSignatoriesOrderBy>>;
};

export type UserPetitionsByAttorneyUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type UserPetitionsByBeneficiaryIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type UserPetitionsByCsmUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type UserPetitionsByOaUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type UserPetitionsByPetitionerRepIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<PetitionCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PetitionsOrderBy>>;
};

export type UserUserDataByIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDatumCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDataOrderBy>>;
};

export type UserUserDocumentsByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserDocumentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserDocumentsOrderBy>>;
};

export type UserUserEmploymentsByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]["input"]>;
  before: InputMaybe<Scalars["Cursor"]["input"]>;
  condition: InputMaybe<UserEmploymentCondition>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserEmploymentsOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `archetype` field. */
  archetype: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `atRecordId` field. */
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `auth0Sub` field. */
  auth0Sub: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `bio` field. */
  bio: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `email` field. */
  email: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `isAttorney` field. */
  isAttorney: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `portalOnboarded` field. */
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `UserDatum` values. */
export type UserDataConnection = {
  /** A list of edges which contains the `UserDatum` and cursor to aid in pagination. */
  edges: Array<UserDataEdge>;
  /** A list of `UserDatum` objects. */
  nodes: Array<Maybe<UserDatum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserDatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UserDatum` edge in the connection. */
export type UserDataEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserDatum` at the end of the edge. */
  node: Maybe<UserDatum>;
};

/** Methods to use when ordering `UserDatum`. */
export enum UserDataOrderBy {
  AddressesAsc = "ADDRESSES_ASC",
  AddressesDesc = "ADDRESSES_DESC",
  AttorneyStateBarNumberAsc = "ATTORNEY_STATE_BAR_NUMBER_ASC",
  AttorneyStateBarNumberDesc = "ATTORNEY_STATE_BAR_NUMBER_DESC",
  CityBirthAsc = "CITY_BIRTH_ASC",
  CityBirthDesc = "CITY_BIRTH_DESC",
  CityForeignAddressAsc = "CITY_FOREIGN_ADDRESS_ASC",
  CityForeignAddressDesc = "CITY_FOREIGN_ADDRESS_DESC",
  CityOfIssuePassportOcrAsc = "CITY_OF_ISSUE_PASSPORT_OCR_ASC",
  CityOfIssuePassportOcrDesc = "CITY_OF_ISSUE_PASSPORT_OCR_DESC",
  CityUsResidentialAddressAsc = "CITY_US_RESIDENTIAL_ADDRESS_ASC",
  CityUsResidentialAddressDesc = "CITY_US_RESIDENTIAL_ADDRESS_DESC",
  CountryBirthAsc = "COUNTRY_BIRTH_ASC",
  CountryBirthDesc = "COUNTRY_BIRTH_DESC",
  CountryForeignAddressAsc = "COUNTRY_FOREIGN_ADDRESS_ASC",
  CountryForeignAddressDesc = "COUNTRY_FOREIGN_ADDRESS_DESC",
  CountryOfIssuePassportOcrAsc = "COUNTRY_OF_ISSUE_PASSPORT_OCR_ASC",
  CountryOfIssuePassportOcrDesc = "COUNTRY_OF_ISSUE_PASSPORT_OCR_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DateOfBirthAsc = "DATE_OF_BIRTH_ASC",
  DateOfBirthDesc = "DATE_OF_BIRTH_DESC",
  DateOfIssuePassportOcrAsc = "DATE_OF_ISSUE_PASSPORT_OCR_ASC",
  DateOfIssuePassportOcrDesc = "DATE_OF_ISSUE_PASSPORT_OCR_DESC",
  DaytimePhoneNumberAsc = "DAYTIME_PHONE_NUMBER_ASC",
  DaytimePhoneNumberDesc = "DAYTIME_PHONE_NUMBER_DESC",
  EquityAsc = "EQUITY_ASC",
  EquityDesc = "EQUITY_DESC",
  ExpirationDatePassportOcrAsc = "EXPIRATION_DATE_PASSPORT_OCR_ASC",
  ExpirationDatePassportOcrDesc = "EXPIRATION_DATE_PASSPORT_OCR_DESC",
  FaxNumberAsc = "FAX_NUMBER_ASC",
  FaxNumberDesc = "FAX_NUMBER_DESC",
  FilingMethodAsc = "FILING_METHOD_ASC",
  FilingMethodDesc = "FILING_METHOD_DESC",
  GenderPassportOcrAsc = "GENDER_PASSPORT_OCR_ASC",
  GenderPassportOcrDesc = "GENDER_PASSPORT_OCR_DESC",
  GithubUrlAsc = "GITHUB_URL_ASC",
  GithubUrlDesc = "GITHUB_URL_DESC",
  GivenNamePassportOcrAsc = "GIVEN_NAME_PASSPORT_OCR_ASC",
  GivenNamePassportOcrDesc = "GIVEN_NAME_PASSPORT_OCR_DESC",
  GoogleScholarUrlAsc = "GOOGLE_SCHOLAR_URL_ASC",
  GoogleScholarUrlDesc = "GOOGLE_SCHOLAR_URL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImminentTravelAsc = "IMMINENT_TRAVEL_ASC",
  ImminentTravelDesc = "IMMINENT_TRAVEL_DESC",
  LicensingAuthorityAsc = "LICENSING_AUTHORITY_ASC",
  LicensingAuthorityDesc = "LICENSING_AUTHORITY_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  MiddleNamePassportOcrAsc = "MIDDLE_NAME_PASSPORT_OCR_ASC",
  MiddleNamePassportOcrDesc = "MIDDLE_NAME_PASSPORT_OCR_DESC",
  MobilePhoneNumberAsc = "MOBILE_PHONE_NUMBER_ASC",
  MobilePhoneNumberDesc = "MOBILE_PHONE_NUMBER_DESC",
  NationalityAsc = "NATIONALITY_ASC",
  NationalityDesc = "NATIONALITY_DESC",
  Natural = "NATURAL",
  OfficeIdAsc = "OFFICE_ID_ASC",
  OfficeIdDesc = "OFFICE_ID_DESC",
  OtherNamesAsc = "OTHER_NAMES_ASC",
  OtherNamesDesc = "OTHER_NAMES_DESC",
  PassportNumberPassportOcrAsc = "PASSPORT_NUMBER_PASSPORT_OCR_ASC",
  PassportNumberPassportOcrDesc = "PASSPORT_NUMBER_PASSPORT_OCR_DESC",
  PersonalUrlAsc = "PERSONAL_URL_ASC",
  PersonalUrlDesc = "PERSONAL_URL_DESC",
  PhoneNumberAsc = "PHONE_NUMBER_ASC",
  PhoneNumberDesc = "PHONE_NUMBER_DESC",
  PlaceOfBirthPassportOcrAsc = "PLACE_OF_BIRTH_PASSPORT_OCR_ASC",
  PlaceOfBirthPassportOcrDesc = "PLACE_OF_BIRTH_PASSPORT_OCR_DESC",
  PostalCodeForeignAddressAsc = "POSTAL_CODE_FOREIGN_ADDRESS_ASC",
  PostalCodeForeignAddressDesc = "POSTAL_CODE_FOREIGN_ADDRESS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProvinceBirthAsc = "PROVINCE_BIRTH_ASC",
  ProvinceBirthDesc = "PROVINCE_BIRTH_DESC",
  ProvinceForeignAddressAsc = "PROVINCE_FOREIGN_ADDRESS_ASC",
  ProvinceForeignAddressDesc = "PROVINCE_FOREIGN_ADDRESS_DESC",
  RoleAsc = "ROLE_ASC",
  RoleDesc = "ROLE_DESC",
  SalaryAsc = "SALARY_ASC",
  SalaryDesc = "SALARY_DESC",
  SignatureFileKeyAsc = "SIGNATURE_FILE_KEY_ASC",
  SignatureFileKeyDesc = "SIGNATURE_FILE_KEY_DESC",
  SignatureImageAsc = "SIGNATURE_IMAGE_ASC",
  SignatureImageDesc = "SIGNATURE_IMAGE_DESC",
  StateForeignAddressAsc = "STATE_FOREIGN_ADDRESS_ASC",
  StateForeignAddressDesc = "STATE_FOREIGN_ADDRESS_DESC",
  StateUsResidentialAddressAsc = "STATE_US_RESIDENTIAL_ADDRESS_ASC",
  StateUsResidentialAddressDesc = "STATE_US_RESIDENTIAL_ADDRESS_DESC",
  StreetNumberStreetNameForeignAddressAsc = "STREET_NUMBER_STREET_NAME_FOREIGN_ADDRESS_ASC",
  StreetNumberStreetNameForeignAddressDesc = "STREET_NUMBER_STREET_NAME_FOREIGN_ADDRESS_DESC",
  StreetNumberStreetNameUsResidentialAddressAsc = "STREET_NUMBER_STREET_NAME_US_RESIDENTIAL_ADDRESS_ASC",
  StreetNumberStreetNameUsResidentialAddressDesc = "STREET_NUMBER_STREET_NAME_US_RESIDENTIAL_ADDRESS_DESC",
  SurnamePassportOcrAsc = "SURNAME_PASSPORT_OCR_ASC",
  SurnamePassportOcrDesc = "SURNAME_PASSPORT_OCR_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UsRoleTitleAsc = "US_ROLE_TITLE_ASC",
  UsRoleTitleDesc = "US_ROLE_TITLE_DESC",
  VisaPathwayAsc = "VISA_PATHWAY_ASC",
  VisaPathwayDesc = "VISA_PATHWAY_DESC",
  ZipCodeUsResidentialAddressAsc = "ZIP_CODE_US_RESIDENTIAL_ADDRESS_ASC",
  ZipCodeUsResidentialAddressDesc = "ZIP_CODE_US_RESIDENTIAL_ADDRESS_DESC",
}

export type UserDatum = Node & {
  addresses: Maybe<Scalars["JSON"]["output"]>;
  attorneyStateBarNumber: Maybe<Scalars["String"]["output"]>;
  cityBirth: Maybe<Scalars["String"]["output"]>;
  cityForeignAddress: Maybe<Scalars["String"]["output"]>;
  cityOfIssuePassportOcr: Maybe<Scalars["String"]["output"]>;
  cityUsResidentialAddress: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `CompanyOffice` that is related to this `UserDatum`. */
  companyOfficeByOfficeId: Maybe<CompanyOffice>;
  countryBirth: Maybe<Scalars["String"]["output"]>;
  countryForeignAddress: Maybe<Scalars["String"]["output"]>;
  countryOfIssuePassportOcr: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  dateOfBirth: Maybe<Scalars["Date"]["output"]>;
  dateOfIssuePassportOcr: Maybe<Scalars["Date"]["output"]>;
  daytimePhoneNumber: Maybe<Scalars["String"]["output"]>;
  equity: Maybe<Scalars["String"]["output"]>;
  expirationDatePassportOcr: Maybe<Scalars["Date"]["output"]>;
  faxNumber: Maybe<Scalars["String"]["output"]>;
  filingMethod: Maybe<Scalars["String"]["output"]>;
  genderPassportOcr: Maybe<Scalars["String"]["output"]>;
  githubUrl: Maybe<Scalars["String"]["output"]>;
  givenNamePassportOcr: Maybe<Scalars["String"]["output"]>;
  googleScholarUrl: Maybe<Scalars["String"]["output"]>;
  id: Scalars["BigInt"]["output"];
  imminentTravel: Maybe<Scalars["Boolean"]["output"]>;
  licensingAuthority: Maybe<Scalars["String"]["output"]>;
  linkedinUrl: Maybe<Scalars["String"]["output"]>;
  middleNamePassportOcr: Maybe<Scalars["String"]["output"]>;
  mobilePhoneNumber: Maybe<Scalars["String"]["output"]>;
  nationality: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  officeId: Maybe<Scalars["Int"]["output"]>;
  otherNames: Maybe<Scalars["JSON"]["output"]>;
  passportNumberPassportOcr: Maybe<Scalars["String"]["output"]>;
  personalUrl: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  placeOfBirthPassportOcr: Maybe<Scalars["String"]["output"]>;
  postalCodeForeignAddress: Maybe<Scalars["String"]["output"]>;
  provinceBirth: Maybe<Scalars["String"]["output"]>;
  provinceForeignAddress: Maybe<Scalars["String"]["output"]>;
  role: Maybe<Scalars["String"]["output"]>;
  salary: Maybe<Scalars["String"]["output"]>;
  signatureFileKey: Maybe<Scalars["String"]["output"]>;
  signatureImage: Maybe<Scalars["String"]["output"]>;
  stateForeignAddress: Maybe<Scalars["String"]["output"]>;
  stateUsResidentialAddress: Maybe<Scalars["String"]["output"]>;
  streetNumberStreetNameForeignAddress: Maybe<Scalars["String"]["output"]>;
  streetNumberStreetNameUsResidentialAddress: Maybe<
    Scalars["String"]["output"]
  >;
  surnamePassportOcr: Maybe<Scalars["String"]["output"]>;
  twitterUrl: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  usRoleTitle: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `User` that is related to this `UserDatum`. */
  userById: Maybe<User>;
  visaPathway: Maybe<Scalars["String"]["output"]>;
  zipCodeUsResidentialAddress: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `UserDatum` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserDatumCondition = {
  /** Checks for equality with the object’s `addresses` field. */
  addresses: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `attorneyStateBarNumber` field. */
  attorneyStateBarNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cityBirth` field. */
  cityBirth: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cityForeignAddress` field. */
  cityForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cityOfIssuePassportOcr` field. */
  cityOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cityUsResidentialAddress` field. */
  cityUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `countryBirth` field. */
  countryBirth: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `countryForeignAddress` field. */
  countryForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `countryOfIssuePassportOcr` field. */
  countryOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfBirth` field. */
  dateOfBirth: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `dateOfIssuePassportOcr` field. */
  dateOfIssuePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `daytimePhoneNumber` field. */
  daytimePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `equity` field. */
  equity: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `expirationDatePassportOcr` field. */
  expirationDatePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `faxNumber` field. */
  faxNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `filingMethod` field. */
  filingMethod: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `genderPassportOcr` field. */
  genderPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `githubUrl` field. */
  githubUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `givenNamePassportOcr` field. */
  givenNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `googleScholarUrl` field. */
  googleScholarUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `imminentTravel` field. */
  imminentTravel: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `licensingAuthority` field. */
  licensingAuthority: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `middleNamePassportOcr` field. */
  middleNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mobilePhoneNumber` field. */
  mobilePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `nationality` field. */
  nationality: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `officeId` field. */
  officeId: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `otherNames` field. */
  otherNames: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `passportNumberPassportOcr` field. */
  passportNumberPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `personalUrl` field. */
  personalUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `placeOfBirthPassportOcr` field. */
  placeOfBirthPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `postalCodeForeignAddress` field. */
  postalCodeForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `provinceBirth` field. */
  provinceBirth: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `provinceForeignAddress` field. */
  provinceForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `role` field. */
  role: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `salary` field. */
  salary: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `signatureFileKey` field. */
  signatureFileKey: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `signatureImage` field. */
  signatureImage: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `stateForeignAddress` field. */
  stateForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `stateUsResidentialAddress` field. */
  stateUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `streetNumberStreetNameForeignAddress` field. */
  streetNumberStreetNameForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `streetNumberStreetNameUsResidentialAddress` field. */
  streetNumberStreetNameUsResidentialAddress: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** Checks for equality with the object’s `surnamePassportOcr` field. */
  surnamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `usRoleTitle` field. */
  usRoleTitle: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visaPathway` field. */
  visaPathway: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `zipCodeUsResidentialAddress` field. */
  zipCodeUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `UserDatum` */
export type UserDatumInput = {
  addresses: InputMaybe<Scalars["JSON"]["input"]>;
  attorneyStateBarNumber: InputMaybe<Scalars["String"]["input"]>;
  cityBirth: InputMaybe<Scalars["String"]["input"]>;
  cityForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  cityOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  cityUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  countryBirth: InputMaybe<Scalars["String"]["input"]>;
  countryForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  countryOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfBirth: InputMaybe<Scalars["Date"]["input"]>;
  dateOfIssuePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  daytimePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  equity: InputMaybe<Scalars["String"]["input"]>;
  expirationDatePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  faxNumber: InputMaybe<Scalars["String"]["input"]>;
  filingMethod: InputMaybe<Scalars["String"]["input"]>;
  genderPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  githubUrl: InputMaybe<Scalars["String"]["input"]>;
  givenNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  googleScholarUrl: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  imminentTravel: InputMaybe<Scalars["Boolean"]["input"]>;
  licensingAuthority: InputMaybe<Scalars["String"]["input"]>;
  linkedinUrl: InputMaybe<Scalars["String"]["input"]>;
  middleNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  mobilePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  nationality: InputMaybe<Scalars["String"]["input"]>;
  officeId: InputMaybe<Scalars["Int"]["input"]>;
  otherNames: InputMaybe<Scalars["JSON"]["input"]>;
  passportNumberPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  personalUrl: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  placeOfBirthPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  postalCodeForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  provinceBirth: InputMaybe<Scalars["String"]["input"]>;
  provinceForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  role: InputMaybe<Scalars["String"]["input"]>;
  salary: InputMaybe<Scalars["String"]["input"]>;
  signatureFileKey: InputMaybe<Scalars["String"]["input"]>;
  signatureImage: InputMaybe<Scalars["String"]["input"]>;
  stateForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  stateUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameUsResidentialAddress: InputMaybe<
    Scalars["String"]["input"]
  >;
  surnamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  twitterUrl: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  usRoleTitle: InputMaybe<Scalars["String"]["input"]>;
  visaPathway: InputMaybe<Scalars["String"]["input"]>;
  zipCodeUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents an update to a `UserDatum`. Fields that are set will be updated. */
export type UserDatumPatch = {
  addresses: InputMaybe<Scalars["JSON"]["input"]>;
  attorneyStateBarNumber: InputMaybe<Scalars["String"]["input"]>;
  cityBirth: InputMaybe<Scalars["String"]["input"]>;
  cityForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  cityOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  cityUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  countryBirth: InputMaybe<Scalars["String"]["input"]>;
  countryForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  countryOfIssuePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfBirth: InputMaybe<Scalars["Date"]["input"]>;
  dateOfIssuePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  daytimePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  equity: InputMaybe<Scalars["String"]["input"]>;
  expirationDatePassportOcr: InputMaybe<Scalars["Date"]["input"]>;
  faxNumber: InputMaybe<Scalars["String"]["input"]>;
  filingMethod: InputMaybe<Scalars["String"]["input"]>;
  genderPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  githubUrl: InputMaybe<Scalars["String"]["input"]>;
  givenNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  googleScholarUrl: InputMaybe<Scalars["String"]["input"]>;
  id: InputMaybe<Scalars["BigInt"]["input"]>;
  imminentTravel: InputMaybe<Scalars["Boolean"]["input"]>;
  licensingAuthority: InputMaybe<Scalars["String"]["input"]>;
  linkedinUrl: InputMaybe<Scalars["String"]["input"]>;
  middleNamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  mobilePhoneNumber: InputMaybe<Scalars["String"]["input"]>;
  nationality: InputMaybe<Scalars["String"]["input"]>;
  officeId: InputMaybe<Scalars["Int"]["input"]>;
  otherNames: InputMaybe<Scalars["JSON"]["input"]>;
  passportNumberPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  personalUrl: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  placeOfBirthPassportOcr: InputMaybe<Scalars["String"]["input"]>;
  postalCodeForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  provinceBirth: InputMaybe<Scalars["String"]["input"]>;
  provinceForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  role: InputMaybe<Scalars["String"]["input"]>;
  salary: InputMaybe<Scalars["String"]["input"]>;
  signatureFileKey: InputMaybe<Scalars["String"]["input"]>;
  signatureImage: InputMaybe<Scalars["String"]["input"]>;
  stateForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  stateUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameForeignAddress: InputMaybe<Scalars["String"]["input"]>;
  streetNumberStreetNameUsResidentialAddress: InputMaybe<
    Scalars["String"]["input"]
  >;
  surnamePassportOcr: InputMaybe<Scalars["String"]["input"]>;
  twitterUrl: InputMaybe<Scalars["String"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  usRoleTitle: InputMaybe<Scalars["String"]["input"]>;
  visaPathway: InputMaybe<Scalars["String"]["input"]>;
  zipCodeUsResidentialAddress: InputMaybe<Scalars["String"]["input"]>;
};

export type UserDocument = Node & {
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  documentType: IndividualDocumentType;
  /** Reads a single `File` that is related to this `UserDocument`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `User` that is related to this `UserDocument`. */
  userByUserId: Maybe<User>;
  userId: Scalars["BigInt"]["output"];
};

/**
 * A condition to be used against `UserDocument` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserDocumentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `documentType` field. */
  documentType: InputMaybe<IndividualDocumentType>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `UserDocument` */
export type UserDocumentInput = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  documentType: IndividualDocumentType;
  fileId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
};

/** Represents an update to a `UserDocument`. Fields that are set will be updated. */
export type UserDocumentPatch = {
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  documentType: InputMaybe<IndividualDocumentType>;
  fileId: InputMaybe<Scalars["BigInt"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `UserDocument` values. */
export type UserDocumentsConnection = {
  /** A list of edges which contains the `UserDocument` and cursor to aid in pagination. */
  edges: Array<UserDocumentsEdge>;
  /** A list of `UserDocument` objects. */
  nodes: Array<Maybe<UserDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserDocument` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UserDocument` edge in the connection. */
export type UserDocumentsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserDocument` at the end of the edge. */
  node: Maybe<UserDocument>;
};

/** Methods to use when ordering `UserDocument`. */
export enum UserDocumentsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DocumentTypeAsc = "DOCUMENT_TYPE_ASC",
  DocumentTypeDesc = "DOCUMENT_TYPE_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type UserEmployment = Node & {
  city: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Company` that is related to this `UserEmployment`. */
  companyByCompanyId: Maybe<Company>;
  companyId: Scalars["BigInt"]["output"];
  companyName: Scalars["String"]["output"];
  country: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["Datetime"]["output"]>;
  endDate: Maybe<Scalars["Date"]["output"]>;
  hoursPerWeek: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isCurrent: Maybe<Scalars["Boolean"]["output"]>;
  isFullTime: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  otherCompensation: Maybe<Scalars["JSON"]["output"]>;
  position: Maybe<Scalars["String"]["output"]>;
  salary: Maybe<Scalars["Float"]["output"]>;
  salaryFrequency: Maybe<Scalars["String"]["output"]>;
  startDate: Maybe<Scalars["Date"]["output"]>;
  updatedAt: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `User` that is related to this `UserEmployment`. */
  userByUserId: Maybe<User>;
  userId: Maybe<Scalars["BigInt"]["output"]>;
};

/**
 * A condition to be used against `UserEmployment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserEmploymentCondition = {
  /** Checks for equality with the object’s `city` field. */
  city: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `country` field. */
  country: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `hoursPerWeek` field. */
  hoursPerWeek: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isCurrent` field. */
  isCurrent: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isFullTime` field. */
  isFullTime: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `otherCompensation` field. */
  otherCompensation: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `salary` field. */
  salary: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `salaryFrequency` field. */
  salaryFrequency: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** An input for mutations affecting `UserEmployment` */
export type UserEmploymentInput = {
  city: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["BigInt"]["input"];
  companyName: Scalars["String"]["input"];
  country: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  endDate: InputMaybe<Scalars["Date"]["input"]>;
  hoursPerWeek: InputMaybe<Scalars["Int"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  isCurrent: InputMaybe<Scalars["Boolean"]["input"]>;
  isFullTime: InputMaybe<Scalars["Boolean"]["input"]>;
  otherCompensation: InputMaybe<Scalars["JSON"]["input"]>;
  position: InputMaybe<Scalars["String"]["input"]>;
  salary: InputMaybe<Scalars["Float"]["input"]>;
  salaryFrequency: InputMaybe<Scalars["String"]["input"]>;
  startDate: InputMaybe<Scalars["Date"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Represents an update to a `UserEmployment`. Fields that are set will be updated. */
export type UserEmploymentPatch = {
  city: InputMaybe<Scalars["String"]["input"]>;
  companyId: InputMaybe<Scalars["BigInt"]["input"]>;
  companyName: InputMaybe<Scalars["String"]["input"]>;
  country: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  endDate: InputMaybe<Scalars["Date"]["input"]>;
  hoursPerWeek: InputMaybe<Scalars["Int"]["input"]>;
  id: InputMaybe<Scalars["Int"]["input"]>;
  isCurrent: InputMaybe<Scalars["Boolean"]["input"]>;
  isFullTime: InputMaybe<Scalars["Boolean"]["input"]>;
  otherCompensation: InputMaybe<Scalars["JSON"]["input"]>;
  position: InputMaybe<Scalars["String"]["input"]>;
  salary: InputMaybe<Scalars["Float"]["input"]>;
  salaryFrequency: InputMaybe<Scalars["String"]["input"]>;
  startDate: InputMaybe<Scalars["Date"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A connection to a list of `UserEmployment` values. */
export type UserEmploymentsConnection = {
  /** A list of edges which contains the `UserEmployment` and cursor to aid in pagination. */
  edges: Array<UserEmploymentsEdge>;
  /** A list of `UserEmployment` objects. */
  nodes: Array<Maybe<UserEmployment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserEmployment` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UserEmployment` edge in the connection. */
export type UserEmploymentsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserEmployment` at the end of the edge. */
  node: Maybe<UserEmployment>;
};

/** Methods to use when ordering `UserEmployment`. */
export enum UserEmploymentsOrderBy {
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EndDateAsc = "END_DATE_ASC",
  EndDateDesc = "END_DATE_DESC",
  HoursPerWeekAsc = "HOURS_PER_WEEK_ASC",
  HoursPerWeekDesc = "HOURS_PER_WEEK_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsCurrentAsc = "IS_CURRENT_ASC",
  IsCurrentDesc = "IS_CURRENT_DESC",
  IsFullTimeAsc = "IS_FULL_TIME_ASC",
  IsFullTimeDesc = "IS_FULL_TIME_DESC",
  Natural = "NATURAL",
  OtherCompensationAsc = "OTHER_COMPENSATION_ASC",
  OtherCompensationDesc = "OTHER_COMPENSATION_DESC",
  PositionAsc = "POSITION_ASC",
  PositionDesc = "POSITION_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SalaryAsc = "SALARY_ASC",
  SalaryDesc = "SALARY_DESC",
  SalaryFrequencyAsc = "SALARY_FREQUENCY_ASC",
  SalaryFrequencyDesc = "SALARY_FREQUENCY_DESC",
  StartDateAsc = "START_DATE_ASC",
  StartDateDesc = "START_DATE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** An input for mutations affecting `User` */
export type UserInput = {
  archetype: InputMaybe<Scalars["String"]["input"]>;
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  auth0Sub: InputMaybe<Scalars["String"]["input"]>;
  bio: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  email: InputMaybe<Scalars["String"]["input"]>;
  fullName: Scalars["String"]["input"];
  isAttorney: InputMaybe<Scalars["Boolean"]["input"]>;
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  archetype: InputMaybe<Scalars["String"]["input"]>;
  atRecordId: InputMaybe<Scalars["String"]["input"]>;
  auth0Sub: InputMaybe<Scalars["String"]["input"]>;
  bio: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["Datetime"]["input"]>;
  email: InputMaybe<Scalars["String"]["input"]>;
  fullName: InputMaybe<Scalars["String"]["input"]>;
  isAttorney: InputMaybe<Scalars["Boolean"]["input"]>;
  portalOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  ArchetypeAsc = "ARCHETYPE_ASC",
  ArchetypeDesc = "ARCHETYPE_DESC",
  AtRecordIdAsc = "AT_RECORD_ID_ASC",
  AtRecordIdDesc = "AT_RECORD_ID_DESC",
  Auth0SubAsc = "AUTH0_SUB_ASC",
  Auth0SubDesc = "AUTH0_SUB_DESC",
  BioAsc = "BIO_ASC",
  BioDesc = "BIO_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FullNameAsc = "FULL_NAME_ASC",
  FullNameDesc = "FULL_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsAttorneyAsc = "IS_ATTORNEY_ASC",
  IsAttorneyDesc = "IS_ATTORNEY_DESC",
  Natural = "NATURAL",
  PortalOnboardedAsc = "PORTAL_ONBOARDED_ASC",
  PortalOnboardedDesc = "PORTAL_ONBOARDED_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export enum VisaClassType {
  Eb_1A = "EB_1A",
  Eb_2Niw = "EB_2_NIW",
  H_1B = "H_1B",
  O_1A = "O_1A",
  Tn = "TN",
}

export type VisaType = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  visaCategory: Scalars["String"]["output"];
  visaDescription: Scalars["String"]["output"];
  visaTypeCode: Scalars["String"]["output"];
};

/**
 * A condition to be used against `VisaType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type VisaTypeCondition = {
  /** Checks for equality with the object’s `visaCategory` field. */
  visaCategory: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visaDescription` field. */
  visaDescription: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visaTypeCode` field. */
  visaTypeCode: InputMaybe<Scalars["String"]["input"]>;
};

/** An input for mutations affecting `VisaType` */
export type VisaTypeInput = {
  visaCategory: Scalars["String"]["input"];
  visaDescription: Scalars["String"]["input"];
  visaTypeCode: Scalars["String"]["input"];
};

/** Represents an update to a `VisaType`. Fields that are set will be updated. */
export type VisaTypePatch = {
  visaCategory: InputMaybe<Scalars["String"]["input"]>;
  visaDescription: InputMaybe<Scalars["String"]["input"]>;
  visaTypeCode: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `VisaType` values. */
export type VisaTypesConnection = {
  /** A list of edges which contains the `VisaType` and cursor to aid in pagination. */
  edges: Array<VisaTypesEdge>;
  /** A list of `VisaType` objects. */
  nodes: Array<Maybe<VisaType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VisaType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `VisaType` edge in the connection. */
export type VisaTypesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `VisaType` at the end of the edge. */
  node: Maybe<VisaType>;
};

/** Methods to use when ordering `VisaType`. */
export enum VisaTypesOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  VisaCategoryAsc = "VISA_CATEGORY_ASC",
  VisaCategoryDesc = "VISA_CATEGORY_DESC",
  VisaDescriptionAsc = "VISA_DESCRIPTION_ASC",
  VisaDescriptionDesc = "VISA_DESCRIPTION_DESC",
  VisaTypeCodeAsc = "VISA_TYPE_CODE_ASC",
  VisaTypeCodeDesc = "VISA_TYPE_CODE_DESC",
}

export type ExhibitFileSubSubscriptionVariables = Exact<{
  wsId: Scalars["String"]["input"];
  exhibitId: Scalars["String"]["input"];
}>;

export type ExhibitFileSubSubscription = {
  exhibitFilesUpdated: { id: string | null } | null;
};

export type UserTableFragment = {
  id: string;
  auth0Sub: string | null;
  fullName: string;
  portalOnboarded: boolean | null;
  email: string | null;
  petitionsByBeneficiaryId: {
    nodes: Array<{
      id: string;
      visaClass: VisaClassType;
      status: PetitionStatus;
      targetSubmissionDate: string | null;
      companyByPetitionerId: { dbaName: string } | null;
    } | null>;
  };
};

export type UserTableFragmentVariables = Exact<{ [key: string]: never }>;

export type AllContactsQueryVariables = Exact<{ [key: string]: never }>;

export type AllContactsQuery = {
  allUsers: { nodes: Array<{ id: string; fullName: string } | null> } | null;
};

export type AllValidContactsQueryVariables = Exact<{ [key: string]: never }>;

export type AllValidContactsQuery = {
  allUsers: {
    nodes: Array<{
      id: string;
      auth0Sub: string | null;
      fullName: string;
      portalOnboarded: boolean | null;
      email: string | null;
      petitionsByBeneficiaryId: {
        nodes: Array<{
          id: string;
          visaClass: VisaClassType;
          status: PetitionStatus;
          targetSubmissionDate: string | null;
          companyByPetitionerId: { dbaName: string } | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type EditContactBioMutationVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  bio: Scalars["String"]["input"];
}>;

export type EditContactBioMutation = {
  updateUserById: { clientMutationId: string | null } | null;
};

export type EditCompanyBioMutationVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  bio: Scalars["String"]["input"];
}>;

export type EditCompanyBioMutation = {
  updateCompanyById: { clientMutationId: string | null } | null;
};

export type PetitionSelectorQueryVariables = Exact<{ [key: string]: never }>;

export type PetitionSelectorQuery = {
  allPetitions: {
    nodes: Array<{
      id: string;
      visaClass: VisaClassType;
      status: PetitionStatus;
      userByBeneficiaryId: { id: string; fullName: string } | null;
      companyByPetitionerId: { id: string; dbaName: string } | null;
    } | null>;
  } | null;
};

export type BuilderHeaderQueryVariables = Exact<{
  petitionId: Scalars["BigInt"]["input"];
}>;

export type BuilderHeaderQuery = {
  petitionById: {
    id: string;
    visaClass: VisaClassType;
    uscisAddress: string | null;
    userByBeneficiaryId: {
      id: string;
      fullName: string;
      email: string | null;
    } | null;
    companyByPetitionerId: { id: string; dbaName: string } | null;
    modulesByPetitionId: {
      nodes: Array<{
        id: string;
        name: string;
        exhibitsByModuleId: {
          nodes: Array<{ id: string; status: ExhibitStatusType } | null>;
        };
      } | null>;
    };
  } | null;
};

export type CompiledPetitionsByPetitionIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type CompiledPetitionsByPetitionIdQuery = {
  petitionById: {
    compiledPetitionsByPetitionId: {
      nodes: Array<{
        createdAt: any;
        displayName: string | null;
        status: CompilerStatus;
        userByCreatorId: { fullName: string } | null;
        fileByFileId: {
          id: string;
          mimeType: string;
          userByOwnerId: { fullName: string } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type PetitionMetadataByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type PetitionMetadataByIdQuery = {
  petitionById: { metadata: string } | null;
};

export type AllFilesForPetitionByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type AllFilesForPetitionByIdQuery = {
  petitionById: {
    modulesByPetitionId: {
      nodes: Array<{
        exhibitsByModuleId: {
          nodes: Array<{
            name: string;
            id: string;
            exhibitFilesByExhibitId: {
              nodes: Array<{
                fileByFileId: {
                  id: string;
                  name: string;
                  mimeType: string;
                } | null;
              } | null>;
            };
          } | null>;
        };
      } | null>;
    };
    userByBeneficiaryId: {
      immigrationDocumentsByOwnerId: {
        nodes: Array<{
          type: ImmigrationDocumentType;
          fileByFileId: { id: string; name: string; mimeType: string } | null;
        } | null>;
      };
    } | null;
    companyByPetitionerId: {
      companyDocumentsByCompanyId: {
        nodes: Array<{
          type: CompanyDocumentType;
          fileByFileId: { id: string; name: string; mimeType: string } | null;
        } | null>;
      };
    } | null;
    petitionFormsByPetitionId: {
      nodes: Array<{
        type: FormType;
        fileByFileId: { id: string; name: string; mimeType: string } | null;
      } | null>;
    };
    petitionLettersByPetitionId: {
      nodes: Array<{
        name: string;
        fileByFinalFileId: {
          name: string;
          id: string;
          mimeType: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type AllModuleTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllModuleTypesQuery = {
  allModuleTypes: {
    nodes: Array<{
      name: string;
      id: string;
      description: string | null;
      inputs: string;
    } | null>;
  } | null;
};

export type ModuleByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type ModuleByIdQuery = {
  moduleById: {
    id: string;
    name: string;
    description: string | null;
    visible: boolean;
    type: string;
    compilerHideTitleRow: boolean;
    exhibitsByModuleId: { nodes: Array<{ id: string } | null> };
  } | null;
};

export type FileByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type FileByIdQuery = {
  fileById: { id: string; name: string; mimeType: string } | null;
};

export type ExhibitByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type ExhibitByIdQuery = {
  exhibitById: {
    id: string;
    name: string;
    description: string | null;
    orderIndex: number;
    status: ExhibitStatusType;
    ownedBy: ExhibitOwnerType;
    exhibitType: string | null;
    visible: boolean;
    compilerOverrideName: boolean;
    index: number | null;
    metaData: string | null;
    metadata: string | null;
    moduleByModuleId: { id: string; type: string } | null;
    exhibitFilesByExhibitId: {
      nodes: Array<{
        fileId: string;
        orderIndex: number | null;
        fileByFileId: { id: string; name: string; mimeType: string } | null;
      } | null>;
    };
  } | null;
};

export type SubexhibitsByIdQueryVariables = Exact<{
  parentExhibitId: Scalars["BigInt"]["input"];
}>;

export type SubexhibitsByIdQuery = {
  allExhibits: { nodes: Array<{ id: string } | null> } | null;
};

export type CaseStrategyFragmentFragment = {
  id: number;
  nodeId: string;
  userId: string | null;
  userEmail: string | null;
  strategy: string;
  label: string;
  updatedAt: any | null;
};

export type CaseStrategyFragmentFragmentVariables = Exact<{
  [key: string]: never;
}>;

export type UserCaseStrategyQueryVariables = Exact<{
  userEmail: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UserCaseStrategyQuery = {
  allCaseStrategies: {
    nodes: Array<{
      id: number;
      nodeId: string;
      userId: string | null;
      userEmail: string | null;
      strategy: string;
      label: string;
      updatedAt: any | null;
    } | null>;
  } | null;
};

export type AllUserCaseStrategyQueryVariables = Exact<{ [key: string]: never }>;

export type AllUserCaseStrategyQuery = {
  allCaseStrategies: {
    nodes: Array<{
      id: number;
      nodeId: string;
      userId: string | null;
      userEmail: string | null;
      strategy: string;
      label: string;
      updatedAt: any | null;
    } | null>;
  } | null;
};

export type CreateExhibitFileMutationVariables = Exact<{
  input: CreateExhibitFileInput;
}>;

export type CreateExhibitFileMutation = {
  createExhibitFile: { clientMutationId: string | null } | null;
};

export type CreateExhibitMutationVariables = Exact<{
  input: CreateExhibitInput;
}>;

export type CreateExhibitMutation = {
  createExhibit: { clientMutationId: string | null } | null;
};

export type CreateModuleMutationVariables = Exact<{
  input: CreateModuleInput;
}>;

export type CreateModuleMutation = {
  createModule: { clientMutationId: string | null } | null;
};

export type CreatePetitionFormMutationVariables = Exact<{
  input: CreatePetitionFormInput;
}>;

export type CreatePetitionFormMutation = {
  createPetitionForm: { clientMutationId: string | null } | null;
};

export type UpdatePetitionMutationVariables = Exact<{
  input: UpdatePetitionByIdInput;
}>;

export type UpdatePetitionMutation = {
  updatePetitionById: { clientMutationId: string | null } | null;
};

export type UpdateMultipleExhibitFilesMutationVariables = Exact<{
  input: UpdateMultipleExhibitFilesInput;
}>;

export type UpdateMultipleExhibitFilesMutation = {
  updateMultipleExhibitFiles: {
    results: Array<{
      exhibitId: string | null;
      fileId: string | null;
      orderIndex: number | null;
    } | null> | null;
  } | null;
};

export type UpdateMultipleExhibitsMutationVariables = Exact<{
  input: UpdateMultipleExhibitsOrderInput;
}>;

export type UpdateMultipleExhibitsMutation = {
  updateMultipleExhibitsOrder: {
    results: Array<{
      exhibitId: string | null;
      orderIndex: number | null;
    } | null> | null;
  } | null;
};

export type UpdateMultipleModulesMutationVariables = Exact<{
  input: UpdateMultipleModuleOrderInput;
}>;

export type UpdateMultipleModulesMutation = {
  updateMultipleModuleOrder: {
    results: Array<{ moduleId: string | null } | null> | null;
  } | null;
};

export type UpdatePetitionFormMutationVariables = Exact<{
  input: UpdatePetitionFormByIdInput;
}>;

export type UpdatePetitionFormMutation = {
  updatePetitionFormById: { clientMutationId: string | null } | null;
};

export type UnlinkExhibitFiileMutationVariables = Exact<{
  input: DeleteExhibitFileByExhibitIdAndFileIdInput;
}>;

export type UnlinkExhibitFiileMutation = {
  deleteExhibitFileByExhibitIdAndFileId: {
    exhibitFile: { fileId: string } | null;
  } | null;
};

export type DeleteUserDocumentMutationVariables = Exact<{
  input: DeleteUserDocumentByUserIdAndFileIdInput;
}>;

export type DeleteUserDocumentMutation = {
  deleteUserDocumentByUserIdAndFileId: {
    clientMutationId: string | null;
    deletedUserDocumentId: string | null;
  } | null;
};

export type DeleteImmigrationDocumentMutationVariables = Exact<{
  input: DeleteImmigrationDocumentByOwnerIdAndFileIdInput;
}>;

export type DeleteImmigrationDocumentMutation = {
  deleteImmigrationDocumentByOwnerIdAndFileId: {
    clientMutationId: string | null;
    deletedImmigrationDocumentId: string | null;
  } | null;
};

export type DeleteCompanyDocumentMutationVariables = Exact<{
  input: DeleteCompanyDocumentByCompanyIdAndFileIdInput;
}>;

export type DeleteCompanyDocumentMutation = {
  deleteCompanyDocumentByCompanyIdAndFileId: {
    clientMutationId: string | null;
    deletedCompanyDocumentId: string | null;
  } | null;
};

export type DeleteModuleMutationVariables = Exact<{
  input: DeleteModuleByIdInput;
}>;

export type DeleteModuleMutation = {
  deleteModuleById: { clientMutationId: string | null } | null;
};

export type DeleteExhibitMutationVariables = Exact<{
  input: DeleteExhibitByIdInput;
}>;

export type DeleteExhibitMutation = {
  deleteExhibitById: { clientMutationId: string | null } | null;
};

export type DeletePetitionFormMutationVariables = Exact<{
  input: DeletePetitionFormByIdInput;
}>;

export type DeletePetitionFormMutation = {
  deletePetitionFormById: { clientMutationId: string | null } | null;
};

export type PetitionBuilderExhibitFilesSubSubscriptionVariables = Exact<{
  exhibitId: Scalars["String"]["input"];
}>;

export type PetitionBuilderExhibitFilesSubSubscription = {
  exhibitFilesBuilderUpdated: { id: string | null } | null;
};

export type PetitionBuilderExhibitSubSubscriptionVariables = Exact<{
  exhibitId: Scalars["String"]["input"];
}>;

export type PetitionBuilderExhibitSubSubscription = {
  exhibitsBuilderUpdated: { id: string | null } | null;
};

export type PetitionBuilderModuleSubSubscriptionVariables = Exact<{
  moduleId: Scalars["String"]["input"];
}>;

export type PetitionBuilderModuleSubSubscription = {
  moduleBuilderUpdated: { id: string | null } | null;
};

export type PetitionBuilderPetitionSubSubscriptionVariables = Exact<{
  petitionId: Scalars["String"]["input"];
}>;

export type PetitionBuilderPetitionSubSubscription = {
  petitionBuilderPetitionUpdated: { id: string | null } | null;
};

export type PetitionBuilderSubexhibitsSubSubscriptionVariables = Exact<{
  parentExhibitId: Scalars["String"]["input"];
}>;

export type PetitionBuilderSubexhibitsSubSubscription = {
  subexhibitsBuilderUpdated: { id: string | null } | null;
};

export type UpdateModuleByIdMutationVariables = Exact<{
  input: UpdateModuleByIdInput;
}>;

export type UpdateModuleByIdMutation = {
  updateModuleById: {
    clientMutationId: string | null;
    module: { id: string; name: string; description: string | null } | null;
  } | null;
};

export type UpdateExhibitByIdMutationVariables = Exact<{
  input: UpdateExhibitByIdInput;
}>;

export type UpdateExhibitByIdMutation = {
  updateExhibitById: {
    clientMutationId: string | null;
    exhibit: {
      id: string;
      name: string;
      description: string | null;
      status: ExhibitStatusType;
      metaData: string | null;
      metadata: string | null;
    } | null;
  } | null;
};

export type UpdateFileByIdMutationVariables = Exact<{
  input: UpdateFileByIdInput;
}>;

export type UpdateFileByIdMutation = {
  updateFileById: { file: { id: string; name: string } | null } | null;
};

export type CreateCaseStrategyMutationVariables = Exact<{
  input: CreateCaseStrategyInput;
}>;

export type CreateCaseStrategyMutation = {
  createCaseStrategy: {
    caseStrategy: {
      id: number;
      nodeId: string;
      userId: string | null;
      userEmail: string | null;
      strategy: string;
      label: string;
      updatedAt: any | null;
    } | null;
  } | null;
};

export type UpdateCaseStrategyByIdMutationVariables = Exact<{
  input: UpdateCaseStrategyByIdInput;
}>;

export type UpdateCaseStrategyByIdMutation = {
  updateCaseStrategyById: {
    caseStrategy: {
      id: number;
      nodeId: string;
      userId: string | null;
      userEmail: string | null;
      strategy: string;
      label: string;
      updatedAt: any | null;
    } | null;
  } | null;
};

export type CaseStrategyByIdQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type CaseStrategyByIdQuery = {
  caseStrategyById: {
    id: number;
    nodeId: string;
    label: string;
    strategy: string;
  } | null;
};

export type PetitionNoteLogQueryVariables = Exact<{
  petitionByIdId: Scalars["BigInt"]["input"];
  orderBy: InputMaybe<Array<NotesOrderBy>>;
}>;

export type PetitionNoteLogQuery = {
  petitionById: {
    notesByPetitionId: {
      nodes: Array<{
        id: string;
        createdAt: any;
        content: string;
        userByUserId: { fullName: string } | null;
        userBySenderId: { fullName: string } | null;
      } | null>;
    };
  } | null;
};

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  createNote: {
    note: {
      id: string;
      createdAt: any;
      content: string;
      userByUserId: { fullName: string } | null;
      userBySenderId: { fullName: string } | null;
    } | null;
  } | null;
};

export type AllUserDocumentsByUserIdQueryVariables = Exact<{
  userId: Scalars["BigInt"]["input"];
}>;

export type AllUserDocumentsByUserIdQuery = {
  allUserDocuments: {
    nodes: Array<{
      nodeId: string;
      fileId: string;
      documentType: IndividualDocumentType;
      fileByFileId: {
        id: string;
        name: string;
        mimeType: string;
        ownerId: string;
      } | null;
    } | null>;
  } | null;
};

export type AllUserFilesByUserIdWithTypeQueryVariables = Exact<{
  userId: Scalars["BigInt"]["input"];
}>;

export type AllUserFilesByUserIdWithTypeQuery = {
  allFiles: {
    nodes: Array<{
      id: string;
      name: string;
      mimeType: string;
      userDocumentsByFileId: {
        nodes: Array<{
          nodeId: string;
          documentType: IndividualDocumentType;
          fileId: string;
        } | null>;
      };
      immigrationDocumentsByFileId: {
        nodes: Array<{
          type: ImmigrationDocumentType;
          nodeId: string;
          fileId: string;
        } | null>;
      };
      exhibitFilesByFileId: {
        nodes: Array<{
          fileId: string;
          nodeId: string;
          exhibitId: string;
          exhibitByExhibitId: {
            exhibitType: string | null;
            moduleByModuleId: { type: string } | null;
          } | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type AllCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCompaniesQuery = {
  allCompanies: { nodes: Array<{ id: string; dbaName: string } | null> } | null;
};

export type FilesByUserIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type FilesByUserIdQuery = {
  allFiles: {
    nodes: Array<{ id: string; name: string; createdAt: any } | null>;
  } | null;
};

export type ExhibitTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ExhibitTypesQuery = {
  allExhibitTypes: { nodes: Array<{ id: string; name: string } | null> } | null;
};

export type PetitionUpdatedSubscriptionVariables = Exact<{
  wsId: Scalars["String"]["input"];
}>;

export type PetitionUpdatedSubscription = {
  petitionUpdated: { id: string | null } | null;
};

export type UpdateImmigrationDocOrderMutationVariables = Exact<{
  input: UpdateMultipleImmigrationDocsInput;
}>;

export type UpdateImmigrationDocOrderMutation = {
  updateMultipleImmigrationDocs: { clientMutationId: string | null } | null;
};

export type UpdateImmigrationDocumentMutationVariables = Exact<{
  input: UpdateImmigrationDocumentInput;
}>;

export type UpdateImmigrationDocumentMutation = {
  updateImmigrationDocument: {
    clientMutationId: string | null;
    immigrationDocument: {
      nodeId: string;
      deleted: boolean;
      fileId: string;
    } | null;
  } | null;
};

export type CreateCompanyMemberMutationVariables = Exact<{
  input: CreateCompanyMemberInput;
}>;

export type CreateCompanyMemberMutation = {
  createCompanyMember: {
    companyByCompanyId: {
      nodeId: string;
      companyMembersByCompanyId: {
        nodes: Array<{
          nodeId: string;
          userByUserId: { fullName: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type EntitiesByTypeQueryVariables = Exact<{
  type: InputMaybe<EntityTypes>;
}>;

export type EntitiesByTypeQuery = {
  allAdditionalEntities: {
    nodes: Array<{
      name: string | null;
      id: string;
      type: EntityTypes;
      companyByCompanyId: { dbaName: string } | null;
      userByUserId: { fullName: string } | null;
    } | null>;
  } | null;
};

export type CreateReusableExhibitMutationVariables = Exact<{
  input: CreateReusableExhibitInput;
}>;

export type CreateReusableExhibitMutation = {
  createReusableExhibit: { clientMutationId: string | null } | null;
};

export type UserFormDatumQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type UserFormDatumQuery = {
  userById: {
    nodeId: string;
    id: string;
    fullName: string;
    userDatumById: {
      passportNumberPassportOcr: string | null;
      cityOfIssuePassportOcr: string | null;
      countryOfIssuePassportOcr: string | null;
      expirationDatePassportOcr: string | null;
      dateOfIssuePassportOcr: string | null;
      placeOfBirthPassportOcr: string | null;
      surnamePassportOcr: string | null;
      givenNamePassportOcr: string | null;
      middleNamePassportOcr: string | null;
    } | null;
  } | null;
};

export type AllCompanyOfficesQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type AllCompanyOfficesQuery = {
  companyById: {
    companyOfficesByCompanyId: {
      nodes: Array<{
        id: number;
        label: string | null;
        postalCode: string;
        state: string;
        street: string;
        street2: string | null;
        unitType: UnitTypeAddressEnum | null;
        unitNumber: string | null;
        country: string;
        city: string;
        isEmployeeWorksite: boolean | null;
      } | null>;
    };
  } | null;
};

export type RemoveCompanyMemberMutationVariables = Exact<{
  input: DeleteCompanyMemberByCompanyIdAndUserIdInput;
}>;

export type RemoveCompanyMemberMutation = {
  deleteCompanyMemberByCompanyIdAndUserId: {
    companyMember: {
      companyId: string;
      userId: string;
      role: CompanyUserRole;
      isAuthorizedSignatory: boolean | null;
    } | null;
  } | null;
};

export type ExhibitFilesQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type ExhibitFilesQuery = {
  exhibitById: {
    name: string;
    exhibitFilesByExhibitId: {
      nodes: Array<{
        orderIndex: number | null;
        fileByFileId: {
          id: string;
          name: string;
          mimeType: string;
          createdAt: any;
        } | null;
      } | null>;
    };
  } | null;
};

export type PetitionFormByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type PetitionFormByIdQuery = {
  petitionFormById: {
    fileByFileId: { id: string; name: string } | null;
  } | null;
};

export type ImmigrationDocsSubSubscriptionVariables = Exact<{
  wsId: Scalars["String"]["input"];
}>;

export type ImmigrationDocsSubSubscription = {
  immigrationDocsUpdated: { id: string | null } | null;
};

export type CompanyDocsSubSubscriptionVariables = Exact<{
  wsId: Scalars["String"]["input"];
}>;

export type CompanyDocsSubSubscription = {
  companyDocsUpdated: { id: string | null } | null;
};

export type LetterGenContactDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LetterGenContactDataQuery = {
  allUsers: {
    nodes: Array<{ bio: string | null; fullName: string; id: string } | null>;
  } | null;
  allCompanies: {
    nodes: Array<{ id: string; dbaName: string; bio: string | null } | null>;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  createUser: {
    user: {
      id: string;
      fullName: string;
      bio: string | null;
      additionalEntitiesByUserId: { nodes: Array<{ id: string } | null> };
    } | null;
  } | null;
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
  createCompany: {
    company: {
      dbaName: string;
      bio: string | null;
      additionalEntitiesByCompanyId: { nodes: Array<{ id: string } | null> };
    } | null;
  } | null;
};

export type CreateEntityMutationVariables = Exact<{
  input: CreateAdditionalEntityInput;
}>;

export type CreateEntityMutation = {
  createAdditionalEntity: { additionalEntity: { id: string } | null } | null;
};

export type AllPetitionFormsQueryVariables = Exact<{
  petitionId: Scalars["BigInt"]["input"];
}>;

export type AllPetitionFormsQuery = {
  allPetitionForms: {
    nodes: Array<{
      id: string;
      type: FormType;
      fileByFileId: { id: string; createdAt: any } | null;
    } | null>;
  } | null;
};

export type EntitiesPageQueryVariables = Exact<{ [key: string]: never }>;

export type EntitiesPageQuery = {
  allCompanies: {
    totalCount: number;
    nodes: Array<{ id: string; dbaName: string; bio: string | null } | null>;
  } | null;
  allUsers: {
    totalCount: number;
    nodes: Array<{ bio: string | null; id: string; fullName: string } | null>;
  } | null;
};

export type EntitiesPageStatsQueryVariables = Exact<{ [key: string]: never }>;

export type EntitiesPageStatsQuery = {
  allCompanies: { totalCount: number } | null;
  allUsers: { totalCount: number } | null;
};

export type AdditionalEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type AdditionalEntitiesQuery = {
  allAdditionalEntities: {
    nodes: Array<{
      id: string;
      name: string | null;
      type: EntityTypes;
      data: string | null;
      userByUserId: { fullName: string; id: string; bio: string | null } | null;
      companyByCompanyId: {
        id: string;
        dbaName: string;
        bio: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CompanyEntityFragment = {
  id: string;
  dbaName: string;
  bio: string | null;
  legalName: string | null;
  phoneNumber: string | null;
  ein: string | null;
  websiteUrl: string | null;
  countryBusinessAddress: string | null;
  cityBusinessAddress: string | null;
  stateBusinessAddress: string | null;
  streetNumberStreetNameBusinessAddress: string | null;
  unitNumberBusinessAddress: string | null;
  unitTypeBusinessAddress: UnitTypeAddressEnum | null;
  zipCodeBusinessAddress: string | null;
  isBusinessAddressWorksite: boolean | null;
  companyTypeOfBusiness: string | null;
  currentNumberOfEmployees: number | null;
  companyGrossAnnualIncome: string | null;
  companyNetAnnualIncome: string | null;
  companyDocumentsByCompanyId: {
    nodes: Array<{
      type: CompanyDocumentType;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  };
  companyMembersByCompanyId: {
    nodes: Array<{
      isAuthorizedSignatory: boolean | null;
      role: CompanyUserRole;
      userByUserId: { id: string; fullName: string } | null;
    } | null>;
  };
};

export type CompanyEntityFragmentVariables = Exact<{ [key: string]: never }>;

export type GetEntityQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  entityType: EntityTypes;
}>;

export type GetEntityQuery = {
  additionalEntityById: {
    id: string;
    name: string | null;
    type: EntityTypes;
    data: string | null;
    userByUserId: {
      id: string;
      email: string | null;
      bio: string | null;
      fullName: string;
      archetype: string | null;
      portalOnboarded: boolean | null;
      immigrationDocumentsByOwnerId: {
        nodes: Array<{
          type: ImmigrationDocumentType;
          fileByFileId: { id: string; name: string } | null;
        } | null>;
      };
      userDatumById: {
        cityBirth: string | null;
        cityForeignAddress: string | null;
        cityOfIssuePassportOcr: string | null;
        cityUsResidentialAddress: string | null;
        countryBirth: string | null;
        countryForeignAddress: string | null;
        countryOfIssuePassportOcr: string | null;
        placeOfBirthPassportOcr: string | null;
        postalCodeForeignAddress: string | null;
        provinceBirth: string | null;
        provinceForeignAddress: string | null;
        stateForeignAddress: string | null;
        stateUsResidentialAddress: string | null;
        streetNumberStreetNameForeignAddress: string | null;
        streetNumberStreetNameUsResidentialAddress: string | null;
        zipCodeUsResidentialAddress: string | null;
        phoneNumber: string | null;
        role: string | null;
        linkedinUrl: string | null;
        personalUrl: string | null;
        dateOfBirth: string | null;
        addresses: string | null;
      } | null;
      companyMembersByUserId: {
        nodes: Array<{
          companyByCompanyId: {
            id: string;
            dbaName: string;
            portalOnboarded: boolean | null;
            cityBusinessAddress: string | null;
            countryBusinessAddress: string | null;
            stateBusinessAddress: string | null;
            streetNumberStreetNameBusinessAddress: string | null;
            unitNumberBusinessAddress: string | null;
            unitTypeBusinessAddress: UnitTypeAddressEnum | null;
            zipCodeBusinessAddress: string | null;
            isBusinessAddressWorksite: boolean | null;
          } | null;
        } | null>;
      };
      adminByUserId: { nodeId: string } | null;
      petitionsByBeneficiaryId: { totalCount: number };
    } | null;
    companyByCompanyId: {
      id: string;
      dbaName: string;
      bio: string | null;
      legalName: string | null;
      phoneNumber: string | null;
      ein: string | null;
      websiteUrl: string | null;
      countryBusinessAddress: string | null;
      cityBusinessAddress: string | null;
      stateBusinessAddress: string | null;
      streetNumberStreetNameBusinessAddress: string | null;
      unitNumberBusinessAddress: string | null;
      unitTypeBusinessAddress: UnitTypeAddressEnum | null;
      zipCodeBusinessAddress: string | null;
      isBusinessAddressWorksite: boolean | null;
      companyTypeOfBusiness: string | null;
      currentNumberOfEmployees: number | null;
      companyGrossAnnualIncome: string | null;
      companyNetAnnualIncome: string | null;
      companyDocumentsByCompanyId: {
        nodes: Array<{
          type: CompanyDocumentType;
          fileByFileId: { id: string; name: string } | null;
        } | null>;
      };
      companyMembersByCompanyId: {
        nodes: Array<{
          isAuthorizedSignatory: boolean | null;
          role: CompanyUserRole;
          userByUserId: { id: string; fullName: string } | null;
        } | null>;
      };
    } | null;
  } | null;
  entitySchemaByEntityType: { schema: string } | null;
};

export type UpdateAdditionalEntityByIdMutationVariables = Exact<{
  input: UpdateAdditionalEntityByIdInput;
}>;

export type UpdateAdditionalEntityByIdMutation = {
  updateAdditionalEntityById: { clientMutationId: string | null } | null;
};

export type AllReusableExhibitsQueryVariables = Exact<{ [key: string]: never }>;

export type AllReusableExhibitsQuery = {
  allReusableExhibits: {
    nodes: Array<{
      id: string;
      name: string;
      description: string | null;
      moduleTypeByModuleType: { id: string; name: string } | null;
      exhibitTypeByExhibitType: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type ExhibitRepoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ExhibitRepoQueryQuery = {
  allExhibitTypes: { nodes: Array<{ id: string; name: string } | null> } | null;
  allModuleTypes: { nodes: Array<{ id: string; name: string } | null> } | null;
};

export type ReusableExhibitByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type ReusableExhibitByIdQuery = {
  reusableExhibitById: {
    id: string;
    name: string;
    description: string | null;
    visible: boolean;
    ownedBy: ExhibitOwnerType;
    reusableExhibitFilesByReusableExhibitId: {
      nodes: Array<{
        fileByFileId: { id: string; name: string } | null;
      } | null>;
    };
    exhibitTypeByExhibitType: { id: string; name: string } | null;
    moduleTypeByModuleType: { id: string; name: string } | null;
    reusableExhibitEntitiesByReusableExhibitId: {
      nodes: Array<{
        additionalEntityByEntityId: {
          id: string;
          name: string | null;
          type: EntityTypes;
          companyByCompanyId: { id: string; dbaName: string } | null;
          userByUserId: { id: string; fullName: string } | null;
        } | null;
      } | null>;
    };
    reusableExhibitsByParentExhibitId: {
      nodes: Array<{
        id: string;
        name: string;
        description: string | null;
        exhibitTypeByExhibitType: {
          __typename: "ExhibitType";
          id: string;
          name: string;
        } | null;
        moduleTypeByModuleType: {
          __typename: "ModuleType";
          id: string;
          name: string;
        } | null;
        reusableExhibitFilesByReusableExhibitId: {
          nodes: Array<{
            fileByFileId: { id: string; name: string } | null;
          } | null>;
        };
      } | null>;
    };
  } | null;
};

export type UpdateResuableExhibitMutationVariables = Exact<{
  input: UpdateReusableExhibitByIdInput;
}>;

export type UpdateResuableExhibitMutation = {
  updateReusableExhibitById: { reusableExhibit: { id: string } | null } | null;
};

export type UpdateReusableExhibitFilesOrderMutationVariables = Exact<{
  input: UpdateMultipleReusableExhibitFilesInput;
}>;

export type UpdateReusableExhibitFilesOrderMutation = {
  updateMultipleReusableExhibitFiles: {
    clientMutationId: string | null;
  } | null;
};

export type UpdateReusableSubexhibitOrderMutationVariables = Exact<{
  input: UpdateMultipleReusableExhibitsOrderInput;
}>;

export type UpdateReusableSubexhibitOrderMutation = {
  updateMultipleReusableExhibitsOrder: {
    clientMutationId: string | null;
  } | null;
};

export type CreateReusableExhibitEntityMutationVariables = Exact<{
  input: CreateReusableExhibitEntityInput;
}>;

export type CreateReusableExhibitEntityMutation = {
  createReusableExhibitEntity: { clientMutationId: string | null } | null;
};

export type DeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdMutationVariables =
  Exact<{
    input: DeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdInput;
  }>;

export type DeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdMutation =
  {
    deleteReusableExhibitEntityByReusableExhibitIdAndEntityId: {
      clientMutationId: string | null;
    } | null;
  };

export type UserUpdatesMutationVariables = Exact<{
  input: UpdateUserByIdInput;
}>;

export type UserUpdatesMutation = {
  updateUserById: {
    user: {
      bio: string | null;
      email: string | null;
      portalOnboarded: boolean | null;
      fullName: string;
    } | null;
  } | null;
};

export type UserDatumUpdatesMutationVariables = Exact<{
  input: UpdateUserDatumByIdInput;
}>;

export type UserDatumUpdatesMutation = {
  updateUserDatumById: {
    userDatum: {
      id: string;
      stateUsResidentialAddress: string | null;
      streetNumberStreetNameForeignAddress: string | null;
      streetNumberStreetNameUsResidentialAddress: string | null;
      zipCodeUsResidentialAddress: string | null;
      stateForeignAddress: string | null;
      provinceForeignAddress: string | null;
      provinceBirth: string | null;
      postalCodeForeignAddress: string | null;
      cityBirth: string | null;
      cityForeignAddress: string | null;
      countryBirth: string | null;
      cityUsResidentialAddress: string | null;
      countryForeignAddress: string | null;
      phoneNumber: string | null;
      role: string | null;
      usRoleTitle: string | null;
      addresses: string | null;
    } | null;
  } | null;
};

export type CreateUserDatumMutationVariables = Exact<{
  input: CreateUserDatumInput;
}>;

export type CreateUserDatumMutation = {
  createUserDatum: {
    userDatum: {
      id: string;
      stateUsResidentialAddress: string | null;
      streetNumberStreetNameForeignAddress: string | null;
      streetNumberStreetNameUsResidentialAddress: string | null;
      zipCodeUsResidentialAddress: string | null;
      stateForeignAddress: string | null;
      provinceForeignAddress: string | null;
      provinceBirth: string | null;
      postalCodeForeignAddress: string | null;
      cityBirth: string | null;
      cityForeignAddress: string | null;
      countryBirth: string | null;
      cityUsResidentialAddress: string | null;
      countryForeignAddress: string | null;
      phoneNumber: string | null;
      role: string | null;
    } | null;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateCompanyByIdInput;
}>;

export type UpdateTeamMutation = {
  updateCompanyById: { clientMutationId: string | null } | null;
};

export type CompanyByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type CompanyByIdQuery = {
  companyById: {
    dbaName: string;
    id: string;
    bio: string | null;
    portalOnboarded: boolean | null;
    stateBusinessAddress: string | null;
    streetNumberStreetNameBusinessAddress: string | null;
    unitNumberBusinessAddress: string | null;
    unitTypeBusinessAddress: UnitTypeAddressEnum | null;
    zipCodeBusinessAddress: string | null;
    countryBusinessAddress: string | null;
    cityBusinessAddress: string | null;
    companyTypeOfBusiness: string | null;
    currentNumberOfEmployees: number | null;
    companyGrossAnnualIncome: string | null;
    companyNetAnnualIncome: string | null;
  } | null;
};

export type ImmigrationDocsByUserQueryVariables = Exact<{
  ownerId: Scalars["BigInt"]["input"];
  type: ImmigrationDocumentType;
}>;

export type ImmigrationDocsByUserQuery = {
  allImmigrationDocuments: {
    nodes: Array<{
      nodeId: string;
      deleted: boolean;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type AllImmigrationDocsByUserQueryVariables = Exact<{
  ownerId: Scalars["BigInt"]["input"];
}>;

export type AllImmigrationDocsByUserQuery = {
  allImmigrationDocuments: {
    nodes: Array<{
      nodeId: string;
      type: ImmigrationDocumentType;
      deleted: boolean;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type CompanyDocsByTypeQueryVariables = Exact<{
  companyId: Scalars["BigInt"]["input"];
  type: CompanyDocumentType;
}>;

export type CompanyDocsByTypeQuery = {
  allCompanyDocuments: {
    nodes: Array<{
      visible: boolean;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type AllCompanyDocsQueryVariables = Exact<{
  companyId: Scalars["BigInt"]["input"];
}>;

export type AllCompanyDocsQuery = {
  allCompanyDocuments: {
    nodes: Array<{
      type: CompanyDocumentType;
      visible: boolean;
      fileByFileId: { id: string; name: string; deleted: boolean } | null;
    } | null>;
  } | null;
};

export type AllCompanyDocsForBeneficiaryQueryVariables = Exact<{
  companyId: Scalars["BigInt"]["input"];
}>;

export type AllCompanyDocsForBeneficiaryQuery = {
  allCompanyDocuments: {
    nodes: Array<{
      type: CompanyDocumentType;
      visible: boolean;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyByIdInput;
}>;

export type UpdateCompanyMutation = {
  updateCompanyById: { company: { id: string } | null } | null;
};

export type CreateCompanyOfficeMutationVariables = Exact<{
  input: CreateCompanyOfficeInput;
}>;

export type CreateCompanyOfficeMutation = {
  createCompanyOffice: { companyOffice: { id: number } | null } | null;
};

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars["BigInt"]["input"];
  count: Scalars["Int"]["input"];
}>;

export type GetCompanyQuery = {
  companyById: {
    countryBusinessAddress: string | null;
    stateBusinessAddress: string | null;
    cityBusinessAddress: string | null;
    streetNumberStreetNameBusinessAddress: string | null;
    zipCodeBusinessAddress: string | null;
    isBusinessAddressWorksite: boolean | null;
    portalOnboarded: boolean | null;
    companyMembersByCompanyId: {
      nodes: Array<{
        companyId: string;
        nodeId: string;
        isAuthorizedSignatory: boolean | null;
        userByUserId: {
          fullName: string;
          id: string;
          email: string | null;
          userDatumById: {
            phoneNumber: string | null;
            usRoleTitle: string | null;
            role: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AllUserDocumentsByTypeQueryVariables = Exact<{
  userId: InputMaybe<Scalars["BigInt"]["input"]>;
  documentType: IndividualDocumentType;
}>;

export type AllUserDocumentsByTypeQuery = {
  allUserDocuments: {
    nodes: Array<{
      documentType: IndividualDocumentType;
      fileByFileId: { id: string; name: string } | null;
    } | null>;
  } | null;
};

export type UserEmploymentByIdQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type UserEmploymentByIdQuery = {
  userEmploymentById: {
    id: number;
    city: string | null;
    companyName: string;
    country: string | null;
    endDate: string | null;
    isCurrent: boolean | null;
    nodeId: string;
    position: string | null;
    startDate: string | null;
    userId: string | null;
  } | null;
};

export type UpdateUserEmploymentByIdMutationVariables = Exact<{
  input: UpdateUserEmploymentByIdInput;
}>;

export type UpdateUserEmploymentByIdMutation = {
  updateUserEmploymentById: {
    userEmployment: {
      userId: string | null;
      companyName: string;
      position: string | null;
      endDate: string | null;
      isCurrent: boolean | null;
      nodeId: string;
      startDate: string | null;
      city: string | null;
      country: string | null;
    } | null;
  } | null;
};

export type UpdateCompanyMemberByCompanyIdAndUserIdMutationVariables = Exact<{
  input: UpdateCompanyMemberByCompanyIdAndUserIdInput;
}>;

export type UpdateCompanyMemberByCompanyIdAndUserIdMutation = {
  updateCompanyMemberByCompanyIdAndUserId: {
    companyMember: {
      userId: string;
      companyId: string;
      role: CompanyUserRole;
      isAuthorizedSignatory: boolean | null;
    } | null;
  } | null;
};

export type CompanyMemberByCompanyIdAndUserIdQueryVariables = Exact<{
  companyId: Scalars["BigInt"]["input"];
  userId: Scalars["BigInt"]["input"];
}>;

export type CompanyMemberByCompanyIdAndUserIdQuery = {
  companyMemberByCompanyIdAndUserId: {
    companyId: string;
    isAuthorizedSignatory: boolean | null;
    userId: string;
  } | null;
};

export type UpdateCompanyOfficeByIdMutationVariables = Exact<{
  input: UpdateCompanyOfficeByIdInput;
}>;

export type UpdateCompanyOfficeByIdMutation = {
  updateCompanyOfficeById: {
    companyOffice: {
      id: number;
      companyId: number;
      label: string | null;
    } | null;
  } | null;
};

export type OnboardingByPetitionIdQueryVariables = Exact<{
  petitionId: Scalars["BigInt"]["input"];
}>;

export type OnboardingByPetitionIdQuery = {
  allOnboardings: {
    nodes: Array<{
      nodeId: string;
      id: string;
      petitionId: string;
      modules: string;
      status: OnboardingStatusType | null;
      version: any;
    } | null>;
  } | null;
};

export type UpdateOnboardingByIdMutationVariables = Exact<{
  input: UpdateOnboardingByIdInput;
}>;

export type UpdateOnboardingByIdMutation = {
  updateOnboardingById: { onboarding: { id: string } | null } | null;
};

export type CreateUserEmploymentMutationVariables = Exact<{
  input: CreateUserEmploymentInput;
}>;

export type CreateUserEmploymentMutation = {
  createUserEmployment: {
    userEmployment: {
      id: number;
      companyName: string;
      position: string | null;
      startDate: string | null;
      endDate: string | null;
      isCurrent: boolean | null;
      salary: number | null;
      salaryFrequency: string | null;
      otherCompensation: string | null;
      isFullTime: boolean | null;
      hoursPerWeek: number | null;
      userId: string | null;
      companyId: string;
      nodeId: string;
    } | null;
  } | null;
};

export type UpdateUserEmploymentMutationVariables = Exact<{
  input: UpdateUserEmploymentByIdInput;
}>;

export type UpdateUserEmploymentMutation = {
  updateUserEmploymentById: {
    userEmployment: {
      id: number;
      city: string | null;
      companyId: string;
      companyName: string;
      country: string | null;
      endDate: string | null;
      hoursPerWeek: number | null;
      isCurrent: boolean | null;
      isFullTime: boolean | null;
      nodeId: string;
      otherCompensation: string | null;
      position: string | null;
      salary: number | null;
      salaryFrequency: string | null;
      startDate: string | null;
      userId: string | null;
    } | null;
  } | null;
};

export type UserEmploymentsQueryVariables = Exact<{
  petitionerId: Scalars["BigInt"]["input"];
}>;

export type UserEmploymentsQuery = {
  allUserEmployments: {
    nodes: Array<{
      nodeId: string;
      id: number;
      companyId: string;
      companyName: string;
      position: string | null;
      startDate: string | null;
      isCurrent: boolean | null;
      salary: number | null;
      salaryFrequency: string | null;
      otherCompensation: string | null;
      isFullTime: boolean | null;
      hoursPerWeek: number | null;
      userId: string | null;
    } | null>;
  } | null;
};

export type LoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type LoggedInUserQuery = {
  currentUserId: string | null;
  isAdmin: boolean | null;
};

export type UserDatumFragment = {
  cityBirth: string | null;
  cityForeignAddress: string | null;
  cityOfIssuePassportOcr: string | null;
  cityUsResidentialAddress: string | null;
  countryBirth: string | null;
  countryForeignAddress: string | null;
  countryOfIssuePassportOcr: string | null;
  placeOfBirthPassportOcr: string | null;
  postalCodeForeignAddress: string | null;
  provinceBirth: string | null;
  provinceForeignAddress: string | null;
  stateForeignAddress: string | null;
  stateUsResidentialAddress: string | null;
  streetNumberStreetNameForeignAddress: string | null;
  streetNumberStreetNameUsResidentialAddress: string | null;
  zipCodeUsResidentialAddress: string | null;
  phoneNumber: string | null;
  role: string | null;
  linkedinUrl: string | null;
  personalUrl: string | null;
  dateOfBirth: string | null;
  addresses: string | null;
};

export type UserDatumFragmentVariables = Exact<{ [key: string]: never }>;

export type UserFragment = {
  id: string;
  email: string | null;
  bio: string | null;
  fullName: string;
  archetype: string | null;
  portalOnboarded: boolean | null;
  userDatumById: {
    cityBirth: string | null;
    cityForeignAddress: string | null;
    cityOfIssuePassportOcr: string | null;
    cityUsResidentialAddress: string | null;
    countryBirth: string | null;
    countryForeignAddress: string | null;
    countryOfIssuePassportOcr: string | null;
    placeOfBirthPassportOcr: string | null;
    postalCodeForeignAddress: string | null;
    provinceBirth: string | null;
    provinceForeignAddress: string | null;
    stateForeignAddress: string | null;
    stateUsResidentialAddress: string | null;
    streetNumberStreetNameForeignAddress: string | null;
    streetNumberStreetNameUsResidentialAddress: string | null;
    zipCodeUsResidentialAddress: string | null;
    phoneNumber: string | null;
    role: string | null;
    linkedinUrl: string | null;
    personalUrl: string | null;
    dateOfBirth: string | null;
    addresses: string | null;
  } | null;
  companyMembersByUserId: {
    nodes: Array<{
      companyByCompanyId: {
        id: string;
        dbaName: string;
        portalOnboarded: boolean | null;
        cityBusinessAddress: string | null;
        countryBusinessAddress: string | null;
        stateBusinessAddress: string | null;
        streetNumberStreetNameBusinessAddress: string | null;
        unitNumberBusinessAddress: string | null;
        unitTypeBusinessAddress: UnitTypeAddressEnum | null;
        zipCodeBusinessAddress: string | null;
        isBusinessAddressWorksite: boolean | null;
      } | null;
    } | null>;
  };
  adminByUserId: { nodeId: string } | null;
  petitionsByBeneficiaryId: { totalCount: number };
};

export type UserFragmentVariables = Exact<{ [key: string]: never }>;

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars["BigInt"]["input"];
}>;

export type GetUserByIdQuery = {
  userById: {
    id: string;
    email: string | null;
    bio: string | null;
    fullName: string;
    archetype: string | null;
    portalOnboarded: boolean | null;
    userDatumById: {
      cityBirth: string | null;
      cityForeignAddress: string | null;
      cityOfIssuePassportOcr: string | null;
      cityUsResidentialAddress: string | null;
      countryBirth: string | null;
      countryForeignAddress: string | null;
      countryOfIssuePassportOcr: string | null;
      placeOfBirthPassportOcr: string | null;
      postalCodeForeignAddress: string | null;
      provinceBirth: string | null;
      provinceForeignAddress: string | null;
      stateForeignAddress: string | null;
      stateUsResidentialAddress: string | null;
      streetNumberStreetNameForeignAddress: string | null;
      streetNumberStreetNameUsResidentialAddress: string | null;
      zipCodeUsResidentialAddress: string | null;
      phoneNumber: string | null;
      role: string | null;
      linkedinUrl: string | null;
      personalUrl: string | null;
      dateOfBirth: string | null;
      addresses: string | null;
    } | null;
    companyMembersByUserId: {
      nodes: Array<{
        companyByCompanyId: {
          id: string;
          dbaName: string;
          portalOnboarded: boolean | null;
          cityBusinessAddress: string | null;
          countryBusinessAddress: string | null;
          stateBusinessAddress: string | null;
          streetNumberStreetNameBusinessAddress: string | null;
          unitNumberBusinessAddress: string | null;
          unitTypeBusinessAddress: UnitTypeAddressEnum | null;
          zipCodeBusinessAddress: string | null;
          isBusinessAddressWorksite: boolean | null;
        } | null;
      } | null>;
    };
    adminByUserId: { nodeId: string } | null;
    petitionsByBeneficiaryId: { totalCount: number };
  } | null;
};

export type ExhibitFragment = {
  id: string;
  name: string;
  description: string | null;
  status: ExhibitStatusType;
  parentExhibitId: string | null;
  orderIndex: number;
  ownedBy: ExhibitOwnerType;
  exhibitType: string | null;
  metaData: string | null;
  exhibitFilesByExhibitId: {
    nodes: Array<{
      orderIndex: number | null;
      fileByFileId: {
        id: string;
        name: string;
        deleted: boolean;
        createdAt: any;
        mimeType: string;
      } | null;
    } | null>;
  };
};

export type ExhibitFragmentVariables = Exact<{ [key: string]: never }>;

export type ModuleFragment = {
  id: string;
  name: string;
  description: string | null;
  type: string;
  orderIndex: number;
  exhibitsByModuleId: {
    nodes: Array<{
      id: string;
      name: string;
      description: string | null;
      status: ExhibitStatusType;
      parentExhibitId: string | null;
      orderIndex: number;
      ownedBy: ExhibitOwnerType;
      exhibitType: string | null;
      metaData: string | null;
      exhibitFilesByExhibitId: {
        nodes: Array<{
          orderIndex: number | null;
          fileByFileId: {
            id: string;
            name: string;
            deleted: boolean;
            createdAt: any;
            mimeType: string;
          } | null;
        } | null>;
      };
    } | null>;
  };
};

export type ModuleFragmentVariables = Exact<{ [key: string]: never }>;

export type PetitionFragment = {
  id: string;
  latestPetition: boolean;
  petitionShippedDate: string | null;
  status: PetitionStatus;
  targetSubmissionDate: string | null;
  uscisAddress: string | null;
  trackingNumber: string | null;
  uscisReceiptNumber: string | null;
  uscisStatus: string | null;
  filingType: FilingType | null;
  visaClass: VisaClassType;
  metadata: string;
  companyByPetitionerId: { dbaName: string; id: string } | null;
  userByBeneficiaryId: { id: string; fullName: string } | null;
  modulesByPetitionId: {
    nodes: Array<{
      id: string;
      name: string;
      description: string | null;
      type: string;
      orderIndex: number;
      exhibitsByModuleId: {
        nodes: Array<{
          id: string;
          name: string;
          description: string | null;
          status: ExhibitStatusType;
          parentExhibitId: string | null;
          orderIndex: number;
          ownedBy: ExhibitOwnerType;
          exhibitType: string | null;
          metaData: string | null;
          exhibitFilesByExhibitId: {
            nodes: Array<{
              orderIndex: number | null;
              fileByFileId: {
                id: string;
                name: string;
                deleted: boolean;
                createdAt: any;
                mimeType: string;
              } | null;
            } | null>;
          };
        } | null>;
      };
    } | null>;
  };
};

export type PetitionFragmentVariables = Exact<{ [key: string]: never }>;

export type GetPetitionsByUserIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type GetPetitionsByUserIdQuery = {
  allPetitions: {
    nodes: Array<{
      id: string;
      latestPetition: boolean;
      petitionShippedDate: string | null;
      status: PetitionStatus;
      targetSubmissionDate: string | null;
      uscisAddress: string | null;
      trackingNumber: string | null;
      uscisReceiptNumber: string | null;
      uscisStatus: string | null;
      filingType: FilingType | null;
      visaClass: VisaClassType;
      metadata: string;
      companyByPetitionerId: { dbaName: string; id: string } | null;
      userByBeneficiaryId: { id: string; fullName: string } | null;
      modulesByPetitionId: {
        nodes: Array<{
          id: string;
          name: string;
          description: string | null;
          type: string;
          orderIndex: number;
          exhibitsByModuleId: {
            nodes: Array<{
              id: string;
              name: string;
              description: string | null;
              status: ExhibitStatusType;
              parentExhibitId: string | null;
              orderIndex: number;
              ownedBy: ExhibitOwnerType;
              exhibitType: string | null;
              metaData: string | null;
              exhibitFilesByExhibitId: {
                nodes: Array<{
                  orderIndex: number | null;
                  fileByFileId: {
                    id: string;
                    name: string;
                    deleted: boolean;
                    createdAt: any;
                    mimeType: string;
                  } | null;
                } | null>;
              };
            } | null>;
          };
        } | null>;
      };
    } | null>;
  } | null;
};

export type GetPetitionsByCompanyIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type GetPetitionsByCompanyIdQuery = {
  allPetitions: {
    nodes: Array<{
      id: string;
      latestPetition: boolean;
      petitionShippedDate: string | null;
      status: PetitionStatus;
      targetSubmissionDate: string | null;
      uscisAddress: string | null;
      trackingNumber: string | null;
      uscisReceiptNumber: string | null;
      uscisStatus: string | null;
      filingType: FilingType | null;
      visaClass: VisaClassType;
      metadata: string;
      companyByPetitionerId: { dbaName: string; id: string } | null;
      userByBeneficiaryId: { id: string; fullName: string } | null;
      modulesByPetitionId: {
        nodes: Array<{
          id: string;
          name: string;
          description: string | null;
          type: string;
          orderIndex: number;
          exhibitsByModuleId: {
            nodes: Array<{
              id: string;
              name: string;
              description: string | null;
              status: ExhibitStatusType;
              parentExhibitId: string | null;
              orderIndex: number;
              ownedBy: ExhibitOwnerType;
              exhibitType: string | null;
              metaData: string | null;
              exhibitFilesByExhibitId: {
                nodes: Array<{
                  orderIndex: number | null;
                  fileByFileId: {
                    id: string;
                    name: string;
                    deleted: boolean;
                    createdAt: any;
                    mimeType: string;
                  } | null;
                } | null>;
              };
            } | null>;
          };
        } | null>;
      };
    } | null>;
  } | null;
};

export type PetitionByIdQueryVariables = Exact<{
  petitionId: Scalars["BigInt"]["input"];
}>;

export type PetitionByIdQuery = {
  petitionById: {
    id: string;
    latestPetition: boolean;
    petitionShippedDate: string | null;
    status: PetitionStatus;
    targetSubmissionDate: string | null;
    uscisAddress: string | null;
    trackingNumber: string | null;
    uscisReceiptNumber: string | null;
    uscisStatus: string | null;
    filingType: FilingType | null;
    visaClass: VisaClassType;
    metadata: string;
    companyByPetitionerId: { dbaName: string; id: string } | null;
    userByBeneficiaryId: { id: string; fullName: string } | null;
    modulesByPetitionId: {
      nodes: Array<{
        id: string;
        name: string;
        description: string | null;
        type: string;
        orderIndex: number;
        exhibitsByModuleId: {
          nodes: Array<{
            id: string;
            name: string;
            description: string | null;
            status: ExhibitStatusType;
            parentExhibitId: string | null;
            orderIndex: number;
            ownedBy: ExhibitOwnerType;
            exhibitType: string | null;
            metaData: string | null;
            exhibitFilesByExhibitId: {
              nodes: Array<{
                orderIndex: number | null;
                fileByFileId: {
                  id: string;
                  name: string;
                  deleted: boolean;
                  createdAt: any;
                  mimeType: string;
                } | null;
              } | null>;
            };
          } | null>;
        };
      } | null>;
    };
  } | null;
};

export type PetitionBuilderPetitionByIdQueryVariables = Exact<{
  petitionId: Scalars["BigInt"]["input"];
}>;

export type PetitionBuilderPetitionByIdQuery = {
  petitionById: {
    id: string;
    latestPetition: boolean;
    petitionShippedDate: string | null;
    status: PetitionStatus;
    targetSubmissionDate: string | null;
    uscisAddress: string | null;
    trackingNumber: string | null;
    uscisReceiptNumber: string | null;
    uscisStatus: string | null;
    visaClass: VisaClassType;
    metadata: string;
    companyByPetitionerId: { dbaName: string; id: string } | null;
    userByBeneficiaryId: { id: string; fullName: string } | null;
    modulesByPetitionId: {
      nodes: Array<{
        id: string;
        name: string;
        description: string | null;
        type: string;
        orderIndex: number;
        exhibitsByModuleId: {
          nodes: Array<{
            id: string;
            name: string;
            description: string | null;
            status: ExhibitStatusType;
            parentExhibitId: string | null;
            orderIndex: number;
            ownedBy: ExhibitOwnerType;
            exhibitType: string | null;
            metaData: string | null;
            exhibitFilesByExhibitId: {
              nodes: Array<{
                orderIndex: number | null;
                fileByFileId: {
                  id: string;
                  name: string;
                  deleted: boolean;
                  createdAt: any;
                  mimeType: string;
                } | null;
              } | null>;
            };
          } | null>;
        };
      } | null>;
    };
  } | null;
};

export type TestSubSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TestSubSubscription = {
  testSubscription: { id: string | null } | null;
};

export type GetUserDatumByIdQueryVariables = Exact<{
  id: Scalars["BigInt"]["input"];
}>;

export type GetUserDatumByIdQuery = {
  userDatumById: { addresses: string | null; otherNames: string | null } | null;
};

export type OnboardingModuleTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OnboardingModuleTypesQuery = {
  allOnboardingModuleTypes: {
    nodes: Array<{ moduleType: string; description: string | null } | null>;
  } | null;
};
