import {
  UserFragment,
  PetitionFragment,
  ExhibitFragment,
  VisaClassType,
} from "@codegen/schema";
import { PlymouthUser, Team, TeamGroup } from "./types";
import { z } from "zod";

export const visaClassMap: Record<VisaClassType, string> = {
  [VisaClassType.H_1B]: "H-1B",
  [VisaClassType.O_1A]: "O-1A",
  [VisaClassType.Tn]: "TN",
  [VisaClassType.Eb_1A]: "EB-1A",
  [VisaClassType.Eb_2Niw]: "EB-2 NIW",
};

export const formatVisaClass = (visaClass: VisaClassType) => {
  return visaClassMap[visaClass] ?? visaClass;
};

export const graphqlEnumToType = (x: string): string => {
  if (x == null) return x;
  const y = x.toLowerCase();
  return y.replaceAll("-", "_");
};

export const moduleExhibitTypeMap = {
  "memo-starter": ["employer-documents", "beneficiary-cv", "ev-letter", "expert-letter"],
  membership: [
    "expert-letter",
    "proof-of-membership-letter-or-email",
    "judges",
    "evidence-of-recognition",
    "proof-of-membership-profile-page",
    "membership-documentation",
  ],

  judging: [
    "judging-invite-or-confirmation",
    "evidence-of-selection-criteria",
    "judges",
    "expert-letter",
    "judging-documentation",
    "screenshot-of-event-or-prizes",
    "link-to-event"
  ],

  "critical-role": [
    "ev-letter",
    "offer-letter",
    "promotion-evidence",
    "pay-raise-evidence",
    "code-contributor-page",
    "code-project-ownership",
    "expert-letter",
    "org-chart",
    "internal-docs",
    "customer-contract",
    "calendar",
    "original-code",
    "pitch-deck",
    "user-count-or-revenue",
    "patent",
    "distinguished-reputation",
  ],
  awards: [
    "cap-table",
    "award-evidence",
    "vc-evidence",
    "evidence-of-recognition",
    "evidence-of-selectivity",
    "evidence-of-selection-criteria",
    "expert-letter",
    "profile-of-judges",
  ],

  "original-contribution": [
    "original-contributions-summary",
    "press-article",
    "evidence-of-citation",
    "evidence-of-commercial-adoption",
    "evidence-of-significant-usage",
    "evidence-of-industry-awards",
    "expert-letter",
    "internal-docs",
    "original-code",
    "technical-doc",
    "product-roadmap",
    "customer-contract",
    "vc-evidence",
    "pitch-deck",
    "user-count-or-revenue",
    "patent",
  ],
  press: ["press-article", "press-summary"],
  "high-remuneration": [
    "offer-letter",
    "ev-letter",
    "pay-stubs",
    "pay-bonus",
    "tax-return",
    "equity-statement",
    "compensation-benchmark",
    "promotion-evidence",
    "pay-raise-evidence",
  ],

  authorship: ["google-scholar-page", "journal-article", "conference-presentation", "publication-prominence"],

  "tn-support-letter-offer-of-employment": ["support-letter", "offer-letter"],

  "tn-occupation-list": ["tn-occupation-list", "occupational-outlook-handbook"],

  "tn-beneficiary-education-docs": ["transcript", "beneficiary-cv", "proof-of-enrollment"],
};
export type CreatorModuleType = keyof typeof moduleExhibitTypeMap;
export const creatorModuleTypes = Object.keys(
  moduleExhibitTypeMap
) as CreatorModuleType[];

export const getTeamsFromContact = (contact: UserFragment): TeamGroup[] => {
  const res: TeamGroup = {
    label: "Personal",
    teams: [
      {
        label: contact.fullName ?? "Personal Account",
        value: contact.id,
        type: "personal",
      },
    ],
  };

  
  /**
   * If not members of any company, just return Personal profile
   */
  if (
    contact.companyMembersByUserId.nodes == null ||
    contact.companyMembersByUserId.nodes.length === 0
  )
    return [res];

  /**
   * If members of any company, add teams of type "team"
   */
  const teams: Team[] = [];

  for (const x of contact.companyMembersByUserId.nodes) {
    if (x == null) continue;
    const team = x.companyByCompanyId;
    if (team == null) continue;

    teams.push({
      label: team.dbaName ?? "Unknown Team",
      value: team.id,
      type: "team",
      portalOnboarded: team.portalOnboarded ?? false,
      hqAddress: {
        country: team.countryBusinessAddress,
        state: team.stateBusinessAddress,
        city: team.cityBusinessAddress,
        street: team.streetNumberStreetNameBusinessAddress,
        postalCode: team.zipCodeBusinessAddress,
        isWorksite: team.isBusinessAddressWorksite
      },
    });
  }

  /**
   * If contact has any petitions, return "personal" + "team" from company members
   */
  if (contact.petitionsByBeneficiaryId.totalCount > 0) {
    if (teams.length > 0) {
      return [
        {
          label: "Teams",
          teams,
        },
        res,
      ];
    }

    return [res];
  }

  /**
   * Return teams of type "team" only. This is probably empty if not a member
   */
  return [
    {
      label: "Teams",
      teams,
    },
  ];
};

export const parseContact = (
  user: UserFragment | undefined
): PlymouthUser | undefined =>
  user?.id != null
    ? {
        id: user.id,
        name: user.fullName ?? undefined,
        firstName: user.fullName?.split(' ')?.[0] ?? "",
        middleName: user.fullName?.split(' ')?.length > 2 ? user.fullName?.split(' ')?.[1] : "",
        lastName: user.fullName?.split(' ')?.[2] ?? user.fullName?.split(' ')?.[1] ?? "",
        email: user.email ?? undefined,
        teams: getTeamsFromContact(user),
        portalOnboarded: user.portalOnboarded ?? false,
        usAddress: {
          country: "US",
          state: user.userDatumById?.stateUsResidentialAddress,
          city: user.userDatumById?.cityUsResidentialAddress,
          street:
            user.userDatumById?.streetNumberStreetNameUsResidentialAddress,
          postalCode: user.userDatumById?.zipCodeUsResidentialAddress,
        },
        foreignAddress: {
          country: user.userDatumById?.countryForeignAddress,
          state: user.userDatumById?.provinceForeignAddress,
          city: user.userDatumById?.cityForeignAddress,
          street: user.userDatumById?.streetNumberStreetNameForeignAddress,
          postalCode: user.userDatumById?.postalCodeForeignAddress,
        },
        phoneNumber: user.userDatumById?.phoneNumber ?? undefined,
        role: user.userDatumById?.role ?? undefined,
      }
    : undefined;

const fileSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string(),
});
export type ParsedFile = z.infer<typeof fileSchema>;

const statusMap = {
  requested: "requested",
  submitted: "submitted",
  approved: "approved",

  pending: "pending",
  drafted: "drafted",

  "Plymouth Approved": "plymouth-approved",
  "Customer Approved": "customer-approved",
  "Edit Requested": "edit-requested",
};

const baseExhibitSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z
    .string()
    .nullable()
    .transform((x) => (x == null ? "files" : x)),
  status: z.string().transform((x) => {
    if (x == null) return "requested";
    const k = x.toLowerCase();
    const res = statusMap[k as keyof typeof statusMap];
    if (res == null) {
      console.error("unknown status", x);
      return "pending";
    }
    return res;
  }),
  files: z.array(fileSchema),
});

export type ParsedExhibit = z.infer<typeof baseExhibitSchema>;

const moduleSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  exhibits: z.array(baseExhibitSchema),
  idx: z.number(),
});
export type ParsedModule = z.infer<typeof moduleSchema>;

const basePetitionTreeNode = z.object({
  id: z.string(),
  type: z.union([
    z.literal("petition"),
    z.literal("module"),
    z.literal("exhibit"),
    z.literal("subexhibit"),
    z.literal("file"),
    z.literal("memo-preview"),
  ]),
});

export type PetitionTreeNode = z.infer<typeof basePetitionTreeNode> & {
  children: PetitionTreeNode[];
};
export const petitionTreeNodeSchema: z.ZodType<PetitionTreeNode> =
  basePetitionTreeNode.extend({
    children: z.lazy(() => petitionTreeNodeSchema.array()),
  });

const parseExhibits = (exhibits: (ExhibitFragment | null)[] | null) => {
  if (exhibits == null) return [];

  const res: PetitionTreeNode[] = [];

  const subexhibitMap: Record<string, ExhibitFragment[]> = {};
  for (const exhibit of exhibits) {
    if (exhibit == null) continue;
    if (exhibit.parentExhibitId == null) continue;
    if (subexhibitMap[exhibit.parentExhibitId] == null) {
      subexhibitMap[exhibit.parentExhibitId] = [];
    }
    subexhibitMap[exhibit.parentExhibitId].push(exhibit);
  }

  for (const exhibit of exhibits) {
    if (exhibit == null) continue;
    const children = [];

    for (const file of exhibit.exhibitFilesByExhibitId.nodes ?? []) {
      if (file == null) continue;
      if (file.fileByFileId?.id == null || file.fileByFileId.name == null)
        continue;

      const parser = petitionTreeNodeSchema.safeParse({
        id: file.fileByFileId.id,
        type: "file",
        children: [],
      });

      if (!parser.success) {
        console.error("failed to parse file", parser.error);
        continue;
      }

      children.push(parser.data);
    }

    const subexhibits = subexhibitMap[exhibit.id];
    for (const subexhibit of subexhibits ?? []) {
      if (subexhibit == null) continue;

      const children = [];

      for (const file of subexhibit.exhibitFilesByExhibitId.nodes ?? []) {
        if (file == null) continue;
        if (file.fileByFileId?.id == null || file.fileByFileId.name == null)
          continue;

        const parser = petitionTreeNodeSchema.safeParse({
          id: file.fileByFileId.id,
          type: "file",
          children: [],
        });

        if (!parser.success) {
          console.error("failed to parse file", parser.error);
          continue;
        }

        children.push(parser.data);
      }

      const parsedSubexhibit = petitionTreeNodeSchema.safeParse({
        id: subexhibit.id,
        type: "exhibit",
        children,
      });

      if (!parsedSubexhibit.success) {
        console.error("failed to parse subexhibit", parsedSubexhibit.error);
        continue;
      }

      children.push(parsedSubexhibit.data);
    }

    const parsedExhibit = petitionTreeNodeSchema.safeParse({
      id: exhibit.id,
      type: "exhibit",
      children,
    });

    if (!parsedExhibit.success) {
      console.error("failed to parse exhibit", parsedExhibit.error);
      continue;
    }

    res.push(parsedExhibit.data);
  }
  return res;
};

const parseModules = (
  modules: PetitionFragment["modulesByPetitionId"]["nodes"]
) => {
  const res: PetitionTreeNode[] = [];
  if (modules == null) return res;

  for (const module of modules) {
    if (module == null) continue;

    const parsedModule = petitionTreeNodeSchema.safeParse({
      id: module.id,
      type: "module",
      children: parseExhibits(module.exhibitsByModuleId.nodes),
    });

    if (!parsedModule.success) {
      console.log(module);
      console.error("failed to parse module", parsedModule.error);
      continue;
    }

    res.push(parsedModule.data);
  }

  return res;
};

export const parsePetitionTree = (
  petition: PetitionFragment
): PetitionTreeNode | undefined => {
  if (petition.id == null) return;
  if (petition.userByBeneficiaryId?.id == null) {
    console.error("petition is missing beneficiary");
    return;
  }
  if (petition.visaClass?.[0] == null) {
    console.error("petition is missing visa class");
    return;
  }

  const parser = petitionTreeNodeSchema.safeParse({
    id: petition.id,
    type: "petition",
    children: parseModules(petition.modulesByPetitionId.nodes),
  });

  if (!parser.success) {
    console.error("failed to parse petition", parser.error);
    return;
  }

  return parser.data;
};
