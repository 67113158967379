import { Button } from "@components/button";
import { OnboardingPageType } from "@pages/onboarding/types";

export const CTAContainer = (props: {
  children: React.ReactNode;
  onBack?: OnboardingPageType['onBack']
  onSkip?: OnboardingPageType['onSkip']
}) => {
  const { children, onBack, onSkip } = props;

  return (
    <div className="absolute bottom-0 left-0 py-4 w-screen bg-white">
      <div className="m-auto w-full md:w-[671px]">
        <div className="flex grow justify-between">
          {
            onBack ? (
              <Button
                variant="outline"
                className="text-md rounded-sm px-7 py-5"
                disabled={false}
                onClick={onBack}
                type="button"
              >
                Back
              </Button>
            ) : <span/>
          }
          <div className="flex items-center gap-6">
            {
              onSkip && (
                <Button className="text-md hover:bg-gray-50" type="button" variant={'link'} onClick={onSkip}>Next, skip for now</Button>
              )
            }
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

