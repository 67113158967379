import { cn } from "@utils/cn";
import {
  ClipboardText,
  File,
  HouseSimple,
  Icon,
  Info,
  Flag
} from "@phosphor-icons/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useContact } from "@utils/hooks";
import { useFeatureGate } from "@statsig/react-bindings";

export type PageOptions = "home" | "actions" | "docs" | "help" | "track" | "onboarding";

export const allPages: {
  title: string;
  page: PageOptions;
  icon: Icon;
}[] = [
  {
    title: "Home",
    page: "home",
    icon: HouseSimple,
  },
  // {
  //   title: "Action Items",
  //   page: "onboarding",
  //   icon: Flag,
  //   notificationCount: 5,
  // },
  {
    title: "Action Items",
    page: "actions",
    icon: ClipboardText,
  },
  {
    title: "Documents",
    page: "docs",
    icon: File,
  },
  // {
  //   title: "Track your Petition",
  //   page: "track",
  //   icon: <MapPin strokeWidth={1} size={24} />,
  // },
  {
    title: "Help",
    page: "help",
    icon: Info,
  },
];

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  selectedPage?: PageOptions;
}) {
  const navProps = {
    ...props,
  };
  const { team } = useContact();
  delete navProps["selectedPage"];

  const nav = useNavigate();
  const { value: beneficiaryOnboarding } = useFeatureGate('beneficiary-onboarding')

  const doNavigatePage = useCallback(
    (page: PageOptions) => {
      if (page === "help") {
        window.open("https://www.plymouthstreet.org/resources", "_blank");
        return;
      }
      nav(`/${page === "home" ? "" : page}`);
    },
    [nav]
  );

  const relevantPages = allPages.filter((page) => {
    if (team?.type === "team") {
      return (
        page.page !== "actions" && page.page !== "track"
      );
    }

    if (team?.type === "personal" && beneficiaryOnboarding) {
      return page.page !== "actions"
    }

    return true;
  });

  return (
    <nav
      className={cn("flex flex-col space-y-4 lg:space-y-6", className)}
      {...navProps}
    >
      {relevantPages.map((page) => (
        <a
          key={page.page}
          onClick={() => doNavigatePage(page.page)}
          className={cn(
            "flex flex-row items-center p-2 hover:cursor-pointer relative space-x-2",
            props.selectedPage === page.page
                  ? "text-accent"
                  : "text-muted-foreground"
          )}
          title={page.title}
        >
          {
            <page.icon
              weight={props.selectedPage === page.page ? "duotone" : "regular"}
              size={14}
              className={cn('rounded w-6 h-6',
                props.selectedPage === page.page
                  ? "text-accent"
                  : "text-muted-foreground"
              )}
            />
          }
          {/* { page.notificationCount && (<span className="absolute top-[-10px] right-[-10px] rounded
          -full bg-red-600 w-6 h-6 text-sm text-white flex items-center justify-center font-bold">{page.notificationCount}</span>)} */}
          <span className="text-sm">{page.title}</span>
        </a>
      ))}
    </nav>
  );
}
